import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IUser } from '@/types/IUser';

type IUserRequest = { id: string };

type IUserResponse = AxiosResponse<IUser>;

export function useUserActivate() {
  const queryClient = useQueryClient();

  return useMutation<IUserResponse, AxiosError, IUserRequest>(
    [queryKeys.userActivate],
    ({ id }) => api.put(`/users/api/admin/users/activate/${id}`),
    {
      onError() {
        notify('error', t('messages.error'));
      },
      onSuccess: (_, { id }) => {
        notify('success', 'User has been successfully activated!');
        queryClient.invalidateQueries([queryKeys.users]);
        queryClient.invalidateQueries([queryKeys.user, { id }]);
      },
    }
  );
}
