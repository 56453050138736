import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import { IStockRequestMaterial } from '@/types/IStockRequest';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

export const sarMaterialsColumns: MRT_ColumnDef<IStockRequestMaterial>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: t('id'),
    size: 5,
  },
  {
    accessorFn: (row) => (
      <Link
        to={`/sar/details/${row.sarId}/material/${row.id}`}
        className="whitespace-nowrap hover:text-[#228BE6]"
      >
        {row.noun.noun ?? '-'}
      </Link>
    ),
    accessorKey: 'noun',
    header: t('material.noun'),
    size: 150,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.modifier.modifier ?? '-'}</span>
    ),

    accessorKey: 'modifier',
    header: t('material.modifier'),
    size: 150,
  },
  //materialNumbers
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.info ? row.info.materialNumber : '-'}
      </span>
    ),
    accessorKey: 'materialNumber',
    header: t('materialInfo.materialNumber'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.info ? row.info.materialDescription : '-'}
      </span>
    ),
    accessorKey: 'requisitioner',
    header: t('materialInfo.materialDescription'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.info ? row.info.baseUnitOfMeasure : '-'}
      </span>
    ),
    accessorKey: 'baseUnitOfMeasure',
    header: t('materialInfo.baseUnitOfMeasure'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.info ? row.info.reorderPoint : '-'}
      </span>
    ),
    accessorKey: 'reorderPoint',
    header: t('materialInfo.reorderPoint'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.info ? row.info.mrpController : '-'}
      </span>
    ),
    accessorKey: 'mrpController',
    header: t('materialInfo.mrpController'),
  },
  {
    accessorFn: (row) =>
      row.certificates && row.certificates.length != 0 ? (
        <Tooltip
          withArrow
          multiline
          label={row.certificates.map((certificate) => (
            <span key={certificate.id}>{certificate.certificate + '\n'}</span>
          ))}
        >
          <span className="whitespace-nowrap cursor-pointer">
            {row.certificates[0]?.certificate}
          </span>
        </Tooltip>
      ) : (
        <div>-</div>
      ),
    accessorKey: 'certificates',
    header: t('materialInfo.certificates'),
  },
];
