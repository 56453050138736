export type IEnumOrderStatusKeys = keyof typeof IEnumOrderStatus;

export enum IEnumOrderStatus {
  PR_RFQ_CREATED = 'PR - RFQ Created',
  PR_AWAITING_RELEASE = 'PR - Awaiting Release',
  STO_AWAITING_RELEASE = 'STO - Awaiting Release',
  PO_CREATED = 'PO - Created',
  PR_RELEASED = 'PR - Released',
  STR_COMPLETED = 'STR - Completed',
}
