import clsx from 'clsx';

import { IUser } from '@/types/IUser';

export default function ProfileAvatar({ me }: { me?: IUser }) {
  return (
    <>
      <div className="h-8 w-8 md:h-10 md:w-10 text-center grid content-center rounded-full border-2 border-gray-200 bg-gray-100 shrink-0">
        <span className="uppercase text-primary text-xs md:text-[15px]">
          {me?.firstName?.[0]}
          {me?.lastName?.[0]}
        </span>
      </div>

      <div className={clsx('flex-col justify-center')}>
        {/* <div className="flex items-center gap-2">
          <span className="font-bold text-sm capitalize ">
            {`${me?.firstName} ${me?.lastName}`}
          </span>
        </div> */}
      </div>
    </>
  );
}
