export type IMeasurementUnit = {
  fullName: string;
  id: string;
  shortName: string;
};

export const measurementUnitKeys: Record<keyof IMeasurementUnit, string> = {
  fullName: 'fullName',
  id: 'id',
  shortName: 'shortName',
};
