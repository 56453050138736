import { IPageActions } from '@/types/common/IPageActions';

enum IEnumUsersPageActions {
  'edit',
  'deactivateUser',
  'activateUser',
  'promoteUser',
  'demoteUser',
}

export type IUsersPageActions = {
  action: keyof typeof IEnumUsersPageActions;
  actionId: string;
};

export const usersPageActions: IPageActions<IUsersPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumUsersPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
