import { Link } from 'react-router-dom';

import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import { IModifier } from '@/types/IModifier';

export const modifiersColumns: MRT_ColumnDef<IModifier>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: t('id'),
  },
  {
    accessorFn: (row) => (
      <Link to={`/nouns/${row.nounId}/modifiers/${row.id}/attributes`}>
        {row.modifier}
      </Link>
    ),
    accessorKey: 'modifier',
    header: t('modifier.modifier'),
  },
];
