import { IPageActions } from '@/types/common/IPageActions';

enum IEnumNounsPageActions {
  'view',
}

export type INounsPageActions = {
  action: keyof typeof IEnumNounsPageActions;
  actionId: string;
};

export const nounsPageActions: IPageActions<INounsPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumNounsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
