import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IUser } from '@/types/IUser';

type IStockRequestPublishRequest = { id: number };

type IStockRequestPublishResponse = AxiosResponse<IUser>;

export function useStockRequestPublish() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockRequestPublishResponse,
    AxiosError,
    IStockRequestPublishRequest
  >(
    [queryKeys.userBlock],
    ({ id }) => api.put(`/materials/api/sars/publish/draft/${id}`),
    {
      onError() {
        notify('error', t('messages.error'));
      },
      onSuccess: () => {
        notify(
          'success',
          'Stock authorization request has been successfully published!'
        );
        queryClient.invalidateQueries([queryKeys.stockRequests]);
      },
    }
  );
}
