import { IPageActions } from '@/types/common/IPageActions';

enum IEnumBasketPageActions {
  'delete',
}

export type IBasketPageActions = {
  action: keyof typeof IEnumBasketPageActions;
  actionId: string;
};

export const basketPageActions: IPageActions<IBasketPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumBasketPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
