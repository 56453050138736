import { type FC } from 'react';

import { t } from 'i18next';

import { useMeasurementDelete } from '@api/measurements/useMeasurementDelete';
import { Button, Modal } from '@mantine/core';
import { notify } from '@utils/notify';

type DeleteAttributeModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmDeleteModal: FC<DeleteAttributeModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const deleteMeasurementUnit = useMeasurementDelete();

  const handleSubmit = () => {
    deleteMeasurementUnit
      .mutateAsync({ unitId: id })
      .then(() => {
        notify('success', 'Unit of Measurement was successfully deleted!');
        onClose();
      })
      .catch(() => {
        notify('error', t('tryAgainLater'));
      });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Delete Unit Of Measurement`}
    >
      <div className="max-w-[410px] mx-auto text-center text-gray-600 font-semibold m-5">
        {`Are you sure you want to delete the Unit of measurement?`}
      </div>

      <div className="grid gap-4 grid-cols-2">
        <Button
          loading={deleteMeasurementUnit.isLoading}
          onClick={handleSubmit}
          variant="outline"
        >
          Delete
        </Button>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
