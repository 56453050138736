import { type FC } from 'react';

import { Button, Modal, Textarea } from '@mantine/core';
import {
  IStockRequestActionRequest,
  stockRequestActionInitial,
  stockRequestActionValidation,
  useStockRequestAction,
} from '@api/sar/useStockRequestAction';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';

type DeleteAttributeModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const CancelRequestModal: FC<DeleteAttributeModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const cancelStockRequest = useStockRequestAction();

  const form = useForm<IStockRequestActionRequest>({
    initialValues: {
      ...stockRequestActionInitial,
    },
    validate: yupResolver(stockRequestActionValidation),
  });

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title="Are you sure you want to cancel this stock authorization request?"
    >
      <form
        onSubmit={form.onSubmit(() => {
          cancelStockRequest
            .mutateAsync({
              id: +id,
              status: 'CANCEL',
              reason: form.values.reason,
            })
            .then(() => {
              notify(
                'success',
                'The stock authorization request was successfully cancelled.'
              );
              onClose();
            });
        })}
      >
        <Textarea
          required
          autosize
          label="Cancellation reason"
          minRows={3}
          name="reason"
          w={600}
          {...form.getInputProps('reason')}
        />

        <div className="flex justify-center gap-4 mt-8">
          <Button onClick={onClose} variant="outline" w={200}>
            No
          </Button>
          <Button loading={cancelStockRequest.isLoading} type="submit" w={200}>
            Yes
          </Button>
        </div>
      </form>
    </Modal>
  );
};
