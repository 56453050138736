import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import { StatusTag } from '@components/molecules/StatusTag';

import { IUser } from '@/types/IUser';

export const usersColumns: MRT_ColumnDef<IUser>[] = [
  {
    accessorFn: (row) => <span className="whitespace-nowrap">{row.name}</span>,
    accessorKey: 'name',
    header: t('user.name'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.username}</span>
    ),
    accessorKey: 'username',
    header: t('user.username'),
  },
  {
    accessorFn: (row) => <span className="whitespace-nowrap">{row.email}</span>,
    accessorKey: 'email',
    header: t('user.email'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.jobTitle ?? '-'}</span>
    ),
    accessorKey: 'jobTitle',
    header: t('user.jobTitle'),
  },
  {
    accessorFn: (row) => <span className="whitespace-nowrap">{row.role}</span>,
    accessorKey: 'role',
    header: t('user.role'),
  },
  {
    accessorFn: (row) => <StatusTag status={row.status} />,
    accessorKey: 'status',
    header: t('user.status'),
  },
];
