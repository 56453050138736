import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IDictionary } from '@/types/IDictionary';

type IDictionaryRequest = { id: number };

type IDictionaryResponse = AxiosResponse<IDictionary>;

export function useDictionaryById(params: IDictionaryRequest) {
  return useQuery<IDictionaryResponse, AxiosError>(
    [queryKeys.user, params],
    () => api.get(`/materials/api/dictionaries/${params.id}`),
    { enabled: !!params.id }
  );
}
