import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IWorkOrderMaterial } from '@/types/IWorkOrder';

type IMaterialRequest = { id: string };

type IMaterialResponse = AxiosResponse<IWorkOrderMaterial>;

export function useWorkOrderMaterial(params: IMaterialRequest) {
  return useQuery<IMaterialResponse, AxiosError>(
    [queryKeys.material, params],
    () => api.get(`/work-orders/api/materials/${params.id}`),
    { enabled: !!params.id }
  );
}
