import { type FC, useEffect } from 'react';

import { t } from 'i18next';

import {
  IMeasurementEditRequest,
  measurementEditInitial,
  measurementEditValidation,
  useMeasurementEdit,
} from '@api/measurements/useMeasurementEdit';
import { useMeasurementUnitById } from '@api/measurements/useMeasurementUnitById';
import { Button, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';

type IEditMeasurementModal = {
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

export const EditMeasurementModal: FC<IEditMeasurementModal> = ({
  id,
  isOpen,
  onClose,
}) => {
  const measurementEdit = useMeasurementEdit();

  const measurementQuery = useMeasurementUnitById({ id });
  const measurement = measurementQuery.data;

  const form = useForm<IMeasurementEditRequest>({
    initialValues: measurementEditInitial,
    validate: yupResolver(measurementEditValidation),
  });

  useEffect(() => {
    if (measurement) {
      form.setValues({
        fullName: measurement.fullName,
        id: measurement.id,
        shortName: measurement.shortName,
      });
    }
  }, [measurement]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={570}
      title="Edit unit of measurement"
    >
      <form
        onSubmit={form.onSubmit((values) => {
          measurementEdit
            .mutateAsync({
              ...values,
            })
            .then(() => {
              notify(
                'success',
                'Unit of measurement was successfully updated!'
              );
              onClose();
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="grid gap-4 grid-cols-2 p-5">
          <TextInput
            label="UoM full name"
            name="fullName"
            required
            {...form.getInputProps('fullName')}
          />
          <TextInput
            label="UoM short name"
            name="shortName"
            required
            {...form.getInputProps('shortName')}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 px-5">
          <Button
            loading={measurementEdit.isLoading}
            type="submit"
            variant="outlined"
          >
            {t('save')}
          </Button>
          <Button onClick={onClose} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
