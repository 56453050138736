import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type IBasketMaterialDeleteRequest = { materialId: string };

type IBasketMaterialDeleteResponse = AxiosResponse<void>;

export function useBasketDelete() {
  const queryClient = useQueryClient();

  return useMutation<
    IBasketMaterialDeleteResponse,
    AxiosError,
    IBasketMaterialDeleteRequest
  >(({ materialId }) => api.delete(`/materials/api/basket/${materialId}`), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.basket]);
      notify('success', 'Material was successfully deleted.');
    },
  });
}
