import { Link } from 'react-router-dom';

import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import { INoun } from '@/types/INoun';

export const nounsColumns: MRT_ColumnDef<INoun>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: t('id'),
  },
  {
    accessorFn: (row) => (
      <Link to={`/nouns/${row.id}/modifiers`}>{row.noun}</Link>
    ),
    accessorKey: 'noun',
    header: t('noun.noun'),
  },
];
