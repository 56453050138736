import { type FC } from 'react';

import { useStockRequestPublish } from '@api/sar/useStockRequestPublish';
import { Button, Modal } from '@mantine/core';

type PublishSarModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmPublishModal: FC<PublishSarModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const publishSar = useStockRequestPublish();

  const handleSarPublish = () => {
    publishSar.mutateAsync({ id: Number(id) }).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Publish SAR`}
    >
      <div className="max-w-[410px] mx-auto text-center text-gray-600 font-semibold m-5">
        {`Are you sure you want to publish this request?`}
      </div>

      <div className="grid gap-4 grid-cols-2">
        <Button
          loading={publishSar.isLoading}
          onClick={handleSarPublish}
          variant="outline"
        >
          Confirm
        </Button>
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
