export enum IEnumSarActionRoles {
  REQUISITIONER = 'REQUISITIONER',
  DATA_CHAMPION = 'DATA_CHAMPION',
  QA_QC = 'QA_QC',
}

export enum IEnumSarQaQcActionRoles {
  REQUISITIONER = 'REQUISITIONER',
  DATA_CHAMPION = 'DATA_CHAMPION',
}

export type IEnumSarActionRolesKeys = keyof typeof IEnumSarActionRoles;
