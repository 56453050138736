import { FC, useMemo } from 'react';

import clsx from 'clsx';
import { t } from 'i18next';

import { IEnumSarStatusKeys } from '@consts/enums/IEnumStatuses';
import { Badge, BadgeProps } from '@mantine/core';

type StatusProps = BadgeProps & {
  readonly status: IEnumSarStatusKeys;
  readonly variant?: 'default' | 'mantine';
};

export const SarStatusTag: FC<StatusProps> = ({
  status,
  variant = 'default',
  ...props
}: StatusProps) => {
  const statusColor = useMemo(() => {
    switch (status) {
      case 'COMPLETED':
        return 'green';
      case 'CANCELED':
        return 'red';
      case 'AWAITING_ENG_REVIEW':
      case 'AWAITING_MDM':
      case 'AWAITING_QA_QC':
      case 'AWAITING_REQUISITIONER':
        return 'blue';
      case 'DRAFT':
        return 'gray';
      default:
        return '';
    }
  }, [status]);

  const className = useMemo(() => {
    switch (variant) {
      case 'default':
        return clsx(
          'py-0.5 px-2 rounded-2xl text-xs font-medium h-auto normal-case shrink-0',
          {
            'bg-[#E5E5E6] text-[#45464E]': statusColor == 'gray',
            'bg-[#EAF9FF] text-[#4695F1]': statusColor == 'blue',
            'bg-[#ECFDF3] text-[#027A48]': statusColor == 'green',
            'bg-[#FFF2EA] text-[#F15046]': statusColor == 'red',
          }
        );
      default:
        return 'px-5 py-3 lg:px-8 lg:py-4 rounded-lg font-semibold text-sm normal-case shrink-0';
    }
  }, [status]);

  return (
    <Badge classNames={{ root: className }} color={statusColor} {...props}>
      <div className="flex justify-center items-center">
        <Badge
          className={clsx('w-1.5 h-1.5 !p-0 mr-[6px]', {
            'bg-[#027A48]': statusColor == 'green',
            'bg-[#45464E]': statusColor == 'gray',
            'bg-[#4695F1]': statusColor == 'blue',
            'bg-[#F15046]': statusColor == 'red',
          })}
        />
        {t(`statuses.${status}`)}
      </div>
    </Badge>
  );
};
