import { type FC, useContext, useMemo, useState, useEffect } from 'react';
import { t } from 'i18next';

import { notify } from '@utils/notify';
import { UserContext } from '@context/UserContext';

import { Button, Loader, Modal, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { NotFoundResult } from '@components/molecules/NotFoundResult';

import { useDictionaries } from '@api/dictionaries/useDictionaries';
import { useDataChampionReviewers } from '@api/users/useDataChampionReviewers';
import {
  IStockRequestEditRequest,
  stockRequestEditInitial,
  stockRequestEditValidation,
  useStockRequestEdit,
} from '@api/sar/useStockRequestEdit';
import { useStockRequestById } from '@api/sar/useStockRequestById';
import { IRoleEnum } from '@/types/enums/rolesEnum';
import { useUserByRole } from '@api/users/useUserByRole';

export const EditStockRequestModal: FC<{
  isOpen: boolean;
  id?: string;
  onClose: () => void;
}> = ({ onClose, id, isOpen }) => {
  const { userMe } = useContext(UserContext);
  const requestEdit = useStockRequestEdit();
  const [searchQuery, setSearchQuery] = useState('');

  const stockRequestQuery = useStockRequestById({ sarId: Number(id) });
  const stockRequest = stockRequestQuery.data;

  //FIXME: check searchQuery
  const objectPartsQuery = useDictionaries({
    // searchQuery,
  });
  const objectParts = useMemo(() => {
    if (objectPartsQuery.isFetched) {
      const mappings = objectPartsQuery?.data?.[0].mappings ?? [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        values: v as string[],
      }));
    }
    return [];
  }, [objectPartsQuery]);

  const form = useForm<IStockRequestEditRequest>({
    initialValues: {
      ...stockRequestEditInitial,
    },
    validate: yupResolver(stockRequestEditValidation),
    validateInputOnChange: true,
  });

  useEffect(() => {
    if (stockRequest) {
      const {
        sarName,
        objectPart,
        dataChampionReviewer,
        qaQcReviewer,
        materialControllerReviewer,
        status,
      } = stockRequest;

      form.setValues({
        status,
        sarName,
        objectPart,
        qaQcReviewer: qaQcReviewer?.toString(),
        materialControllerReviewer: materialControllerReviewer?.toString(),
        dataChampionReviewer: dataChampionReviewer.toString(),
        updatedAt: new Date().toISOString(),
        updatedBy: String(userMe?.id),
        id: Number(id),
      });
    }
  }, [stockRequest]);

  const dataChampionReviewersQuery = useDataChampionReviewers({
    objectPart: form.values.objectPart,
  });
  const dataChampionReviewers = dataChampionReviewersQuery.data?.data;

  const materialControllerReviewersQuery = useUserByRole({
    role: IRoleEnum.MATERIAL_CONTROLLER_REVIEWER,
  });
  const materialControllerReviewers =
    materialControllerReviewersQuery?.data?.data;

  const qaQcReviewersQuery = useUserByRole({
    role: IRoleEnum.QA_QC_REVIEWER,
  });
  const qaQcReviewers = qaQcReviewersQuery?.data?.data;

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Edit stock authorization request`}
    >
      {stockRequestQuery.isLoading && objectPartsQuery.isLoading ? (
        <Loader />
      ) : !stockRequest ? (
        <NotFoundResult />
      ) : (
        <form
          onSubmit={form.onSubmit(() => {
            requestEdit
              .mutateAsync({
                ...form.values,
                objectPart: form.values.objectPart,
                dataChampionReviewer: +form.values.dataChampionReviewer || 0,
              })
              .then(() => {
                notify(
                  'success',
                  'Request details has been successfully updated!'
                );
                onClose();
              })
              .catch(() => {
                notify('error', t('tryAgainLater'));
              });
          })}
        >
          <div className="bg-white rounded-xl">
            <div className="mb-[10px] grid gap-4 grid-cols-3">
              <TextInput
                label={t('sar.sarName')}
                name="sarName"
                {...form.getInputProps('sarName')}
              />
              <Select
                comboboxProps={{ withinPortal: false }}
                data={objectParts.map((object) => ({
                  label: object.label,
                  value: object.label,
                }))}
                disabled={objectPartsQuery.isLoading}
                label={t('sar.objectPart')}
                name="objectPart"
                placeholder="Select item"
                searchable
                {...form.getInputProps('objectPart')}
                onSearchChange={setSearchQuery}
                searchValue={searchQuery}
              />
              <Select
                comboboxProps={{ withinPortal: false }}
                placeholder="Select item"
                data={(dataChampionReviewers ?? []).map((dataChampion) => ({
                  value: String(dataChampion.id),
                  label: dataChampion.name || '',
                }))}
                disabled={!form.values.objectPart}
                label={t('sar.dataChampionReviewer')}
                name="dataChampionReviewer"
                searchable
                {...form.getInputProps('dataChampionReviewer')}
              />
            </div>
            <div className="grid gap-4 grid-cols-2">
              <Select
                comboboxProps={{ withinPortal: false }}
                placeholder="Select item"
                data={(materialControllerReviewers ?? []).map(
                  (materialControllerReviewer) => ({
                    value: String(materialControllerReviewer.id),
                    label: materialControllerReviewer.name || '',
                  })
                )}
                disabled={userMe?.role != 'ADMIN'}
                label={t('sar.materialControllerReviewer')}
                name="materialControllerReviewer"
                {...form.getInputProps('materialControllerReviewer')}
              />
              <Select
                comboboxProps={{ withinPortal: false }}
                placeholder="Select item"
                data={(qaQcReviewers ?? []).map((qaQcReviewer) => ({
                  value: String(qaQcReviewer.id),
                  label: qaQcReviewer.name || '',
                }))}
                disabled={userMe?.role != 'MATERIAL_CONTROLLER_REVIEWER'}
                label={t('sar.qaQcReviewer')}
                name="qaQcReviewer"
                {...form.getInputProps('qaQcReviewer')}
              />
            </div>
          </div>

          <div className="flex flex-row justify-between mt-8">
            <Button
              w={200}
              onClick={onClose}
              variant="outline"
              className=" mr-4"
            >
              Cancel
            </Button>
            <Button w={200} loading={requestEdit.isLoading} type="submit">
              Save
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
