import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IStockRequestMaterial } from '@/types/IStockRequest';

export type IStockMaterialResponse = IStockRequestMaterial;

export function useStockMaterial({ ...params }: { sarMaterialId: number }) {
  return useQuery<IStockMaterialResponse, AxiosError>(
    [queryKeys.stockMaterial, params],
    () =>
      api
        .get(`/materials/api/sar-materials/${params.sarMaterialId}`)
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) &&
        !!params.sarMaterialId,
    }
  );
}
