import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { array, boolean, number, object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IModifier } from '@/types/IModifier';
import { INoun } from '@/types/INoun';

export type IEdititionalAttribute = {
  id?: number | null;
  isUomRequired?: boolean | null;
  key: string;
  modifierId: number | null;
  nounId: number | null;
  uom: {
    id?: number | string;
  } | null;
  value: string;
};

export type IStockMaterialEditRequest = {
  id: number;
  modifier?: Pick<IModifier, 'id'>;
  noun?: Pick<INoun, 'id'>;
  sarAttributes?: IEdititionalAttribute[];
  sarId: number;
};

export const stockMaterialEditInitial: IStockMaterialEditRequest = {
  id: 0,
  modifier: {
    id: '',
  },
  noun: {
    id: '',
  },
  sarAttributes: [],
  sarId: 0,
};

type IStockMaterialEditResponse = AxiosResponse<void>;

export const stockMaterialEditValidation: ObjectSchema<IStockMaterialEditRequest> =
  object({
    id: number().required('This field is required!'),
    modifier: object({
      id: number().required('This field is required!'),
    }),
    noun: object({
      id: number().required('This field is required!'),
    }),
    sarAttributes: array()
      .of(
        object({
          id: number().nullable(),
          isUomRequired: boolean().nullable(),
          key: string().required(),
          modifierId: number().required(),
          nounId: number().required(),
          uom: object({
            id: number().when('isUomRequired', {
              is: (isUomRequired: boolean) => isUomRequired,
              otherwise: (s) => s.notRequired(),
              then: (s) => s.required(),
            }),
          }).nullable(),
          value: string().required(),
        })
      )
      .required(),
    sarId: number().required('This field is required!'),
  });

export function useEditStockMaterial() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockMaterialEditResponse,
    AxiosError,
    IStockMaterialEditRequest
  >((payload) => api.put('/materials/api/sar-materials', payload), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.stockMaterials]);
      queryClient.invalidateQueries([queryKeys.stockMaterial]);
    },
  });
}
