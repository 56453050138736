import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { object, ObjectSchema, string, number } from 'yup';

import { IEnumSarStatusKeys } from '@consts/enums/IEnumStatuses';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IStockRequest } from '@/types/IStockRequest';

export type IStockRequestCreateRequest = Pick<
  IStockRequest,
  | 'sarName'
  | 'objectPart'
  | 'dataChampionReviewer'
  | 'qaQcReviewer'
  | 'materialControllerReviewer'
  | 'status'
  | 'createdBy'
  | 'createdAt'
>;

type IStockRequestCreateResponse = AxiosResponse<number>;

export const stockRequestCreateInitial: IStockRequestCreateRequest = {
  dataChampionReviewer: 0,
  materialControllerReviewer: 0,
  objectPart: '',
  qaQcReviewer: 0,
  sarName: '',
  status: 'DRAFT',
};

export const stockRequestCreateValidation: ObjectSchema<IStockRequestCreateRequest> =
  object({
    createdAt: string().required(),
    createdBy: string().required(),
    dataChampionReviewer: number().required('This field is required!'),
    materialControllerReviewer: number().required('This field is required!'),
    objectPart: string().required('This field is required!'),
    qaQcReviewer: number().required('This field is required!'),
    sarName: string().required('This field is required!'),
    status: string<IEnumSarStatusKeys>().required('This field is required!'),
  });

export function useStockRequestCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockRequestCreateResponse,
    AxiosError,
    IStockRequestCreateRequest
  >((payload) => api.post('/materials/api/sars', payload), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => queryClient.invalidateQueries([queryKeys.stockRequests]),
  });
}
