import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type ISARMaterialDeleteRequest = { id: string };

type ISARMaterialDeleteResponse = AxiosResponse<void>;

export function useStockMaterialDelete() {
  const queryClient = useQueryClient();

  return useMutation<
    ISARMaterialDeleteResponse,
    AxiosError,
    ISARMaterialDeleteRequest
  >(({ id }) => api.delete(`/materials/api/sar-materials/${id}`), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.stockMaterials]);
      queryClient.invalidateQueries([queryKeys.stockMaterialsPaged]);
      notify('success', 'Material was successfully deleted from request.');
    },
  });
}
