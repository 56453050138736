import { FC } from 'react';

import { useUserByRole } from '@api/users/useUserByRole';
import { SelectMultipleFilter } from '@components/molecules/filters/static/SelectMultipleFilter';

import { FilterRender } from '@/types/common/IFilters';
import { IRoleEnum } from '@/types/enums/rolesEnum';

interface UsersMCSelectMultipleInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const MaterialControllersSelectMultipleFilter: FC<
  UsersMCSelectMultipleInterface
> = ({ filterRender }) => {
  // const [userName, setUserName] = useState('');
  const usersQuery = useUserByRole({
    role: IRoleEnum.MATERIAL_CONTROLLER_REVIEWER,
  });
  const users = usersQuery?.data?.data;

  const options =
    users?.map((user) => ({
      label: user.username,
      value: user.id.toString(),
    })) || [];

  return (
    <SelectMultipleFilter
      filterRender={filterRender}
      loading={usersQuery.isLoading}
      options={options}
    />
  );
};
