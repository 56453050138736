export type IModifier = {
  id: number | string;
  modifier: string;
  nounId: string;
};

export const modifierKeys: Record<keyof IModifier, string> = {
  id: 'id',
  modifier: 'modifier',
  nounId: 'nounId',
};
