import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import clsx from 'clsx';

import {
  authInitial,
  authValidation,
  IUserAuthRequest,
  useAuth,
} from '@api/auth/useAuth';
import { Lock, Message } from '@assets/icons';
import { PageMeta } from '@context/PageMetaContext';
import { Button, Image, PasswordInput, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';

import '@utils/index';

const LoginPage: FC = () => {
  const navigate = useNavigate();
  const login = useAuth();
  const { t } = useTranslation();

  const form = useForm<IUserAuthRequest>({
    initialValues: authInitial,
    validate: yupResolver(authValidation),
  });

  return (
    <>
      <PageMeta title={t('auth.auth')} />

      <div className="mb-12 flex flex-col items-center gap-5">
        <Image fit="contain" h={100} radius="md" src="/logo.png" w="auto" />
        <span
          className={clsx(
            'truncate whitespace-nowrap text-3xl font-bold tracking-wide'
          )}
        >
          {/* <span className="text-primary">MRTF</span> */}
        </span>
      </div>

      <form
        className="w-full max-w-sm lg:w-2/3"
        onSubmit={form.onSubmit(({ email, password }) => {
          login
            .mutateAsync({ email: email.trim(), password: password.trim() })
            .catch((error) => {
              if (error.response?.data.status == 400) {
                if (error.response.data.message == 'REQUIRED_NEW_PASSWORD') {
                  navigate('/reset-password', {
                    state: { email: email.trim(), password: password.trim() },
                  });
                } else {
                  form.setFieldError('email', t('auth.incorrectLogin'));
                }
              }
            });
        })}
      >
        <TextInput
          className=""
          leftSection={<Message width={16} />}
          name="email"
          placeholder="User name"
          withAsterisk
          {...form.getInputProps('email')}
        />
        <PasswordInput
          leftSection={<Lock width={16} />}
          name="password"
          placeholder="Password"
          withAsterisk
          {...form.getInputProps('password')}
          className="mt-5"
        />
        <div className="mt-8 flex flex-col">
          <Button
            className="w-[180px] self-center"
            loading={login.isLoading}
            type="submit"
          >
            {t('auth.enter')}
          </Button>

          {/* <Link className="w-full" to="/reset/email">
            <Button className="mt-3 w-full" variant="subtle">
              {t('auth.forgotPassword')}
            </Button>
          </Link> */}
        </div>
      </form>
    </>
  );
};

export default LoginPage;
