import { FC, useState } from 'react';

import { t } from 'i18next';

import { Button, Radio } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

import { FilterRender } from '@/types/common/IFilters';

interface RadioFilterInterface {
  filterRender?: FilterRender;
  options: { label: string; value: string }[];
}

export const RadioFilter: FC<RadioFilterInterface> = ({
  filterRender,
  options,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [newValue, setNewValue] = useState({ [key]: filtered[key] });

  return (
    <div className="space-y-3 bg-white max-w-tiny">
      <Radio.Group
        defaultValue={filtered[key]}
        onChange={(e) => {
          setNewValue({ [key]: e });
        }}
      >
        {options.map((option) => (
          <Radio
            classNames={{
              body: 'hover:bg-primary-50 rounded hover:text-primary font-semibold',
              inner: 'p-1 cursor-pointer',
              label: 'p-1 cursor-pointer font-medium',
              labelWrapper: 'text-inherit',
              radio: 'cursor-pointer',
            }}
            key={`radio-${option.value}`}
            label={option.label}
            value={option.value}
          />
        ))}
      </Radio.Group>
      <div className="grid grid-cols-2 gap-2 mt-2">
        <Button
          className="h-auto p-1"
          leftSection={<IconSearch size={14} />}
          onClick={() => onFilter(newValue)}
          size="small"
          variant="primary"
        >
          {t(`search`)}
        </Button>
        <Button
          className="h-auto p-1"
          onClick={() => {
            setNewValue({ [key]: undefined });
            onFilter({ [key]: undefined });
          }}
          size="small"
          variant="outline"
        >
          {t(`clear`)}
        </Button>
      </div>
    </div>
  );
};
