import { type FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { t } from 'i18next';

import { PlusOutlined } from '@ant-design/icons';
import { useFilterModifiers } from '@api/filters/useFilterModifiers';
import { useFilterNouns } from '@api/filters/useFilterNouns';
import { useMeasurementUnits } from '@api/measurements/useMeasurementUnits';
import { useAdditionalAttributeDelete } from '@api/sar-materials/useAdditionalAttributeDelete';
import {
  IAdditionalAttribute,
  stockMaterialAddValidation,
} from '@api/sar-materials/useAddStockMaterial';
import { useStockMaterial } from '@api/sar-materials/useStockMaterial';
import {
  IStockMaterialEditRequest,
  stockMaterialEditInitial,
  useEditStockMaterial,
} from '@api/sar-materials/useStockMaterialEdit';
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Modal,
  Popover,
  Select,
  Skeleton,
  Text,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { notify } from '@utils/notify';

import { IStockRequestAttribute } from '@/types/IStockRequest';

type IEditStockMaterialModal = {
  isOpen: boolean;
  onClose: () => void;
  sarMaterialId: number;
};

export const EditStockMaterialModal: FC<IEditStockMaterialModal> = ({
  isOpen,
  onClose,
  sarMaterialId,
}) => {
  const { sarId } = useParams();
  const [attributeIdList, setAttributeIdList] = useState<number[]>([]);
  const stockMaterialQuery = useStockMaterial({ sarMaterialId: sarMaterialId });
  const stockMaterial = stockMaterialQuery.data;

  //GET Nouns
  const nounsQuery = useFilterNouns();
  const nouns = useMemo(() => {
    const obj = nounsQuery.data;
    return obj
      ? Object.keys(obj)
          .map((key) => ({ key, value: obj[key] }))
          .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
      : [];
  }, [nounsQuery.data]);

  const [isStockMaterialLoading, setIsStockMaterialLoading] =
    useState<boolean>(true);
  const [inputs, setInputs] = useState<IAdditionalAttribute[]>([]);
  const [attributes, setAttributes] = useState<IStockRequestAttribute[]>([]);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        id: 0,
        isUomRequired: false,
        key: '',
        modifierId: null,
        nounId: null,
        uom: null,
        value: '',
      },
    ]);
  };

  const attributeDelete = useAdditionalAttributeDelete();

  const handleDeleteInput = (index, attributeId) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
    setAttributeIdList([...attributeIdList, attributeId]);
  };

  const requestMaterialEdit = useEditStockMaterial();

  const form = useForm<IStockMaterialEditRequest>({
    initialValues: {
      ...stockMaterialEditInitial,
    },
    transformValues: (values) => ({
      ...values,
      modifier: {
        id: Number(values.modifier?.id),
      },
      noun: {
        id: Number(values.noun?.id),
      },
      sarAttributes:
        values.sarAttributes?.concat(inputs).map((input) => ({
          id: input.id !== 0 ? input.id : null,
          isUomRequired: input.isUomRequired ?? false,
          key: input.key,
          modifierId: Number(form.values.modifier.id),
          nounId: Number(form.values.noun.id),
          uom:
            input.uom !== null
              ? {
                  id: Number(input.uom.id),
                }
              : null,
          value: input.value,
        })) ?? [],
      sarId: Number(sarId),
    }),
    validate: yupResolver(stockMaterialAddValidation),
  });

  useEffect(() => {
    if (stockMaterial && isOpen) {
      setIsStockMaterialLoading(false);
      setInputs(
        stockMaterial?.sarAttributes.filter(
          (attr) => !attr.isDefault
        ) as IAdditionalAttribute[]
      );
      setAttributes(
        stockMaterial?.sarAttributes.filter(
          (attr) => attr.isDefault
        ) as IStockRequestAttribute[]
      );
      form.setValues({
        ...stockMaterial,
        modifier: {
          id: String(stockMaterial?.modifier.id),
        },
        noun: {
          id: String(stockMaterial?.noun.id),
        },
        sarId: Number(sarId),
      });
    }
  }, [stockMaterial, sarId, isOpen]);

  //GET Modifiers
  const modifiersQuery = useFilterModifiers({
    id: stockMaterial?.noun.id?.toString() as string,
  });
  const modifiers = useMemo(() => {
    if (modifiersQuery.data) {
      form.setFieldValue('modifier.id', null);
      const obj = modifiersQuery.data;
      return obj
        ? Object.keys(obj)
            .map((key) => ({ key, value: obj[key] }))
            .sort((a, b) =>
              a.value > b.value ? 1 : b.value > a.value ? -1 : 0
            )
        : [];
    }
  }, [modifiersQuery.data]);

  //GET UoMs
  const measurementsQuery = useMeasurementUnits({
    page: 0,
    size: 100,
  });
  const measurements = measurementsQuery.data;

  const handleClose = () => {
    onClose();
    form.reset();

    setInputs([]);
    setAttributeIdList([]);
  };

  const handleChange = (event, index, isUomSelect: boolean) => {
    const onChangeValue = [...inputs];
    if (!isUomSelect) {
      const { name, value } = event.target;
      const { checked } = event.currentTarget;
      if (name == 'isUomRequired') {
        onChangeValue[index][name] = checked;
      } else {
        onChangeValue[index][name] = value;
      }
    } else {
      onChangeValue[index]['uom'] = {
        id: event.value != null ? event.value : 0,
      };
    }
    setInputs(onChangeValue);
  };

  useEffect(() => {
    if (attributes.length != 0) {
      form.setFieldValue('sarAttributes', attributes);
    }
  }, [attributes]);

  return (
    <Modal
      centered
      onClose={handleClose}
      opened={isOpen}
      size={940}
      title="Edit Material"
    >
      {isStockMaterialLoading ? (
        <Skeleton />
      ) : (
        <form
          onSubmit={form.onSubmit((values) => {
            requestMaterialEdit
              .mutateAsync({
                ...values,
              })
              .then(() => {
                notify('success', 'Material was successfully added!');
                onClose();
              })
              .catch(() => {
                notify('error', t('tryAgainLater'));
              });
            if (attributeIdList.length != 0) {
              attributeIdList.map((attributeId) => {
                attributeDelete
                  .mutateAsync({ id: Number(attributeId) })
                  .then(() => {
                    notify('success', 'Attribute was successfully deleted!');
                  })
                  .catch(() => {
                    notify('error', t('tryAgainLater'));
                  });
              });
            }
          })}
        >
          <div>
            <div className="input_container">
              <div className="grid gap-4 grid-cols-2 items-end mb-6">
                <Select
                  className="mt-0"
                  comboboxProps={{ withinPortal: false }}
                  data={(nouns ?? [])?.map((noun) => ({
                    label: noun.value,
                    value: noun.key,
                  }))}
                  label="Noun"
                  placeholder="Noun"
                  searchable
                  withAsterisk
                  {...form.getInputProps('noun.id')}
                />
                <Select
                  className="mt-0"
                  comboboxProps={{ withinPortal: false }}
                  data={(modifiers ?? [])?.map((modifier) => ({
                    label: modifier.value,
                    value: modifier.key,
                  }))}
                  disabled={modifiersQuery.isLoading}
                  label="Modifier"
                  nothingFoundMessage="Nothing found..."
                  placeholder="Modifier"
                  withAsterisk
                  {...form.getInputProps('modifier.id')}
                />
              </div>

              {attributes.length != 0 && (
                <span className="text-[#111827] font-semibold text-sm">
                  Material attributes
                </span>
              )}
              <div className="bg-[#F8F9FA] border border-[#CED4DA] border-1 rounded-xl py-8 px-6">
                {/* ATTRIBUTES CHANGE */}
                {attributes.map((attribute, attrIndex) => (
                  <div className="grid grid-cols-2 gap-2 " key={attrIndex}>
                    <div className="flex items-center">
                      <span className="mr-5 text-[#9CA3AF]">
                        {attrIndex + 1}.
                      </span>
                      <TextInput
                        className="mb-5 !mt-0 w-[250px]"
                        label={attribute.key}
                        mt="md"
                        placeholder={attribute.key}
                        withAsterisk
                        {...form.getInputProps(
                          `sarAttributes.${attrIndex}.value`
                        )}
                      />
                    </div>
                    {attribute.isUomRequired && attribute.uom && (
                      <Select
                        className="mt-0 w-[130px]"
                        comboboxProps={{ withinPortal: false }}
                        data={
                          measurements?.map((measurement) => ({
                            label: measurement.shortName,
                            value: measurement.id.toString(),
                          })) ?? []
                        }
                        disabled={measurementsQuery.isLoading}
                        label="Unit of Measurement"
                        nothingFoundMessage="Nothing found..."
                        placeholder="%IN"
                        readOnly={false}
                        width={120}
                        withAsterisk
                        {...form.getInputProps(
                          `sarAttributes.${attrIndex}.uom.id`
                        )}
                      />
                    )}
                  </div>
                ))}

                {/* ADDITIONAL ATTRIBUTES CHANGE */}
                {measurementsQuery.isFetched &&
                  stockMaterial &&
                  inputs.map((item, index) => (
                    <div key={index}>
                      <div className="grid grid-cols-10 gap-4 items-end">
                        <div className="flex items-center col-span-3">
                          <span className="mr-5 text-[#9CA3AF]">
                            {index + 1}.
                          </span>
                          <TextInput
                            className="mb-5 !mt-0 col-span-2 w-full"
                            label="Label"
                            mt="md"
                            name="key"
                            onChange={(event) =>
                              handleChange(event, index, false)
                            }
                            placeholder="Label"
                            required
                            value={item.key}
                            withAsterisk
                          />
                        </div>
                        <TextInput
                          className="mb-4 !mt-0 col-span-3"
                          label="Value"
                          mt="md"
                          name="value"
                          onChange={(event) =>
                            handleChange(event, index, false)
                          }
                          placeholder="Value"
                          required
                          value={item.value}
                          withAsterisk
                        />
                        <Group className="mb-4 col-span-3">
                          {item.isUomRequired !== null && (
                            <Checkbox
                              checked={item.isUomRequired}
                              label="Unit of Measurement"
                              name="isUomRequired"
                              onChange={(event) =>
                                handleChange(event, index, false)
                              }
                            />
                          )}
                          <Select
                            className="mt-0"
                            comboboxProps={{ withinPortal: false }}
                            data={
                              measurements?.map((measurement) => ({
                                label: measurement.shortName,
                                name: 'uom',
                                value: measurement.id.toString(),
                              })) ?? []
                            }
                            disabled={
                              measurementsQuery.isLoading || !item.isUomRequired
                            }
                            name="uom"
                            nothingFoundMessage="Nothing found..."
                            onChange={(_vlue, option) =>
                              handleChange(option, index, true)
                            }
                            placeholder="%IN"
                            required
                            value={
                              item.uom != null ? item.uom.id?.toString() : ''
                            }
                            width={120}
                          />
                        </Group>
                        <Popover
                          clickOutsideEvents={['mouseup', 'touchend']}
                          position="top"
                          shadow="md"
                          trapFocus
                          width={250}
                          withArrow
                          zIndex={1000}
                        >
                          <Popover.Target>
                            <ActionIcon
                              aria-label="editMaterial"
                              className="mb-4 col-span-1"
                              h={40}
                              // onClick={() => handleDeleteInput(index, item.id)}
                              variant="default"
                              w={40}
                            >
                              <IconTrash
                                stroke={1.5}
                                style={{ height: '50%', width: '50%' }}
                              />
                            </ActionIcon>
                          </Popover.Target>
                          <Popover.Dropdown>
                            <Text className="mb-2" size="xs">
                              Are you sure you want to delete this attribute
                              from material?
                            </Text>
                            <Button
                              loading={requestMaterialEdit.isLoading}
                              onClick={() => handleDeleteInput(index, item.id)}
                              size="xs"
                              variant="outlined"
                            >
                              Yes
                            </Button>
                          </Popover.Dropdown>
                        </Popover>
                      </div>
                      {index === inputs.length - 1 && (
                        <Button
                          className="w-full mt-4 text-[#228BE6] border-[#228BE6]"
                          leftSection={<PlusOutlined className="w-4 h-4" />}
                          onClick={() => handleAddInput()}
                          variant="outline"
                        >
                          Add Attribute
                        </Button>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between mt-8">
            <Button className="mr-3" onClick={handleClose} variant="outline">
              {t('cancel')}
            </Button>
            <Button
              loading={requestMaterialEdit.isLoading}
              type="submit"
              variant="outlined"
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Modal>
  );
};
