import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgSar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="#53545C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M1 4.5C1 1.875 1.028 1 4.5 1S8 1.875 8 4.5 8.011 8 4.5 8 1 7.125 1 4.5M12 4.5c0-2.625.028-3.5 3.5-3.5s3.5.875 3.5 3.5.011 3.5-3.5 3.5S12 7.125 12 4.5M1 15.5c0-2.625.028-3.5 3.5-3.5s3.5.875 3.5 3.5.011 3.5-3.5 3.5S1 18.125 1 15.5M12 15.5c0-2.625.028-3.5 3.5-3.5s3.5.875 3.5 3.5.011 3.5-3.5 3.5-3.5-.875-3.5-3.5"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgSar);
export default Memo;
