import { IEnumSarStatusKeys } from '@consts/enums/IEnumStatuses';
import { createContext, FC, ReactNode, useContext, useState } from 'react';

type SarContextValues = {
  onChangeSarId: (sarId: number) => void;
  onChangeSarIdList: (sarIds: string[]) => void;
  onChangeSarNumbersList: (sarNumbersList: string[]) => void;
  onChangeSarMaterialId: (sarMaterialId: number) => void;
  sarId: number;
  sarMaterialId: number;
  sarIdList: string[];
  sarNumbersList: string[];
  sarTab: string;
  sarStatus: IEnumSarStatusKeys;
  onChangeSarTab: (sarId: string) => void;
  onChangeSarStatus: (status: IEnumSarStatusKeys) => void;
};

export const SarContext = createContext<SarContextValues>({
  onChangeSarId: () => {
    //
  },
  onChangeSarTab: () => {
    //
  },
  onChangeSarIdList: () => {
    //
  },
  onChangeSarNumbersList: () => {
    //
  },
  onChangeSarMaterialId: () => {
    //
  },
  onChangeSarStatus: () => {
    //
  },
  sarId: 0,
  sarMaterialId: 0,
  sarIdList: [],
  sarNumbersList: [],
  sarTab: 'all',
  sarStatus: 'DRAFT',
});

export const SarContextProvider: FC<{ children?: ReactNode }> = (props) => {
  const [sarId, setSarId] = useState<number>(0);
  const [sarMaterialId, setSarMaterialId] = useState<number>(0);
  const [sarIdList, setSarIdList] = useState<string[]>([]);
  const [sarNumbersList, setSarNumbersList] = useState<string[]>([]);
  const [sarTab, setSarTab] = useState<string>('all');
  const [sarStatus, setSarStatus] = useState<IEnumSarStatusKeys>('DRAFT');

  const onChangeSarId = (sarId: number) => {
    setSarId(sarId);
  };

  const onChangeSarMaterialId = (sarMaterialId: number) => {
    setSarMaterialId(sarMaterialId);
  };

  const onChangeSarIdList = (sarIds: string[]) => {
    setSarIdList(sarIds);
  };

  const onChangeSarNumbersList = (sarNumbersList: string[]) => {
    setSarNumbersList(sarNumbersList);
  };

  const onChangeSarTab = (sarTab: string) => {
    setSarTab(sarTab);
  };

  const onChangeSarStatus = (status: IEnumSarStatusKeys) => {
    setSarStatus(status);
  };

  return (
    <SarContext.Provider
      value={{
        onChangeSarTab,
        onChangeSarId,
        onChangeSarIdList,
        onChangeSarNumbersList,
        onChangeSarMaterialId,
        sarId,
        sarMaterialId,
        sarIdList,
        sarNumbersList,
        sarTab,
        sarStatus,
        onChangeSarStatus,
      }}
    >
      {props.children}
    </SarContext.Provider>
  );
};

export const useSarContext = () => {
  const userContext = useContext(SarContext);

  if (typeof userContext === 'undefined') {
    throw new Error('useSarContext must be used within a SarContextProvider');
  }

  return userContext;
};
