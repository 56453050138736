import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import moment from 'moment';

import { IWorkOrderMaterial } from '@/types/IWorkOrder';
import { OrderStatusTag } from '@modules/orders/components/atoms/OrderStatusTag';
import { MaterialLink } from '@modules/orders/components/atoms/MaterialLink';

export const orderMaterialsColumns: MRT_ColumnDef<IWorkOrderMaterial>[] = [
  {
    accessorFn: (row) => (
      <MaterialLink id={row.id} materialNumber={row.materialNumber} />
    ),
    accessorKey: 'materialNumber',
    header: t('order.materialNumber'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap ">
        {row.materialDescription ?? '-'}
      </span>
    ),
    accessorKey: 'materialDescription',
    header: t('order.materialDescription'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.requirementQuantity ?? '-'}
      </span>
    ),
    accessorKey: 'requirementQuantity',
    header: t('order.requirementQuantity'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.unitOfMeasurement ?? '-'}</span>
    ),
    accessorKey: 'unitOfMeasurement',
    header: t('order.unitOfMeasurement'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.itemCategory ?? '-'}</span>
    ),
    accessorKey: 'itemCategory',
    header: t('order.itemCategory'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.storageLocation ?? '-'}</span>
    ),
    accessorKey: 'storageLocation',
    header: t('order.storageLocation'),
    enableHiding: true,
  },
  // {
  //   accessorFn: (row) => (
  //     <span className="whitespace-nowrap">{row.branchPlant ?? '-'}</span>
  //   ),
  //   accessorKey: 'branchPlant',
  //   header: t('order.branchPlant'),
  //   enableHiding: true,
  // },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.activity ?? '-'}</span>
    ),
    accessorKey: 'activity',
    header: t('order.activity'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.purchasingOrganization ?? '-'}
      </span>
    ),
    accessorKey: 'purchasingOrganization',
    header: t('order.purchasingOrganization'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.unloadingPlant ?? '-'}</span>
    ),
    accessorKey: 'unloadingPlant',
    header: t('order.unloadingPlant'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {moment(row.updateAt).format('DD.MM.YY') ?? '-'}
      </span>
    ),
    accessorKey: 'updateAt',
    header: t('order.updateAt'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.assignedBuyer ?? '-'}</span>
    ),
    accessorKey: 'assignedBuyer',
    header: t('order.assignedBuyer'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {moment(row.requirementsDate).format('DD/MM/YY') ?? '-'}
      </span>
    ),
    accessorKey: 'requirementsDate',
    header: t('order.requirementsDate'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.str ?? '-'}</span>
    ),
    accessorKey: 'str',
    header: t('order.str'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.sto ?? '-'}</span>
    ),
    accessorKey: 'sto',
    header: t('order.sto'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.pr ?? '-'}</span>
    ),
    accessorKey: 'pr',
    header: t('order.pr'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.po ?? '-'}</span>
    ),
    accessorKey: 'po',
    enableHiding: true,
    header: t('order.po'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {moment(row.deliveryDate).format('DD.MM.YY') ?? '-'}
      </span>
    ),
    accessorKey: 'deliveryDate',
    header: t('order.deliveryDate'),
    enableHiding: true,
  },
  {
    accessorFn: (row) => <OrderStatusTag status={row.status} />,
    accessorKey: 'status',
    header: t('order.status'),
    enableHiding: true,
  },
];
