import { FC } from 'react';

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

enum TableSortEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

type SortType = {
  dataIndex: string;
  onFilter: (key: object) => void;
  orderedBy?: string;
  sortOrderReversed?: boolean;
  sortedBy?: string;
};

export const QueryTableSortIcon: FC<SortType> = ({
  dataIndex,
  onFilter,
  orderedBy,
  sortOrderReversed,
  sortedBy,
}) => {
  const isActive = sortedBy == dataIndex;
  const orders = sortOrderReversed
    ? [TableSortEnum.DESC, TableSortEnum.ASC]
    : [TableSortEnum.ASC, TableSortEnum.DESC];
  const nextOrderBy =
    isActive && orderedBy == orders[0]
      ? orders[1]
      : isActive && orderedBy == orders[1]
        ? undefined
        : orders[0];

  return (
    <span
      className=" ant-table-filter-trigger inline-flex flex-col justify-center text-xs cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        onFilter({
          ['sort']: nextOrderBy
            ? `${dataIndex}${nextOrderBy === TableSortEnum.DESC ? ',desc' : ''}`
            : undefined,
        });
      }}
    >
      {orderedBy == TableSortEnum.ASC && isActive ? (
        <CaretUpOutlined className="h-2.5 text-primary" />
      ) : (
        <CaretDownOutlined
          className={`h-2.5 ${
            orderedBy == TableSortEnum.DESC && isActive
              ? 'text-primary'
              : 'text-gray-500'
          }`}
        />
      )}
    </span>
  );
};
