import { useEffect, useState, type FC } from 'react';

import { Button, CloseButton, Modal, TextInput } from '@mantine/core';
import { useSarMaterialNumber } from '@api/sar-materials/useSarMaterialNumber';
import { notify } from '@utils/notify';
import { useStockMaterialInfo } from '@api/sar-materials/useStockMaterialInfo';

type AddMaterialNumberModalProps = {
  isOpen: boolean;
  onClose: () => void;
  id?: string;
};

export const AddMaterialNumberModal: FC<AddMaterialNumberModalProps> = ({
  isOpen,
  onClose,
  id = '',
}) => {
  const materialInfoQuery = useStockMaterialInfo({
    id: Number(id),
  });
  const materialInfo = materialInfoQuery.data;
  const [materialNumber, setMaterialNumber] = useState<string>('');

  const addMaterialNumberRequest = useSarMaterialNumber();

  const handleMaterialNumberAdd = () => {
    addMaterialNumberRequest
      .mutateAsync({ sarMaterialId: Number(id), materialNumber })
      .then(() => {
        notify(
          'success',
          `Material number was successfully ${
            materialInfo?.materialNumber ? 'saved' : 'added'
          }!`
        );
        onClose();
      });
  };

  useEffect(() => {
    if (materialInfo) {
      setMaterialNumber(materialInfo.materialNumber || '');
    }
  }, [materialInfo]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Add Material Number`}
    >
      <TextInput
        label="Material Number"
        className="w-full mt-0"
        mt="md"
        required
        onChange={(event) => setMaterialNumber(event.currentTarget.value)}
        placeholder="0000"
        rightSection={
          <CloseButton
            aria-label="Clear input"
            onClick={() => setMaterialNumber('')}
            style={{ display: materialNumber ? undefined : 'none' }}
          />
        }
        value={materialNumber}
      />
      <div className="flex justify-center gap-4 mt-8">
        <Button onClick={onClose} variant="outline" w={200}>
          Cancel
        </Button>
        <Button
          loading={addMaterialNumberRequest.isLoading}
          onClick={handleMaterialNumberAdd}
          w={200}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};
