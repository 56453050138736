import { t } from 'i18next';

export type RoleEnumKeys = keyof typeof IRoleEnum;

export enum IRoleEnum {
  ADMIN = 'ADMIN',
  DATA_CHAMPION_REVIEWER = 'DATA_CHAMPION_REVIEWER',
  MATERIAL_CONTROLLER_REVIEWER = 'MATERIAL_CONTROLLER_REVIEWER',
  QA_QC_REVIEWER = 'QA_QC_REVIEWER',
  USER = 'USER',
}

export const getRoles = () => [
  {
    label: t(`roles.admin`),
    value: IRoleEnum.ADMIN,
  },
  {
    label: t(`roles.dataChampionReviewer`),
    value: IRoleEnum.DATA_CHAMPION_REVIEWER,
  },
  {
    label: t(`roles.materialControllerReviewer`),
    value: IRoleEnum.MATERIAL_CONTROLLER_REVIEWER,
  },
  {
    label: t(`roles.qaQcReviewer`),
    value: IRoleEnum.QA_QC_REVIEWER,
  },
  {
    label: t(`roles.user`),
    value: IRoleEnum.USER,
  },
];
