import { IPageActions } from '@/types/common/IPageActions';

enum IEnumAttributesPageActions {
  'delete',
  'create',
}

export type IAttributesPageActions = {
  action: keyof typeof IEnumAttributesPageActions;
  actionId: string;
};

export const attributesPageActions: IPageActions<IAttributesPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumAttributesPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
