import { FC } from 'react';

import { useUserByRole } from '@api/users/useUserByRole';
import { SelectMultipleFilter } from '@components/molecules/filters/static/SelectMultipleFilter';

import { FilterRender } from '@/types/common/IFilters';
import { IRoleEnum } from '@/types/enums/rolesEnum';

interface UsersSelectMultipleInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const UsersSelectMultipleFilter: FC<UsersSelectMultipleInterface> = ({
  filterRender,
}) => {
  // const [userName, setUserName] = useState('');
  const usersQuery = useUserByRole({
    role: IRoleEnum.USER,
  });
  const adminUsersQuery = useUserByRole({
    role: IRoleEnum.ADMIN,
  });
  const users = usersQuery?.data?.data;
  const adminUsers = adminUsersQuery?.data?.data ?? [];

  const options =
    users?.concat(adminUsers).map((user) => ({
      label: user.username,
      value: user.id.toString(),
    })) || [];

  return (
    <SelectMultipleFilter
      filterRender={filterRender}
      loading={usersQuery.isLoading}
      options={options}
    />
  );
};
