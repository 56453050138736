import { Route, Routes } from 'react-router-dom';

import MaterialDetailsPage from './conponents/pages/MaterialDetailsPage';
import MaterialsPage from './conponents/pages/MaterialsPage';

export const MaterialsRouter = () => {
  return (
    <Routes>
      <Route element={<MaterialsPage />} index />
      <Route element={<MaterialDetailsPage />} path="/:id" />
    </Routes>
  );
};
