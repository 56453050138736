import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IMaterial } from '@/types/IMaterial';

type IMaterialsResponse = IPageAbleResponse<IMaterial>;

export function useMaterials({
  ...params
}: IPageAbleRequest & {
  attributesFilters?: any;
  materialFilters?: any;
  searchQuery?: string;
}) {
  return useQuery<IMaterialsResponse, AxiosError>(
    [queryKeys.materials, params],
    () =>
      api
        .post(
          '/materials/api/materials/all',
          {
            attributesFilters: params.attributesFilters,
            materialFilters: params.materialFilters,
          },
          {
            params: {
              page: params.page,
              searchQuery: params.searchQuery,
              size: params.size,
              sort: params.sort,
            },
          }
        )
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
