import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 14 14"
    {...props}
  >
    <path
      fill="#1F4A62"
      d="M5.096 12.855V10.81c0-.518.421-.939.943-.942h1.916c.524 0 .95.422.95.942v2.039c0 .45.364.814.817.818h1.307A2.307 2.307 0 0 0 12.66 13c.431-.428.674-1.009.674-1.615V5.577c0-.49-.218-.954-.597-1.268L8.296.783a2.077 2.077 0 0 0-2.64.047L1.312 4.31a1.65 1.65 0 0 0-.644 1.268v5.802a2.296 2.296 0 0 0 2.304 2.288h1.277a.821.821 0 0 0 .582-.236.81.81 0 0 0 .242-.576z"
    />
  </svg>
);
const Memo = memo(SvgHome);
export default Memo;
