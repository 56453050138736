import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IStockRequest } from '@/types/IStockRequest';

export type IStockMaterialResponse = IStockRequest;

export function useStockRequestById({ ...params }: { sarId: number }) {
  return useQuery<IStockMaterialResponse, AxiosError>(
    [queryKeys.stockRequest, params],
    () =>
      api.get(`/materials/api/sars/${params.sarId}`).then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) && !!params.sarId,
    }
  );
}
