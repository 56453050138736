import { type FC, useContext, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import {
  DeleteOutlined,
  EditOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import { useFilterNouns } from '@api/filters/useFilterNouns';
import { useUsersReviewers } from '@api/users/useUsersReviewers';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { SarContext } from '@context/SarContext';
import { UserContext } from '@context/UserContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Alert, Button, Loader, Text } from '@mantine/core';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IRoleEnum } from '@/types/enums/rolesEnum';

import { ConfirmPublishModal } from '../organisms/modals/ConfirmPublishModal';
import { CreateStockRequestForm } from '../organisms/CreateStockRequestForm';
import { QueryTable } from '@components/organisms/QueryTable';
import { sarMaterialsColumns } from '@modules/sar/consts/columns/sarMaterialsColumns';
import { sarMaterialsFilters } from '@modules/sar/consts/filters/sarMaterialsFilters';
import { IconInfoCircle } from '@tabler/icons-react';
import { useStockMaterialsPaged } from '@api/sar-materials/useStockMaterialsPaged';
import { EditStockMaterialModal } from '../organisms/modals/EditStockMaterialModal';
import { AddStockMaterialModal } from '../organisms/modals/AddStockMaterialModal';
import { AddCertificateModal } from '../organisms/modals/AddCertificateModal';

export const CreateStockRequestPage: FC = () => {
  const { sarId: id, sarStatus } = useContext(SarContext);
  const { userMe } = useContext(UserContext);
  const { actioned, clearParams, filtered, onFilter, setPartialActioned } =
    useUrlFilteredActioned<IPageAbleRequest, ISARPageActions>(
      sarMaterialsFilters,
      sarPageActions
    );

  //GET Nouns
  const nounsQuery = useFilterNouns();
  const nouns = useMemo(() => {
    const obj = nounsQuery.data;
    return obj
      ? Object.keys(obj)
          .map((key) => ({ key, value: obj[key] }))
          .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
      : [];
  }, [nounsQuery.data]);

  const materialControllerUserQuery = useUsersReviewers({
    role: IRoleEnum.MATERIAL_CONTROLLER_REVIEWER,
  });
  const materialControllerUser = materialControllerUserQuery.data?.data;

  const qaQcReviewerUserQuery = useUsersReviewers({
    role: IRoleEnum.QA_QC_REVIEWER,
  });
  const qaQcReviewerUser = qaQcReviewerUserQuery.data?.data;

  const location = useLocation();
  const { sarId } = useParams();
  const isEditPage = location.pathname.includes('edit');
  const stockMaterialsQuery = useStockMaterialsPaged({
    sarId: isEditPage ? Number(sarId) : id,
  });

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/sar', title: 'Stock Authorization Requests' },
          { link: '/sar/create', title: 'Create SAR' },
        ]}
        selectedMenuKeys={['sar']}
        title="Create Stock Authorization Requests"
      />
      <div className="flex justify-between items-center">
        <Text className="my-6">New Stock Authorization Request</Text>
        {/* FIXME: check for material form & info form ready */}
        <Button
          disabled={
            !stockMaterialsQuery.data?.content ||
            stockMaterialsQuery.data?.content.length == 0
          }
          w={200}
          onClick={() => {
            setPartialActioned({
              action: 'publishSar',
              actionId: id.toString(),
            });
          }}
        >
          Send
        </Button>
      </div>

      {qaQcReviewerUserQuery.isLoading &&
      materialControllerUserQuery.isLoading ? (
        <Loader />
      ) : !(userMe && qaQcReviewerUser && materialControllerUser) ? (
        <NotFoundResult
          subTitle=" Please try again later!"
          title="There is an error"
        />
      ) : (
        <CreateStockRequestForm
          materialControllerReviewer={materialControllerUser?.email ?? ''}
          materialControllerReviewerId={Number(materialControllerUser?.id) ?? 0}
          qaQcReviewer={qaQcReviewerUser?.email ?? ''}
          qaQcReviewerId={Number(qaQcReviewerUser?.id) ?? 0}
          userId={userMe.id}
        />
      )}

      {id == 0 ? (
        <></>
      ) : stockMaterialsQuery.data?.content &&
        stockMaterialsQuery.data?.content.length == 0 ? (
        <Alert
          variant="light"
          color="yellow"
          title="No materials added"
          icon={<IconInfoCircle color="#FD7E14" />}
        >
          No materials added. Press Add materials button and add materials to
          the Stock Authorization Request
        </Alert>
      ) : (
        <div className="overflow-x-scroll">
          <QueryTable
            actions={(row) => [
              {
                icon: <DeleteOutlined />,
                onClick: () => {
                  setPartialActioned({
                    action: 'deleteMaterial',
                    actionId: row.id.toString() ?? '',
                  });
                },
                title: 'Delete',
              },
              {
                icon: <EditOutlined />,
                onClick: () => {
                  setPartialActioned({
                    action:
                      row.certificates && row.certificates.length != 0
                        ? 'editCertificate'
                        : 'addCertificate',
                    actionId: row.id.toString() ?? '',
                  });
                },
                title:
                  row.certificates && row.certificates.length != 0
                    ? 'Edit certificate'
                    : 'Add certificate',
              },
            ]}
            columns={sarMaterialsColumns}
            empty={{
              description:
                'No materials were created by this user or no request matches your search or filtering criteria',
              title: 'No result',
            }}
            data={stockMaterialsQuery.data}
            query={stockMaterialsQuery}
            tableFilter={{ filtered, filters: sarFilters, onFilter }}
          />
        </div>
      )}

      {id != 0 && (
        <Button
          className="my-6"
          disabled={!nounsQuery.isFetched}
          leftSection={<FileAddOutlined className="w-4 h-4" />}
          onClick={() => {
            setPartialActioned({
              action: 'addMaterial',
            });
          }}
          variant="outline"
        >
          Add Material
        </Button>
      )}

      <AddStockMaterialModal
        isOpen={actioned.action === 'addMaterial'}
        nouns={nouns}
        onClose={clearParams}
      />

      <EditStockMaterialModal
        isOpen={actioned.action === 'editMaterial'}
        onClose={clearParams}
        sarMaterialId={Number(actioned.actionId)}
      />

      <ConfirmPublishModal
        id={actioned.actionId}
        isOpen={actioned.action === 'publishSar'}
        onClose={clearParams}
      />

      <AddCertificateModal
        isOpen={
          actioned.action === 'addCertificate' ||
          actioned.action === 'editCertificate'
        }
        onClose={clearParams}
        id={actioned.actionId}
      />
    </div>
  );
};
