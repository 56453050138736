import { useTranslation } from 'react-i18next';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { ActionIcon, Text, Title } from '@mantine/core';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IStockRequest } from '@/types/IStockRequest';
import { NumberTag } from '@components/atoms/NumberTag';
import { SarStatusTag } from '../atoms/SarStatusTag';
import moment from 'moment';
import { IconEdit } from '@tabler/icons-react';
import { UserContext } from '@context/UserContext';
import { useContext } from 'react';

export default function SarDetailsCard({ sar }: { sar: IStockRequest }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    ISARPageActions
  >(sarFilters, sarPageActions);
  const { userMe } = useContext(UserContext);

  return (
    <div className="flex flex-col p-6 items-start bg-white rounded-xl border-b-0 mb-6">
      <div className="flex justify-between w-full">
        <div className="flex items-center gap-6 w-full">
          <NumberTag id={sar.id} />
          <Title order={3}>{sar.sarName}</Title>
          <SarStatusTag status={sar.status} />
        </div>
        {(userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
          sar.status == 'AWAITING_ENG_REVIEW') ||
        (userMe?.role == 'USER' && sar.status == 'DRAFT') ||
        (userMe?.role == 'USER' && sar.status == 'AWAITING_REQUISITIONER') ||
        (userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
          sar.status == 'AWAITING_MDM') ||
        (userMe?.role == 'QA_QC_REVIEWER' && sar.status == 'AWAITING_QA_QC') ? (
          <ActionIcon
            aria-label="Edit Material"
            onClick={() => {
              setPartialActioned({
                action: 'edit',
                actionId: sar.id.toString() ?? '',
              });
            }}
            variant="transparent"
          >
            <IconEdit style={{ height: '70%', width: '70%' }} />
          </ActionIcon>
        ) : (
          <></>
        )}
      </div>
      <div className="grid grid-cols-2 gap-[112px] mt-8">
        <div>
          <div className="grid grid-cols-2 gap-10 pb-2 mb-3 border-b border-b-1 border-b-[#DEE2E6]">
            <Text>Data champion reviewer</Text>
            <Text>{sar.dataChampionReviewerUser?.name}</Text>
          </div>
          <div className="grid grid-cols-2 gap-10 pb-2 mb-3 border-b border-b-1 border-b-[#DEE2E6]">
            <Text>QA/QC reviewer</Text>
            <Text>{sar.qaQcReviewerUser?.name}</Text>
          </div>
          <div className="grid grid-cols-2 gap-10 pb-2 mb-3 border-b border-b-1 border-b-[#DEE2E6]">
            <Text>Material Controller reviewer</Text>

            <Text>{sar.materialControllerReviewerUser?.name}</Text>
          </div>
        </div>

        <div className="">
          <div className="grid grid-cols-2 gap-10 pb-2 mb-3 border-b border-b-1 border-b-[#DEE2E6]">
            <Text>Object Part</Text>
            <Text>{sar.objectPart}</Text>
          </div>
          <div className="grid grid-cols-2 gap-10 pb-2 mb-3 border-b border-b-1 border-b-[#DEE2E6]">
            <Text>Creation date</Text>
            <Text>{moment(sar.createdAt).format('DD MMM YYYY HH:mm')}</Text>
          </div>
          <div className="grid grid-cols-2 gap-10 pb-2 mb-3 border-b border-b-1 border-b-[#DEE2E6]">
            <Text>Update date</Text>
            <Text>{moment(sar.updatedAt).format('DD MMM YYYY HH:mm')}</Text>
          </div>
        </div>
      </div>
    </div>
  );
}
