import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

export type IStockMaterialResponse = boolean;

export function useStockRequestCanMakeAction({ ...params }: { sarId: number }) {
  return useQuery<IStockMaterialResponse, AxiosError>(
    [queryKeys.stockRequestCanMakeAction, params],
    () =>
      api
        .get(`/materials/api/sars/can-make-action/${params.sarId}`)
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) && !!params.sarId,
    }
  );
}
