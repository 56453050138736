import { type FC, useEffect } from 'react';
import { useState } from 'react';

import { t } from 'i18next';

import { useUser } from '@api/users/useUser';
import { useUserActivate } from '@api/users/useUserActivate';
import { useUserBlock } from '@api/users/useUserBlock';
import { useUserDemote } from '@api/users/useUserDemote';
import { useUserPromote } from '@api/users/useUserPromote';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import {
  Button,
  Divider,
  Modal,
  Select,
  Skeleton,
  Switch,
  TextInput,
} from '@mantine/core';
import {
  getUserPermissions,
  PermissionsEnum,
} from '@modules/users/consts/userPermissions';

type UserEditModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const UserDetailsModal: FC<UserEditModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const [isActive, setIsActive] = useState<boolean>();
  const [userPermission, setUserPermission] = useState<string | null>('');
  const userQuery = useUser({ id });
  const user = userQuery.data?.data;

  useEffect(() => {
    if (user) {
      setIsActive(user.status !== 'ACTIVE');
      setUserPermission(PermissionsEnum[user.role]);
    }
  }, [user]);

  const promoteUser = useUserPromote();
  const demoteUser = useUserDemote();
  const blockUser = useUserBlock();
  const activateUser = useUserActivate();

  const handleSubmit = () => {
    if (user) {
      if (userPermission === 'USER' && userPermission !== user.role) {
        demoteUser.mutateAsync({ id: user.id }).then(() => {
          onClose();
        });
      } else if (userPermission !== user.role) {
        promoteUser.mutateAsync({ id: user?.id ?? '' }).then(() => {
          onClose();
        });
      }

      if (isActive && user.status === 'ACTIVE') {
        blockUser.mutateAsync({ id: user.id }).then(() => {
          onClose();
        });
      } else if (!isActive && user.status === 'BLOCKED') {
        activateUser.mutateAsync({ id: user?.id ?? '' }).then(() => {
          onClose();
        });
      }
    }
  };

  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      size={570}
      title="Edit user information"
    >
      {userQuery.isLoading ? (
        <div className="flex flex-col gap-8">
          <Skeleton className="w-full h-40" />
          <Skeleton className="w-full h-40" />
        </div>
      ) : !user ? (
        <NotFoundResult
          subTitle="There is no detailt about this user"
          title="No result"
        />
      ) : (
        <div className="grid gap-4">
          <div className="grid grid-cols-2 gap-4">
            <TextInput
              disabled
              label="Name"
              value={user.firstName + ' ' + user.lastName ?? '-'}
            />
            <TextInput disabled label="Username" value={user.username ?? '-'} />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <TextInput disabled label="Email" value={user.email ?? '-'} />

            <TextInput
              disabled
              label="Job Title"
              value={user.jobTitle ?? '-'}
            />
          </div>

          <Select
            comboboxProps={{ withinPortal: false }}
            data={getUserPermissions.map((permission) => ({
              label: permission.label,
              value: permission.value,
            }))}
            disabled={userPermission === 'ADMIN'}
            label={t('user.role')}
            onChange={setUserPermission}
            value={userPermission}
          />

          <Divider />

          <Switch
            checked={isActive}
            classNames={{
              body: 'flex flex-col md:flex-row items-center text-center gap-2',
              label: 'p-0 md:pr-2',
            }}
            label="Blocked"
            labelPosition="left"
            onChange={() => {
              setIsActive(!isActive);
            }}
          />

          <div className="flex justify-end gap-4 px-5">
            <Button onClick={onClose} variant="outline">
              {t('cancel')}
            </Button>
            <Button
              loading={
                promoteUser.isLoading ||
                demoteUser.isLoading ||
                activateUser.isLoading ||
                blockUser.isLoading
              }
              onClick={handleSubmit}
              variant="outline"
            >
              {t('save')}
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};
