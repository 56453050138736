import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Text } from '@mantine/core';

import { IMaterial } from '@/types/IMaterial';
import { NumberTag } from '@components/atoms/NumberTag';
import { isObject } from 'lodash';

export default function MaterialCard({ material }: { material: IMaterial }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const branchPlant = useMemo(() => {
    return material.branchPlant
      ? Object.entries(material.branchPlant).map(([key, value]) => {
          const branchSum = Object.values(value).reduce(
            (a, b) => Number(a) + Number(b),
            0
          );

          return {
            items: isObject(value)
              ? Object.entries(value).map(([k, v]) => ({
                  key: k,
                  value: v.toString(),
                }))
              : [],
            key,
            value: isObject(value) ? branchSum : value,
          };
        })
      : null;
  }, [material]);

  return (
    <div className="grid grid-cols-2 rounded-xl  gap-8  p-4  bg-white lg:items-center">
      <div className="flex flex-col gap-1 col-span-1">
        <NumberTag id={material.stockNumber} />
        <span className="normal-case text-lg font-bold mb-4 lg:mb-0">
          {material.noun}
        </span>
        <span className="text-[#6B7280] text-sm font-normal">Disc</span>
        <div className="flex items-center gap-1 text-gray-400 line-clamp-2">
          {material.description1}
          asdasdadasdasdasdasdasdasdasdasdasdadsasdasdadasdasdasdasdasdasdasdasdasdadsasdasdadasdasdasdasdasdasdasdasdasdadsasdasdadasdasdasdasdasdasdasdasdasdadsasdasdadasdasdasdasdasdasdasdasdasdadsasdasdadasdasdasdasdasdasdasdasdasdadsasdasdadasdasdasdasdasdasdasdasdasdadsasdasdadasdasdasdasdasdasdasdasdasdads
        </div>
        <Link to={`/materials/${material.id}`}>
          <span className="text-[#6B7280] underline font-normal text-xs">
            more info
          </span>
        </Link>
      </div>

      {branchPlant ? (
        <div className="col-span-1 text-sm">
          <Text fw={600} size="sm">
            Branch plant:
          </Text>
          {branchPlant.map((item, key) => (
            <div className="grid grid-cols-2 border-b-2 p-2" key={key}>
              <Text size="sm" className="text-[#6B7280]">
                {item.key}
              </Text>{' '}
              <Text size="sm">{item.value}</Text>
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
