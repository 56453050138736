import { FC, useMemo, useState } from 'react';

import { useFilterAttributes } from '@api/filters/useFilterAttributes';
import { useFilterModifiers } from '@api/filters/useFilterModifiers';
import { useFilterNouns } from '@api/filters/useFilterNouns';
import { useMaterials } from '@api/materials/useMaterials';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  Accordion,
  Button,
  Chip,
  CloseButton,
  Group,
  Input,
  Pagination,
  Select,
  Text,
  Title,
} from '@mantine/core';
import { materialsFilters } from '@modules/materials/consts/filters/materialsFilters';
import {
  IMaterialsPageActions,
  materialsPageActions,
} from '@modules/materials/consts/pageActions/IMaterialsPageActions';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import MaterialSkeleton from '../atoms/MaterialSkeleton';
import MaterialCard from '../organisms/MaterialCard';

const MaterialsPage: FC = () => {
  const [nounId, setNounId] = useState<string | null>('');
  const [modifierId, setModifierId] = useState<string | null>('');
  const [attributeIds, setAttributeIds] = useState<string[]>([]);
  const [filters, setFilters] = useState<any>();

  const [searchQuery, setSearchQuery] = useState('');

  const { filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    IMaterialsPageActions
  >(materialsFilters, materialsPageActions);

  //GET Nouns
  const nounsQuery = useFilterNouns();
  const nouns = useMemo(() => {
    const obj = nounsQuery.data;
    return obj
      ? Object.keys(obj)
          .map((key) => ({ key, value: obj[key] }))
          .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
      : [];
  }, [nounsQuery.data]);

  //GET Modifiers
  const modifiersQuery = useFilterModifiers({
    id: nounId as string,
  });
  const modifiers = useMemo(() => {
    const obj = modifiersQuery.data;
    return obj
      ? Object.keys(obj)
          .map((key) => ({ key, value: obj[key] }))
          .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
      : [];
  }, [modifiersQuery.data]);

  const noun = useMemo(() => {
    return nouns?.find((item) => item.key === nounId);
  }, [nounId]);

  const modifier = useMemo(() => {
    return modifiers?.find((item) => item.key === modifierId);
  }, [modifierId]);

  //GET Attributes
  const attributesQuery = useFilterAttributes({
    modifier: modifier?.value,
    noun: noun?.value,
  });
  const attributes = useMemo(() => {
    const obj = attributesQuery.data?.attributesFilters;
    return obj
      ? Object.keys(obj)
          .map((key) => ({ key, values: obj[key] }))
          .sort((a, b) => (a.key > b.key ? 1 : b.key > a.key ? -1 : 0))
      : [];
  }, [attributesQuery.data]);

  const isSearchFiltered = useMemo(() => {
    return searchQuery !== '' && noun;
  }, [searchQuery]);

  //GET Materials
  const materialsQuery = useMaterials({
    ...filtered,
    attributesFilters: isSearchFiltered ? undefined : filters ?? undefined,
    materialFilters: isSearchFiltered
      ? undefined
      : attributesQuery.data?.materialFilters,
    searchQuery,
  });
  const materials = materialsQuery.data?.content;

  //Rendeer filter chips
  const attributesFilters = useMemo(() => {
    return attributes?.map((attribute, key) => (
      <Accordion.Item key={key} value={attribute.key}>
        <Accordion.Control>{attribute.key}</Accordion.Control>
        <Accordion.Panel>
          <Chip.Group
            multiple
            onChange={(v) =>
              setFilters((prevState) => ({
                ...prevState,
                [attribute.key]: v,
              }))
            }
          >
            <Group mt="md">
              {attribute.values.map((value, index) => (
                <Chip
                  className="max-w-[200px]"
                  icon={<></>}
                  key={index}
                  value={value}
                  variant="outline"
                >
                  {value}
                </Chip>
              ))}
            </Group>
          </Chip.Group>
        </Accordion.Panel>
      </Accordion.Item>
    ));
  }, [attributes, attributeIds]);

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/materials', title: 'Materials' }]}
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-all']}
        title="Materials"
      />
      <div className="flex pt-6">
        <Text>All Materials</Text>
        <span className="rounded-2xl text-[#1F4A62] px-2 bg-[#F9F5FF]">
          {materialsQuery.data?.totalElements}
        </span>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div className="bg-white col-span-1 p-6 rounded-xl mt-5">
          <Title size="xl">Filter</Title>
          <div>
            <Select
              className="mt-0"
              data={
                nouns?.map((noun) => ({
                  label: noun.value,
                  value: noun.key,
                })) ?? []
              }
              disabled={nounsQuery.isLoading}
              label="Nouns"
              onChange={setNounId}
              placeholder="Nouns"
              searchable
              value={nounId}
            />
            <Select
              className="mt-0"
              data={
                modifiers?.map((modifier) => ({
                  label: modifier.value,
                  value: modifier.key,
                })) ?? []
              }
              disabled={modifiersQuery.isLoading}
              label="Modifiers"
              nothingFoundMessage="Nothing found..."
              onChange={setModifierId}
              placeholder="Modifiers"
              value={modifierId}
            />
            {/* <MultiSelect
              className="mt-0"
              data={
                attributes?.map((attribute) => ({
                  label: attribute.key,
                  value: attribute.key as string,
                })) ?? []
              }
              disabled={attributesQuery.isLoading}
              label="Attributes"
              onChange={setAttributeIds}
              placeholder="Attributes"
              value={attributeIds}
            /> */}
            <Accordion defaultValue="Apples">{attributesFilters}</Accordion>
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-end gap-4 col-span-3 mt-1">
              <Input
                className="w-full mt-0"
                mt="md"
                onChange={(event) => setSearchQuery(event.currentTarget.value)}
                placeholder="Search..."
                rightSection={
                  <CloseButton
                    aria-label="Clear input"
                    onClick={() => setSearchQuery('')}
                    style={{ display: searchQuery ? undefined : 'none' }}
                  />
                }
                value={searchQuery}
              />
              <Select
                className="mt-0"
                data={['10', '20'].map((permission) => ({
                  label: permission,
                  value: permission,
                }))}
                placeholder="Sort"
              />
            </div>

            {materialsQuery.isLoading ||
            (!!materials?.length && materialsQuery.isLoading) ? (
              <>
                <MaterialSkeleton />
                <MaterialSkeleton />
              </>
            ) : !materials?.length ? (
              <>
                <NotFoundResult
                  subTitle="No matching materials have been found. Do you want to send a Stock Authorization Request (SAR) in order to create new material?"
                  title=""
                />
                <Button disabled variant="outline">
                  Create SAR request
                </Button>
              </>
            ) : (
              materials?.map((material) => (
                <MaterialCard key={material.id} material={material} />
              ))
            )}
          </div>

          {materialsQuery.data?.totalElements !== 0 && (
            <div className="flex justify-between gap-4 mt-5">
              <Pagination
                classNames={{
                  control:
                    'border-gray-200 border-[#DEE2E6] data-[active=true]:bg-[#1F4A62] data-[active=true]:border-primary data-[active=true]:text-white',
                }}
                onChange={(page) => onFilter({ page })}
                total={Math.ceil(
                  (materialsQuery.data?.totalElements || 1) /
                    (filtered.size || 10)
                )}
                value={
                  typeof filtered.page == 'string'
                    ? Number.parseInt(filtered.page)
                    : filtered.page || 1
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaterialsPage;
