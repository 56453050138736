import { useLogout } from '@api/auth/useLogout';
import { Logout } from '@assets/icons';
import { useUserContext } from '@context/UserContext';
import { Menu, UnstyledButton } from '@mantine/core';

import ProfileAvatar from './ProfileAvatar';

export default function ProfileDropdown() {
  const { logout } = useLogout();
  const me = useUserContext().userMe;

  return (
    <>
      <Menu
        classNames={{ dropdown: 'min-w-[200px] lg:min-w-[300px] mt-2.5' }}
        position="bottom-end"
        variant="default"
        withArrow
      >
        <Menu.Target>
          <UnstyledButton className="flex gap-1 md:gap-3 items-center">
            <ProfileAvatar me={me} />
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Label className="flex items-center gap-4 p-4">
            {me?.email}
          </Menu.Label>

          <Menu.Divider />

          <Menu.Item
            leftSection={<Logout height={16} />}
            onClick={() => logout()}
          >
            Logout
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
