import { FC, useContext } from 'react';

import { SelectMultipleFilter } from '@components/molecules/filters/static/SelectMultipleFilter';
import { SarContext } from '@context/SarContext';

import { FilterRender } from '@/types/common/IFilters';

interface MaterialNumberSelectFilterInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const MaterialNumberSelectFilter: FC<
  MaterialNumberSelectFilterInterface
> = ({ filterRender }) => {
  const { sarNumbersList } = useContext(SarContext);

  const options =
    sarNumbersList?.map((sarNumber) => ({
      label: sarNumber,
      value: sarNumber.toString(),
    })) || [];

  return (
    <SelectMultipleFilter
      filterRender={filterRender}
      loading={options.length == 0}
      options={options}
    />
  );
};
