import { Route, Routes } from 'react-router-dom';

import MeasurementUnitsPage from './MeasurementUnitsPage';

export const MeasurementUnitsRouter = () => {
  return (
    <Routes>
      <Route element={<MeasurementUnitsPage />} index />
    </Routes>
  );
};
