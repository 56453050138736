import { IPageActions } from '@/types/common/IPageActions';

enum IEnumMaterialsPageActions {
  'view',
}

export type IMaterialsPageActions = {
  action: keyof typeof IEnumMaterialsPageActions;
  actionId: string;
};

export const materialsPageActions: IPageActions<IMaterialsPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumMaterialsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
