import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IWorkOrder } from '@/types/IWorkOrder';

type IOrdersResponse = IWorkOrder[];

export function useWorkOrders() {
  return useQuery<IOrdersResponse, AxiosError>(
    [queryKeys.orders],
    () => api.get('/work-orders/api/orders').then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
