import { MantineThemeOverride } from '@mantine/core';
import { colors } from './colors';


export const mantineTheme: MantineThemeOverride = {
  colors: {
    blue: [
      colors.primary['50'],
      colors.primary['100'],
      colors.primary['200'],
      colors.primary['300'],
      colors.primary['400'],
      colors.primary['500'],
      colors.primary['600'],
      colors.primary['700'],
      colors.primary['800'],
      colors.primary['900'],
    ],
    gray: [
      colors.gray['50'],
      colors.gray['100'],
      colors.gray['200'],
      colors.gray['300'],
      colors.gray['400'],
      colors.gray['500'],
      colors.gray['600'],
      colors.gray['700'],
      colors.gray['800'],
      colors.gray['900'],
    ],
    green: [
      colors.green['50'],
      colors.green['100'],
      colors.green['200'],
      colors.green['300'],
      colors.green['400'],
      colors.green['500'],
      colors.green['600'],
      colors.green['700'],
      colors.green['800'],
      colors.green['900'],
    ],
    red: [
      colors.red['50'],
      colors.red['100'],
      colors.red['200'],
      colors.red['300'],
      colors.red['400'],
      colors.red['500'],
      colors.red['600'],
      colors.red['700'],
      colors.red['800'],
      colors.red['900'],
    ],
    yellow: [
      colors.yellow['50'],
      colors.yellow['100'],
      colors.yellow['200'],
      colors.yellow['300'],
      colors.yellow['400'],
      colors.yellow['500'],
      colors.yellow['600'],
      colors.yellow['700'],
      colors.yellow['800'],
      colors.yellow['900'],
    ],
  },
  components: {
    Input: {
      defaultProps: { variant: 'filled' },
      styles: () => ({
        input: {
          '&:disabled': { border: 'none' },
          '&:focusWithin': {
            backgroundColor: colors.primary['102'],
            borderColor: colors.primary['400'],
          },
          '&:hover': { backgroundColor: colors.primary['102'], borderColor: colors.primary['400'] },
          '&[dataInvalid]': { borderColor: '#FF564E !important' },
          backgroundColor: 'transparent',
          borderColor: colors.gray['200'],
          borderRadius: 8,
          color: colors.primary['900'],
          height: '40px',
        },
      }),
    },
    InputWrapper: {
      defaultProps: { inputWrapperOrder: ['label', 'input', 'error', 'description'] },
      styles: (theme) => ({
        description: {
          color: theme.colors.gray[5],
          fontSize: '12px',
          fontWeight: 400,
          marginTop: '8px',
        },
        label: { color: theme.colors.gray[6], marginBottom: '4px' },
      }),
    },
    Modal: {
      defaultProps: { closeButtonProps: { iconSize: 24 } },
      styles: () => ({
        body: { overflowY: 'auto', padding: '24px' },
        content: { borderRadius: '12px', display: 'flex', flexDirection: 'column' },
        header: {
          padding: '24px',
          paddingBottom: 0,
        },
        inner: { zIndex: 501 },
        overlay: { zIndex: 500 },
        title: { fontSize: '18px', fontWeight: 600, width: '100%', color: '#228BE6' },
      }),
    },
    Navbar: { styles: () => ({ root: { padding: 0 } }) },
    PasswordInput: { styles: () => ({ innerInput: { height: '100%' }, 
    // section: {
    //   left: '12px'
    // }
   }) },
    Popover: {
      styles: () => ({
        dropdown: {
          'zIndex': '1001 !important',
        },
      }),
    },
    Accordion: {
      styles: () => ({
        item: {
          marginBottom: '24px',
        },
        control: {
          '&:hover': { borderRadius: '0.75rem !important'},
        },
        label: {
          padding: 0
        }
      }),
    },
    RadioGroup: {
      styles: () => ({
        error: { marginLeft: '12px', marginTop: '12px' },
      }),
    },
    Table: {
      styles: () => ({ table: { 
        background: 'white', 
        borderRadius:'12px' 
      } }),
    },
    Select: {
      // classNames: { input: 'bg-white' },
      styles: () => ({ dropdown: { 'zIndex': '20' } }),
    },
    Checkbox: {
      styles: () => ({ icon: { width: '50%' }, inner: { marginRight: '2px' } }),
    },
    Switch: {
      styles: () => ({
        label: { color: '#6B7280', fontSize: '14px', fontWeight: 'normal' },
        thumb: { border: 'none' },
        track: {
          '&:checked': { background: '#5D70FF' },
          border: 'none',
          height: '18px',
          minWidth: 0,
          width: '34px',
        },
      }),
    },
    NavLink: {
        styles: () => ({
          children: { '.mantineNavLinkRoot': { paddingLeft: '3rem' } },
          icon: {
            color: colors.gray['300'],
          },
          label: {
            color: '#53545C',
            fontWeight: 400,
          },
          root: {
            '&.active': {
              '&:hover': {
                backgroundColor: colors.primary.DEFAULT,
              },
              '.mantine-NavLink-icon': {
                color: colors.primary['400'],
              },
              '.mantine-NavLink-label': {
                color: '#FFFFFF',
                fontWeight: 700,
              },
              backgroundColor: '#1F4A62 !important',
              color: 'red'
            },
            '&:hover': {
              backgroundColor: '#e8ecef',
            },
            padding: '12px 24px',
            borderRadius: '12px',
          },
        }),
    },
    Tabs: {
      styles: (theme) => ({
        list: {
          '&:before': { borderColor: '#DEE2E6'},
        },
        tab: {
          '&.active': { border: ' 1px solid #228BE6', color: '#0D0D54' },
          background: '#FFF',
          color: theme.colors.gray[5],
          fontFamily: 'Inter, sans-serif',
          fontSize: '12px',
          fontWeight: 400,
          padding: '12px 20px',
          borderColor: '#DEE2E6'
        },
      }),
    },
    TimeInput: { styles: () => ({ input: { height: '31px !important' } }) },
    Alert: { styles: () => ({
      root: { border: '1px solid #FD7E14', borderRadius: '2px', background: '#FFF4E6', padding: '12px 16px' } ,
      label: {
      color: '#FD7E14'
      }
    }) },
    ActionIcon: { styles: () => ({ root: { color: '#8E8E93', '&:hover': { color: '#397BFF !important' }, } }) },
    Chip: { styles: () => ({ 
      label: { borderRadius: '12px', height: 'fit-content', textWrap: 'balance' }, 
    iconWrapper: {
      display: 'none'
    }
    })},
  },
  fontFamily: 'Inter, sans-serif',
  fontFamilyMonospace: 'Inter, monospace',
  headings: {
    fontWeight: '600',
    sizes: {
      h1: { fontSize: '36px' },
      h2: { fontSize: '24px' },
      h3: { fontSize: '18px' },
    },
  },
};
