import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgLock = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 18 20"
    {...props}
  >
    <path
      stroke="#6E7079"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M13.423 7.448V5.3A4.552 4.552 0 0 0 8.873.75a4.55 4.55 0 0 0-4.57 4.53v2.168"
    />
    <path
      stroke="#6E7079"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M12.683 19.25h-7.64a3.792 3.792 0 0 1-3.793-3.793v-4.289a3.792 3.792 0 0 1 3.792-3.792h7.641a3.792 3.792 0 0 1 3.792 3.792v4.29a3.792 3.792 0 0 1-3.792 3.792"
      clipRule="evenodd"
    />
    <path
      stroke="#6E7079"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M8.863 12.203v2.22"
    />
  </svg>
);
const Memo = memo(SvgLock);
export default Memo;
