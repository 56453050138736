import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { INoun } from '@/types/INoun';

type INounsResponse = IPageAbleResponse<INoun>;

export function useNouns({
  ...params
}: IPageAbleRequest & {
  searchQuery?: string;
}) {
  return useQuery<INounsResponse, AxiosError>(
    [queryKeys.nouns, params],
    () =>
      api.get('/materials/api/admin/nouns', { params }).then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
