import { FC, useState } from 'react';

import { t } from 'i18next';

import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  Chip,
  CloseButton,
  Group,
  Input,
  SegmentedControl,
  Title,
} from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { ordersColumns } from '@modules/orders/consts/columns/ordersColumns';
import { ordersFilters } from '@modules/orders/consts/filters/ordersFilters';
import { orderMaterialsColumns } from '@modules/orders/consts/columns/orderMaterialsColumns';
import { useOrdersMaterials } from '@api/orders/useOrdersMaterials';
import { orderMaterialsFilters } from '@modules/orders/consts/filters/orderMaterialsFilters';
import { OrderMaterialDetailsModal } from '../organisms/OrderMaterialDetailsModal';
import {
  IOrderMaterialPageActions,
  orderMaterialPageActions,
} from '@modules/orders/consts/pageActions/IOrderMaterialPageActions';
import { useWorkOrdersPaged } from '@api/orders/useWorkOrdersPaged';

const WorkOrdersPage: FC = () => {
  const [value, setValue] = useState('favorited');
  const [view, setView] = useState('orders');
  const [searchQuery, setSearchQuery] = useState('');

  const { filtered, onFilter, actioned, clearParams } = useUrlFilteredActioned<
    IPageAbleRequest,
    IOrderMaterialPageActions
  >(orderMaterialsFilters, orderMaterialPageActions);

  const ordersQuery = useWorkOrdersPaged({
    ...filtered,
    workOrderNumber: searchQuery,
    showFavoriteList: value == 'favorited' ? true : undefined,
  });

  const materialsQuery = useOrdersMaterials({
    ...filtered,
    materialNumber: searchQuery,
    showFavoriteList: value == 'favorited' ? true : undefined,
  });

  const handleTabChange = (value: string) => {
    setValue(value);
  };

  const favoriteCounts = ordersQuery.data?.favoriteCount;
  const workOrdersCount = ordersQuery.data?.workOrdersCount;

  return (
    <div className="py-[10px]">
      <PageMeta
        breadcrumbs={[{ link: '/orders', title: 'Work orders' }]}
        selectedMenuKeys={['orders']}
        title="Work orders"
      />
      <div className="flex justify-between mb-6 items-center">
        <Title order={3}>Work orders</Title>
      </div>

      <div className="bg-white p-5 rounded-xl">
        <div className="flex justify-between items-baseline">
          <Chip.Group
            multiple={false}
            onChange={(value) => handleTabChange(value)}
            value={value}
          >
            <Group className="sar-filter-tabs gap-0 mb-5" justify="start">
              <Chip value="favorited" variant="outline">
                {t('order.favorited')}
                <span className="min-w-[30px] ml-2 text-center bg-[#F2F4F7] px-1 rounded-xl">
                  {favoriteCounts}
                </span>
              </Chip>
              <Chip value="all" variant="outline">
                {t('order.all')}
                <span className="min-w-[30px] ml-2 text-center bg-[#F2F4F7] px-1 rounded-xl">
                  {workOrdersCount}
                </span>
              </Chip>
            </Group>
          </Chip.Group>
          <div className="flex items-center">
            <SegmentedControl
              value={view}
              onChange={setView}
              data={[
                { label: 'Work Order view', value: 'orders' },
                { label: 'Material view', value: 'materials' },
              ]}
            />
            <Input
              className="ml-4 !mt-0 w-[280px]"
              mt="md"
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              placeholder={`Search by ${
                view == 'orders' ? 'order ID' : 'material number'
              }`}
              rightSection={
                <CloseButton
                  aria-label="Clear input"
                  onClick={() => setSearchQuery('')}
                  style={{ display: searchQuery ? undefined : 'none' }}
                />
              }
              rightSectionPointerEvents="all"
              value={searchQuery}
            />
          </div>
        </div>
        <div className="overflow-x-scroll">
          {view == 'orders' && (
            <QueryTable
              columns={ordersColumns}
              empty={{
                description:
                  value == 'favorited'
                    ? 'No work order is favorited.\n Navigate to the All work orders tab and favorite one or add materials to the work order to see the work order'
                    : 'No orders were created by this user or no request matches your search or filtering criteria',
                title: 'No result',
              }}
              data={ordersQuery.data?.workOrders}
              query={ordersQuery}
              tableFilter={{ filtered, filters: ordersFilters, onFilter }}
            />
          )}
          {view == 'materials' && (
            <QueryTable
              hideColumns
              columns={orderMaterialsColumns}
              empty={{
                description:
                  value == 'favorited'
                    ? 'No work order is favorited.\n Navigate to the All work orders tab and favorite one or add materials to the work order to see the work order'
                    : 'No orders were created by this user or no request matches your search or filtering criteria',
                title: 'No result',
              }}
              data={materialsQuery.data?.workOrderMaterials}
              query={materialsQuery}
              tableFilter={{
                filtered,
                filters: orderMaterialsFilters,
                onFilter,
              }}
            />
          )}
        </div>
      </div>

      <OrderMaterialDetailsModal
        id={actioned.actionId}
        isOpen={actioned.action === 'view'}
        onClose={clearParams}
      />
    </div>
  );
};

export default WorkOrdersPage;
