import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { mantineTheme } from '@assets/theme/mantineTheme';
import { PageMetaContextProvider } from '@context/PageMetaContext';
import { SarContextProvider } from '@context/SarContext';
import { UserContextProvider } from '@context/UserContext';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { consoleErrorConfig } from '@utils/configs/consoleConfig';

import App from './App';
import reportWebVitals from './utils/reportWebVitals';

import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@utils/configs/axiosConfig';
import '@assets/styles/index.scss';
import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import { variantColorResolver } from '@assets/theme/colorsResolver';

if (process.env.NODE_ENV === 'development') {
  consoleErrorConfig();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <StrictMode>
      <MantineProvider
        theme={{
          ...mantineTheme,
          variantColorResolver: variantColorResolver,
          activeClassName: '.active',
        }}
        withCssVariables
      >
        <Notifications position="top-right" zIndex={1001} />
        <ModalsProvider>
          <QueryClientProvider client={queryClient}>
            <HelmetProvider>
              <PageMetaContextProvider>
                <UserContextProvider>
                  <SarContextProvider>
                    <BrowserRouter>
                      <App />
                    </BrowserRouter>
                  </SarContextProvider>
                </UserContextProvider>
              </PageMetaContextProvider>
            </HelmetProvider>
            <ReactQueryDevtools
              initialIsOpen={false}
              panelProps={{ className: 'min-h-10' }}
            />
          </QueryClientProvider>
        </ModalsProvider>
      </MantineProvider>
    </StrictMode>
  );
}

reportWebVitals();
