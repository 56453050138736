import { FC, useContext } from 'react';

import { SelectMultipleFilter } from '@components/molecules/filters/static/SelectMultipleFilter';
import { SarContext } from '@context/SarContext';

import { FilterRender } from '@/types/common/IFilters';

interface SarIdSelectFilterInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const SarIdSelectFilter: FC<SarIdSelectFilterInterface> = ({
  filterRender,
}) => {
  const { sarIdList } = useContext(SarContext);

  const options =
    sarIdList?.map((sarId) => ({
      label: sarId,
      value: sarId.toString(),
    })) || [];

  return (
    <SelectMultipleFilter
      filterRender={filterRender}
      loading={options.length == 0}
      options={options}
    />
  );
};
