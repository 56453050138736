import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

export type IStockRequestActionRequest = {
  sarId: number;
};

type IStockRequestActionResponse = AxiosResponse<void>;

export function useDuplicateStockRequest() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockRequestActionResponse,
    AxiosError,
    IStockRequestActionRequest
  >((params) => api.post(`/materials/api/sars/clone/${params.sarId}`), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.stockRequest]);
    },
  });
}
