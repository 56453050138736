import { IModifier } from './IModifier';

export type INoun = {
  id: number | string;
  modifiers: IModifier[];
  noun: string;
};

export const nounKeys: Record<keyof INoun, string> = {
  id: 'id',
  modifiers: 'modifiers',
  noun: 'noun',
};
