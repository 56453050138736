import { type FC } from 'react';

import { Button, Modal } from '@mantine/core';
import { useStockRequestDelete } from '@api/sar/useStockRequestDelete';

type DeleteRequestModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmDeleteRequestModal: FC<DeleteRequestModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const deleteStockRequest = useStockRequestDelete();

  const handleRequestDelete = () => {
    deleteStockRequest.mutateAsync({ id: +id }).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Delete stock request`}
    >
      <div className="max-w-[410px] mx-auto text-center text-gray-600 font-semibold m-5">
        {`Are you sure you want to delete this stock authorization request?`}
      </div>

      <div className="grid gap-4 grid-cols-2">
        <Button onClick={onClose} variant="outline">
          No
        </Button>
        <Button
          loading={deleteStockRequest.isLoading}
          onClick={handleRequestDelete}
          variant="outline"
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};
