import { useTranslation } from 'react-i18next';
import { IHistory } from '@/types/IStockRequest';
import { Avatar, Text } from '@mantine/core';
import moment from 'moment';

export default function HistoryChangeCard({ change }: { change: IHistory }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <div className="border-b border-b-[#DEE2E6] p-6 w-full flex">
      <Avatar size="sm" />
      <div className="flex flex-col ml-[10px]">
        <Text size="sm">
          {moment(change.createdAt).format('DD MMM YYYY HH:mm')}
        </Text>
        <div className="flex gap-[10px] items-center mb-3">
          <span className="text-[#AEAEB2] text-xs">{change.userId}</span>
          <span className="text-[#AEAEB2] text-xs">{change.sarEventName}</span>
        </div>
        <Text>{change.eventText}</Text>
      </div>
    </div>
  );
}
