import { IEnumOrderStatusKeys } from '@consts/enums/IEnumOrderStatusKeys';
import { IBranchPlant } from './IBranchPlant';

export type IWorkOrder = {
  id: number;
  workOrderNumber: number;
  isFavorite: boolean;
  createdAt: string;
};

export const ordersKeys: Record<keyof IWorkOrder, string> = {
  id: 'id',
  createdAt: 'createdAt',
  workOrderNumber: 'workOrderNumber',
  isFavorite: 'isFavorite',
};

export type IWorkOrderMaterial = {
  id: number;
  workOrderId: number;
  workOrderNumber: string;
  materialNumber: string;
  materialDescription?: string;
  requirementQuantity: number;
  unitOfMeasurement: string;
  itemCategory: string;
  storageLocation: string;
  branchPlant: IBranchPlant | null;
  plant: string | null;
  activity: string;
  purchasingOrganization: string;
  purchasingGroup: string;
  unloadingPlant?: string;
  assignedBuyer: string;
  status: IEnumOrderStatusKeys;
  str: string;
  sto: string;
  pr: string;
  po: string;
  deliveryDate: string;
  requirementsDate: string | Date;
  createdBy: number | null;
  updateAt: string;
  branchPlants?: IBranchPlant[];
};

export const orderMaterialsKeys: Record<keyof IWorkOrderMaterial, string> = {
  id: 'id',
  workOrderId: 'workOrderId',
  workOrderNumber: 'workOrderNumber',
  materialNumber: 'materialNumber',
  materialDescription: 'materialDescription',
  requirementQuantity: 'requirementQuantity',
  unitOfMeasurement: 'unitOfMeasurement',
  itemCategory: 'itemCategory',
  storageLocation: 'storageLocation',
  branchPlant: 'branchPlant',
  activity: 'activity',
  purchasingOrganization: 'purchasingOrganization',
  purchasingGroup: 'purchasingGroup',
  unloadingPlant: 'unloadingPlant',
  assignedBuyer: 'assignedBuyer',
  plant: 'plant',
  status: 'status',
  str: 'str',
  sto: 'sto',
  pr: 'pr',
  po: 'po',
  deliveryDate: 'deliveryDate',
  requirementsDate: 'requirementsDate',
  createdBy: 'createdBy',
  updateAt: 'updateAt',
  branchPlants: 'branchPlants',
};
