import { paginationBaseFilters } from '@consts/paginationBaseFilters';

import { IFilters } from '@/types/common/IFilters';
import { userKeys } from '@/types/IUser';

export const usersFilters: IFilters = {
  ...paginationBaseFilters,
  [userKeys.email]: {
    sort: true,
  },
  [userKeys.firstName]: {
    sort: true,
  },
  [userKeys.role]: {
    sort: true,
  },
  [userKeys.status]: {
    sort: true,
  },
  [userKeys.jobTitle]: {
    sort: true,
  },
  [userKeys.username]: {
    sort: true,
  },
};
