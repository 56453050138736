import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IModifier } from '@/types/IModifier';

type IModifiersResponse = IPageAbleResponse<IModifier>;

export function useModifiersByNounId({
  ...params
}: IPageAbleRequest & {
  id: string;
  searchQuery?: string;
}) {
  return useQuery<IModifiersResponse, AxiosError>(
    [queryKeys.modifiers, params],
    () =>
      api
        .get(`/materials/api/admin/modifiers/${params.id}`, {
          params,
        })
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess) && !!params.id,
    }
  );
}
