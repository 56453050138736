import { paginationBaseFilters } from '@consts/paginationBaseFilters';

import { IFilters } from '@/types/common/IFilters';
import { measurementUnitKeys } from '@/types/IMeasurementUnit';

export const measurementsFilters: IFilters = {
  ...paginationBaseFilters,
  [measurementUnitKeys.shortName]: {
    sort: true,
  },
  [measurementUnitKeys.fullName]: {
    sort: true,
  },
};
