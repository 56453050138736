import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type IBasketMaterialAddRequest = { materialId: string; quantity: number };

type IBasketMaterialAddResponse = AxiosResponse<void>;

export function useBasketMaterialAdd({ ...params }: IBasketMaterialAddRequest) {
  const queryClient = useQueryClient();
  return useMutation<IBasketMaterialAddResponse, AxiosError, void>(
    () => api.post(`/materials/api/basket`, {}, { params }),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: (_) => {
        notify('success', 'Material was successfully added to the basket.');
        queryClient.invalidateQueries([queryKeys.basket]);
      },
    }
  );
}
