import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { boolean, number, object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IAttribute } from '@/types/IAttribute';

export type IAttributeCreateRequest = Pick<
  IAttribute,
  'value' | 'key' | 'modifierId' | 'nounId' | 'unitOfMeasurement'
>;

type IAttributeCreateResponse = AxiosResponse<IAttribute>;

export const attributeCreateInitial: IAttributeCreateRequest = {
  key: '',
  modifierId: 0,
  nounId: 0,
  unitOfMeasurement: false,
  value: '',
};

export const attributeCreateValidation: ObjectSchema<IAttributeCreateRequest> =
  object({
    key: string().required(),
    modifierId: number().required(),
    nounId: number().required(),
    unitOfMeasurement: boolean().default(false),
    value: string().required(),
  });

export function useAttributeCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IAttributeCreateResponse,
    AxiosError,
    IAttributeCreateRequest
  >((payload) => api.post('/materials/api/admin/attributes', payload), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => queryClient.invalidateQueries([queryKeys.attributes]),
  });
}
