import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { array, boolean, number, object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

export type IAdditionalAttribute = {
  id?: number;
  isUomRequired?: boolean | null;
  key: string;
  modifierId: number | null;
  nounId: number | null;
  sarMaterialId?: number;
  uom: {
    id?: number | string;
  } | null;
  value: string;
};

export type IStockMaterialAddRequest = {
  modifier: {
    id: number | null;
  };
  noun: {
    id: number | null;
  };
  sarAttributes: IAdditionalAttribute[];
  sarId: number | null;
};

type IStockMaterialAddResponse = AxiosResponse<number>;

export const stockMaterialAddInitial: IStockMaterialAddRequest = {
  modifier: {
    id: null,
  },
  noun: {
    id: null,
  },
  sarAttributes: [],
  sarId: null,
};

export const stockMaterialAddValidation: ObjectSchema<IStockMaterialAddRequest> =
  object({
    modifier: object({
      id: number().required('This field is required!'),
    }),
    noun: object({
      id: number().required('This field is required!'),
    }),
    sarAttributes: array()
      .of(
        object({
          isUomRequired: boolean().nullable(),
          key: string().required(),
          modifierId: number().required(),
          nounId: number().required(),
          uom: object({
            id: number().when('isUomRequired', {
              is: (isUomRequired: boolean) => isUomRequired,
              otherwise: (s) => s.notRequired(),
              then: (s) => s.required(),
            }),
          }).nullable(),
          value: string().required(),
        })
      )
      .required(),
    sarId: number().required('This field is required!'),
  });

export function useAddStockMaterial() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockMaterialAddResponse,
    AxiosError,
    IStockMaterialAddRequest
  >((payload) => api.post('/materials/api/sar-materials', payload), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () =>
      queryClient.invalidateQueries([queryKeys.stockMaterialsPaged]),
  });
}
