import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IAttribute } from '@/types/IAttribute';

type IAttributesResponse = IPageAbleResponse<IAttribute>;

export function useAttributesByNounId({
  ...params
}: IPageAbleRequest & {
  modifierId?: string;
  nounId?: string;
  // searchQuery?: string;
}) {
  return useQuery<IAttributesResponse, AxiosError>(
    [queryKeys.attributes, params],
    () =>
      api
        .get(`/materials/api/admin/attributes`, {
          params,
        })
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) && !!params.modifierId,
    }
  );
}
