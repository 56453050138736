import { Route, Routes } from 'react-router-dom';
import WorkOrderDetailsPage from './components/pages/WorkOrderDetailsPage';

import WorkOrdersPage from './components/pages/WorkOrdersPage';

export const OrdersRouter = () => {
  return (
    <Routes>
      <Route element={<WorkOrdersPage />} index />
      <Route element={<WorkOrderDetailsPage />} path="/details/:orderId" />
    </Routes>
  );
};
