import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IStockRequestMaterial } from '@/types/IStockRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

export type IStockMaterialsResponse = IPageAbleResponse<IStockRequestMaterial>;

export function useStockMaterialsPaged({
  ...params
}: IPageAbleRequest & { sarId: number }) {
  return useQuery<IStockMaterialsResponse, AxiosError>(
    [queryKeys.stockMaterialsPaged, params],
    () =>
      api
        .get(`/materials/api/sar-materials/sar/${params.sarId}/paged`, {
          params,
        })
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) && !!params.sarId,
    }
  );
}
