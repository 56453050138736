import { type FC } from 'react';

import { Modal } from '@mantine/core';
import { EditStockMaterialInfoForm } from '../EditStockMaterialInfoForm';

type IEditStockMaterialInfoModal = {
  isOpen: boolean;
  onClose: () => void;
};

export const EditStockMaterialInfoModal: FC<IEditStockMaterialInfoModal> = ({
  isOpen,
  onClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      centered
      onClose={handleClose}
      opened={isOpen}
      size={940}
      title="Edit Material Info"
    >
      <EditStockMaterialInfoForm onClose={onClose} />
    </Modal>
  );
};
