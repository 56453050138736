import { FC, useState } from 'react';

import { ActionIcon, ButtonProps } from '@mantine/core';
import { StarOutlined } from '@ant-design/icons';
import { useFavoriteOrder } from '@api/orders/useFavoriteOrder';
import { notify } from '@utils/notify';
import { t } from 'i18next';

type FavoriteButtonProps = ButtonProps & {
  readonly id: number;
  isFavoriteOrder: boolean;
};

export const FavoriteButton: FC<FavoriteButtonProps> = ({
  id,
  isFavoriteOrder,
  ...props
}: FavoriteButtonProps) => {
  const favoriteOrder = useFavoriteOrder();
  const [isFavorite, setIsFavorite] = useState<boolean>(isFavoriteOrder);

  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    favoriteOrder
      .mutateAsync({
        workOrderId: id,
      })
      .then(() => {
        notify(
          'success',
          !isFavoriteOrder
            ? 'Work order was successfully favorited and available in Favorited tab!'
            : 'Work order was successfully removed from favorited'
        );
      })
      .catch(() => {
        notify('error', t('tryAgainLater'));
      });
  };

  return (
    <ActionIcon
      variant={isFavoriteOrder ? 'light' : 'subtle'}
      aria-label="Favorite order"
      color={isFavoriteOrder ? '#228BE6' : ''}
      onClick={handleFavorite}
      radius="xl"
    >
      <StarOutlined />
    </ActionIcon>
  );
};
