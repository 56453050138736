import { type FC } from 'react';

import { useBasketDelete } from '@api/basket/useBasketDelete';
import { Button, Modal } from '@mantine/core';

type DeleteAttributeModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ConfirmDeleteModal: FC<DeleteAttributeModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const deleteBasketItem = useBasketDelete();

  const handleSubmit = () => {
    deleteBasketItem.mutateAsync({ materialId: id }).then(() => {
      onClose();
    });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Delete attribute`}
    >
      <div className="max-w-[410px] mx-auto text-center text-gray-600 font-semibold m-5">
        {`Are you sure you want to delete material?`}
      </div>

      <div className="grid gap-4 grid-cols-2">
        <Button
          loading={deleteBasketItem.isLoading}
          onClick={handleSubmit}
          variant="outline"
        >
          Delete
        </Button>
        <Button onClick={onClose} variant="outline">
          No
        </Button>
      </div>
    </Modal>
  );
};
