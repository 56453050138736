import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { boolean, number, object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IMaterialInfo } from '@/types/IMaterialInfo';

export type IStockMaterialInfoEditRequest = IMaterialInfo;

type IStockMaterialInfoEditResponse = AxiosResponse<void>;

export const stockMaterialInfoEditInitial: IMaterialInfo = {
  availabilityCheck: 'Z1',
  baseUnitOfMeasure: '',
  basicData: '',
  eanUpc: '',
  genItemCatGroup: 'NORM',
  grProcessingTime: '14',
  id: 0,
  individualCollective: '',
  inspectionSetupIndicator: false,
  lotSizeIndicator: '',
  manufacturer: '',
  manufacturingPartNumber: '',
  materialDescription: '',
  materialGroup: '',
  materialNumber: '',
  maximumLotSize: 0,
  maximumStockLevel: 0,
  minSafetyStock: '',
  minimumLotSize: null,
  movingAveragePricePeriodicUnitPrice: null,
  mrpController: '',
  mrpType: '',
  plannedDeliveryTime: '45',
  priceUnit: 0,
  purchaseOrderText: '',
  purchasingValueKey: '02',
  qmControlKey: 'Z001',
  qmProcActive: false,
  reorderPoint: null,
  roundingValue: '',
  safetyStock: null,
  sarMaterialId: 0,
  schedulingMarginKey: '000',
  specialProcurement: '',
  stockDetermineGroup: 'Z001',
  storageLocForExternalProcurement: '0101',
  validFrom: 'NORM',
  valuationClass: '',
  xplantMaterialStatus: '02',
};

export const stockMaterialInfoEditValidation: ObjectSchema<IStockMaterialInfoEditRequest> =
  object({
    availabilityCheck: string().notRequired(),
    baseUnitOfMeasure: string().required('This field is required!'),
    basicData: string().required('This field is required!'),
    eanUpc: string().notRequired(),
    genItemCatGroup: string().nullable(),
    grProcessingTime: string().required('This field is required!'),
    id: number().required('This field is required!'),
    individualCollective: string().required('This field is required!'),
    inspectionSetupIndicator: boolean().required('This field is required!'), //boolean
    lotSizeIndicator: string().notRequired(),
    manufacturer: string().notRequired(),
    manufacturingPartNumber: string().notRequired(),
    materialDescription: string(),
    materialGroup: string().required('This field is required!'),
    materialNumber: string().nullable().notRequired(),
    maximumLotSize: number().notRequired(),
    maximumStockLevel: number().when('mrpType', {
      is: (mrpType: string) => mrpType == 'Z1',
      otherwise: (s) => s.notRequired(),
      then: (s) => s.required(),
    }),
    minSafetyStock: string().notRequired().nullable(),
    minimumLotSize: number().notRequired(),
    movingAveragePricePeriodicUnitPrice: number().required(
      'This field is required!'
    ),
    mrpController: string().required('This field is required!'),
    mrpType: string().required('This field is required!'),
    plannedDeliveryTime: string().required('This field is required!'),
    priceUnit: number().required('This field is required!'),
    purchaseOrderText: string().notRequired(),
    purchasingValueKey: string().required('This field is required!'),
    qmControlKey: string().notRequired(),
    qmProcActive: boolean().required('This field is required!'), //boolean
    reorderPoint: number()
      .when('mrpType', {
        is: (mrpType: string) => mrpType == 'Z1',
        otherwise: (s) => s.notRequired(),
        then: (s) => s.required(),
      })
      .nullable(),
    roundingValue: string().notRequired(),
    safetyStock: number()
      .when('mrpType', {
        is: (mrpType: string) => mrpType == 'PD',
        otherwise: (s) => s.notRequired(),
        then: (s) => s.required(),
      })
      .nullable(),
    sarMaterialId: number().required('This field is required!'),
    schedulingMarginKey: string().notRequired(),
    specialProcurement: string().notRequired(),
    stockDetermineGroup: string().notRequired(),
    storageLocForExternalProcurement: string().notRequired(),
    validFrom: string().required('This field is required!'),
    valuationClass: string(),
    xplantMaterialStatus: string().nullable(),
  });

export function useStockMaterialInfoEdit() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockMaterialInfoEditResponse,
    AxiosError,
    IStockMaterialInfoEditRequest
  >((payload) => api.put('/materials/api/sar-materials-info', payload), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.stockMaterials]);
      queryClient.invalidateQueries([queryKeys.stockMaterial]);
    },
  });
}
