import { useMemo, type FC } from 'react';

import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { Modal, Skeleton, Table, Text } from '@mantine/core';
import { useBranchPlant } from '@api/orders/useBranchPlant';
import { t } from 'i18next';
import moment from 'moment';
import { useWorkOrderMaterial } from '@api/orders/useWorkOrderMaterial';
import { calculateSum } from '@utils/calculateSum';
import { isObject } from 'lodash';

type OrderMaterialDetailsModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const OrderMaterialDetailsModal: FC<OrderMaterialDetailsModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const plantDataQuery = useBranchPlant({ workOrderMaterialId: +id });
  const plantData = plantDataQuery.data?.data;

  const orderMaterialQuery = useWorkOrderMaterial({ id });
  const orderMaterial = orderMaterialQuery.data?.data;

  const branchPlant = useMemo(() => {
    return orderMaterial?.branchPlant
      ? Object.entries(orderMaterial.branchPlant).map(([key, value]) => {
          const branchSum = Object.values(value).reduce(
            (a, b) => Number(a) + Number(b),
            0
          );

          return {
            items: isObject(value)
              ? Object.entries(value).map(([k, v]) => ({
                  key: k,
                  value: v.toString(),
                }))
              : [],
            key,
            value: isObject(value) ? branchSum : value,
          };
        })
      : null;
  }, [orderMaterial]);

  const totalQuantity = branchPlant ? calculateSum(branchPlant, 'value') : 0;

  //FIXME: replace table with mantine-react-table
  const rows = plantData?.map((plant) => (
    <Table.Tr
      className="!border-b !border-b-1 border-b-[#DEE2E6]"
      key={plant.id}
    >
      <Table.Td>{moment(plant.date).format('DD/MM/YY') ?? '-'}</Table.Td>
      <Table.Td>{plant.mrpElement}</Table.Td>
      <Table.Td>{plant.mrpElementData}</Table.Td>
      <Table.Td>
        {moment(plant.reschedulingDate).format('DD/MM/YY') ?? '-'}
      </Table.Td>
      <Table.Td>{plant.exception}</Table.Td>
      <Table.Td>{plant.receiptReqmt}</Table.Td>
      <Table.Td>{plant.availableQty}</Table.Td>
      <Table.Td>{plant.issRecStorLoc}</Table.Td>
      <Table.Td>{plant.storageLocation}</Table.Td>
      <Table.Td>{plant.reqmtPrio}</Table.Td>
      <Table.Td>{plant.supplRecevingPlant}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Modal
      onClose={onClose}
      opened={isOpen}
      size={570}
      title={`Material #${orderMaterial?.materialNumber}`}
    >
      {plantDataQuery.isLoading ? (
        <div className="flex flex-col gap-8">
          <Skeleton className="w-full h-40" />
          <Skeleton className="w-full h-40" />
        </div>
      ) : !plantData ? (
        <NotFoundResult
          subTitle="There is no detailt about this material"
          title="No result"
        />
      ) : (
        <div className="grid gap-4">
          <div className="flex flex-col">
            {branchPlant ? (
              branchPlant.map((item, key) => (
                <div
                  className="grid grid-cols-2 border-[#ECECEC] border-b-[1px] py-2 px-5"
                  key={key}
                >
                  <Text size="sm">{item.key}</Text>
                  <Text size="sm">{item.value}</Text>
                  {item.items.length != 0 ? (
                    item.items.map((branch, index) => (
                      <div
                        className="py-2 px-6 col-span-2 grid grid-cols-2"
                        key={index}
                      >
                        <Text size="sm" className="mr-4">
                          {branch.key}
                        </Text>
                        <Text size="sm" className="">
                          {branch.value}
                        </Text>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <div>No details found</div>
            )}
          </div>
          <Text size="sm" fw={600} className="mt-6">
            Demand data
          </Text>
          <div className="overflow-x-scroll">
            <Table className="bg-[#F8F9FA]">
              <Table.Thead>
                <Table.Tr className="bg-[#F2F6FD] border-y text-xs border-y-[#DEE2E6]">
                  <Table.Th>{t('branchPlant.date')}</Table.Th>
                  <Table.Th>{t('branchPlant.mrpElement')}</Table.Th>
                  <Table.Th>{t('branchPlant.mrpElementData')}</Table.Th>
                  <Table.Th>{t('branchPlant.reschedulingDate')}</Table.Th>
                  <Table.Th>{t('branchPlant.exception')}</Table.Th>
                  <Table.Th>{t('branchPlant.receiptReqmt')}</Table.Th>
                  <Table.Th>{t('branchPlant.availableQty')}</Table.Th>
                  <Table.Th>{t('branchPlant.issRecStorLoc')}</Table.Th>
                  <Table.Th>{t('branchPlant.storageLocation')}</Table.Th>
                  <Table.Th>{t('branchPlant.reqmtPrio')}</Table.Th>
                  <Table.Th>{t('branchPlant.supplRecevingPlant')}</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          </div>
        </div>
      )}
    </Modal>
  );
};
