import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

export type IAddMaterialCertificatesRequest = {
  sarMaterialId: number;
  certificateIds: number[];
};

type IAddMaterialCertificatesResponse = AxiosResponse<void>;

export function useAddMaterialCertificates() {
  const queryClient = useQueryClient();

  return useMutation<
    IAddMaterialCertificatesResponse,
    AxiosError,
    IAddMaterialCertificatesRequest
  >(
    (params) =>
      api.post(
        '/materials/api/sar-materials/add/certificate',
        {},
        {
          params: {
            sarMaterialId: params.sarMaterialId,
            certificateIds: params.certificateIds,
          },
        }
      ),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.certificates]);
        queryClient.invalidateQueries([queryKeys.stockMaterialsPaged]);
        queryClient.invalidateQueries([queryKeys.stockMaterial]);
      },
    }
  );
}
