import { FC, useState } from 'react';

import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { CloseButton, Input, Title } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { useWorkOrderMaterials } from '@api/orders/useWorkOrderMaterials';
import { useParams } from 'react-router-dom';
import { orderMaterialsColumns } from '@modules/orders/consts/columns/orderMaterialsColumns';
import { orderMaterialsFilters } from '@modules/orders/consts/filters/orderMaterialsFilters';
import {
  IOrderMaterialPageActions,
  orderMaterialPageActions,
} from '@modules/orders/consts/pageActions/IOrderMaterialPageActions';
import { OrderMaterialDetailsModal } from '../organisms/OrderMaterialDetailsModal';

const WorkOrderDetailsPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { orderId } = useParams();

  const { filtered, onFilter, actioned, clearParams } = useUrlFilteredActioned<
    IPageAbleRequest,
    IOrderMaterialPageActions
  >(orderMaterialsFilters, orderMaterialPageActions);

  const orderMaterialsQuery = useWorkOrderMaterials({
    ...filtered,
    materialNumber: searchQuery,
    workOrderId: Number(orderId),
  });

  return (
    <div className="py-[10px]">
      <PageMeta
        breadcrumbs={[
          { link: '/orders', title: 'Work orders' },
          {
            link: `/orders/details/${orderId}`,
            title: `Work order #${orderId}`,
          },
        ]}
        selectedMenuKeys={['orders']}
        title="Work order"
      />
      <div className="flex justify-between mb-6 items-center">
        <Title order={3}>Work order #{orderId}</Title>
      </div>

      <div className="bg-white p-5 rounded-xl">
        <div className="flex justify-between items-end">
          <div>
            <Input
              className="mb-5 !mt-0 w-[280px]"
              mt="md"
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              placeholder="Search by material number"
              rightSection={
                <CloseButton
                  aria-label="Clear input"
                  onClick={() => setSearchQuery('')}
                  style={{ display: searchQuery ? undefined : 'none' }}
                />
              }
              rightSectionPointerEvents="all"
              value={searchQuery}
            />
          </div>
        </div>
        <div className="overflow-x-scroll">
          <QueryTable
            initialColumnVisibility={{
              itemCategory: false,
              storageLocation: false,
              branchPlant: false,
              purchasingOrganization: false,
              unloadingPlant: false,
              assignedBuyer: false,
              activity: false,
              updateAt: false,
            }}
            hideColumns
            columns={orderMaterialsColumns}
            empty={{
              description:
                'No materials were created by this user or no request matches your search or filtering criteria',
              title: 'No result',
            }}
            data={orderMaterialsQuery.data?.workOrderMaterials}
            query={orderMaterialsQuery}
            tableFilter={{ filtered, filters: orderMaterialsFilters, onFilter }}
          />
        </div>
      </div>

      <OrderMaterialDetailsModal
        id={actioned.actionId}
        isOpen={actioned.action === 'view'}
        onClose={clearParams}
      />
    </div>
  );
};

export default WorkOrderDetailsPage;
