import { FC } from 'react';

import { BadgeProps } from '@mantine/core';

type StatusProps = BadgeProps & {
  readonly id: number;
};

export const NumberTag: FC<StatusProps> = ({ id, ...props }: StatusProps) => {
  return (
    <span className="w-fit px-3 py-[4px] rounded-lg border-color-[#E5E7EB] border-[1px] text-[#6B7280] text-sm">
      №: {id}
    </span>
  );
};
