import { IEnumSarStatusKeys } from '@consts/enums/IEnumStatuses';

import { StatusEnumKeys } from './enums/statusEnum';
import { ICertificate } from './ICertificate';
import { IMaterialInfo } from './IMaterialInfo';
import { IMeasurementUnit } from './IMeasurementUnit';
import { IModifier } from './IModifier';
import { INoun } from './INoun';
import { IUserBase } from './IUser';

export type ISar = {
  dataChampionReviewer: string;
  id: number;
  materialControllerReviewer: string;
  objectPart: string;
  qaQcReviewer: string;
  sarName: string;
  status: StatusEnumKeys;
};

export type IHistory = {
  id: number;
  sarId: number;
  userId: number;
  sarEventName: 'SAR_CANCELED' | 'SAR_REJECTED' | 'SAR_APPROVE';
  eventText: string;
  changes: any;
  createdAt: string;
};

export type IComment = {
  comment: string;
  createdAt: string;
  createdBy: string;
  id: number;
  sarId: number;
  updatedAt?: string;
  updatedBy?: string;
};

export type IStockRequestAttribute = {
  id: number;
  isDefault: boolean;
  isUomRequired: boolean;
  key: string;
  modifierId: number;
  nounId: number;
  sarMaterialId: number;
  uom: IMeasurementUnit;
  value: string;
};

export type IStockRequestMaterial = {
  id: number;
  info: IMaterialInfo;
  modifier: IModifier;
  noun: INoun;
  sarAttributes: IStockRequestAttribute[];
  sarId: number;
  certificates: ICertificate[];
};

export type IStockRequest = {
  comments: IComment[];
  createdAt?: string;
  createdBy?: string;
  createdByUser?: {
    email?: string;
    id: number;
    name?: string;
    username?: string;
  };
  dataChampionReviewer: string | number;
  id: number;
  materialControllerReviewer?: string | number;
  dataChampionReviewerUser?: {
    email?: string;
    id: number;
    name?: string;
    username?: string;
  };
  materialControllerReviewerUser?: {
    email?: string;
    id: number;
    name?: string;
    username?: string;
  };
  materialNumbers?: string[];
  objectPart: string;
  qaQcReviewer?: string | number;
  qaQcReviewerUser?: {
    email?: string;
    id: number;
    name?: string;
    username?: string;
  };
  requisitioner?: number;
  sarName: string;
  status: IEnumSarStatusKeys;
  updatedAt?: string;
  updatedBy?: string;
  user?: IUserBase;
};

export const sarKeys: Record<keyof IStockRequest, string> = {
  comments: 'comments',
  createdAt: 'createdAt',
  createdBy: 'createdBy',
  createdByUser: 'createdByUser',
  dataChampionReviewerUser: 'dataChampionReviewerUser',
  dataChampionReviewer: 'dataChampionReviewer',
  id: 'id',
  materialControllerReviewer: 'materialControllerReviewer',
  materialControllerReviewerUser: 'materialControllerReviewerUser',
  materialNumbers: 'materialNumbers',
  objectPart: 'objectPart',
  qaQcReviewer: 'qaQcReviewer',
  qaQcReviewerUser: 'qaQcReviewerUser',
  requisitioner: 'requisitioner',
  sarName: 'sarName',
  status: 'status',
  updatedAt: 'updatedAt',
  updatedBy: 'updatedBy',
  user: 'user',
};
