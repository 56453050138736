import { FC, useState } from 'react';

import { useNouns } from '@api/nouns/useNouns';
import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { CloseButton, Input } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { nounsColumns } from './consts/columns/nounsColumns';
import { nounsFilters } from './consts/filters/nounsFilters';
import {
  INounsPageActions,
  nounsPageActions,
} from './consts/pageActions/INounsPageActions';

const NounsPage: FC = () => {
  const { filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    INounsPageActions
  >(nounsFilters, nounsPageActions);
  const [searchQuery, setSearchQuery] = useState('');

  const nounsQuery = useNouns({
    ...filtered,
    searchQuery,
  });

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/nouns', title: 'Nouns' }]}
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-nouns']}
        title="Nouns"
      />
      <div className="bg-white border-none p-5 rounded-xl">
        <Input
          className="mb-5 !mt-0 w-[280px]"
          mt="md"
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          placeholder="Clearable input"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchQuery('')}
              style={{ display: searchQuery ? undefined : 'none' }}
            />
          }
          value={searchQuery}

          // leftSection={<SearchOutlined size={16} />}
        />
        <QueryTable
          columns={nounsColumns}
          empty={{
            description: 'Please try again with another query.',
            title: 'No nouns match your search query.',
          }}
          data={nounsQuery.data}
          query={nounsQuery}
          tableFilter={{ filtered, filters: nounsFilters, onFilter }}
        />
      </div>
    </div>
  );
};

export default NounsPage;
