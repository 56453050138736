import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  CalendarOutlined,
  MinusOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useBasketQuantity } from '@api/basket/useBasketQuantity';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import {
  Accordion,
  ActionIcon,
  Input,
  NumberInput,
  Popover,
  Select,
  Text,
} from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IMaterial } from '@/types/IMaterial';

import { basketFilters } from '../consts/filters/basketFilters';
import {
  basketPageActions,
  IBasketPageActions,
} from '../consts/pageActions/IBasketPageActions';
import { NumberTag } from '@components/atoms/NumberTag';
import { IconTrash } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { DateInput } from '@mantine/dates';
import { isObject } from 'lodash';
import { calculateSum } from '@utils/calculateSum';

type BasketItemCardProps = {
  material: Pick<
    IMaterial,
    'id' | 'quantity' | 'stockNumber' | 'branchPlant' | 'description1'
  >;
  quantity: number;
  form: any;
  index: number;
  itemCategories: {
    label: string;
    value: string;
  }[];
  purchasingGroups: {
    label: string;
    value: string;
  }[];
  measurements: {
    label: string;
    value: string;
  }[];
  usersList: {
    label: string;
    value: string;
  }[];
  plantsWithLocation: {
    label: string;
    values: string[];
  }[];
};

const BasketItemCard: FC<BasketItemCardProps> = ({
  material,
  quantity = 0,
  form,
  index,
  itemCategories,
  purchasingGroups,
  measurements,
  usersList,
  plantsWithLocation,
}) => {
  const { t } = useTranslation();
  const [opened, { close, open }] = useDisclosure(false);
  const [materialQuantity, setMaterialQuantity] = useState<number | string>(
    quantity
  );
  const changeBasketQuantity = useBasketQuantity();

  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IBasketPageActions
  >(basketFilters, basketPageActions);

  const branchPlant = useMemo(() => {
    return material.branchPlant
      ? Object.entries(material.branchPlant).map(([key, value]) => {
          const branchSum = Object.values(value).reduce(
            (a, b) => Number(a) + Number(b),
            0
          );

          return {
            items: isObject(value)
              ? Object.entries(value).map(([k, v]) => ({
                  key: k,
                  value: v.toString(),
                }))
              : [],
            key,
            value: isObject(value) ? branchSum : value,
          };
        })
      : null;
  }, [material]);

  const totalQuantity = branchPlant ? calculateSum(branchPlant, 'value') : 0;

  return (
    <Accordion className="bg-white rounded-xl border border-[#E4E6ED] mb-5">
      <Accordion.Item
        className="border-none !mb-0 "
        value={material.stockNumber.toString()}
        key={material.stockNumber}
      >
        <div className="flex w-full flex-col p-8">
          <div className="flex items-center justify-between">
            <NumberTag id={material.stockNumber} />

            <Popover opened={opened} width={350} position="bottom" shadow="md">
              <Popover.Target>
                <Text
                  className="text-[#9DA7B2] underline cursor-pointer"
                  onMouseEnter={open}
                  size="sm"
                  onMouseLeave={close}
                >
                  Branch plant
                </Text>
              </Popover.Target>
              <Popover.Dropdown className="p-6 w-[350px] border-[397BFF]">
                <div className="grid gap-0">
                  {branchPlant ? (
                    branchPlant.map((item, key) => (
                      <div
                        className="grid grid-cols-2 border-b-2 p-3"
                        key={key}
                      >
                        <Text size="xs">{item.key}</Text>{' '}
                        <Text size="xs">{item.value}</Text>
                      </div>
                    ))
                  ) : (
                    <div>No details found</div>
                  )}
                </div>
                <div className="grid grid-cols-2 p-3 bg-[#F1F3F9]">
                  <Text size="xs" className="font-bold">
                    Total quantity
                  </Text>{' '}
                  <Text size="xs" className="font-bold">
                    {totalQuantity}
                  </Text>
                </div>
              </Popover.Dropdown>
            </Popover>

            <div className="flex items-center rounded-xl border border-[#E4E6ED] ">
              <ActionIcon
                onClick={() => {
                  changeBasketQuantity.mutateAsync({
                    materialId: material.id,
                    operation: 'MINUS',
                    quantity: materialQuantity as number,
                  });
                  setMaterialQuantity((prevState) => Number(prevState) - 1);
                }}
                disabled={materialQuantity === 1}
                variant="transparent"
                size="lg"
                aria-label="Gallery"
              >
                <MinusOutlined />
              </ActionIcon>
              <NumberInput
                className="w-[50px] basket-quant-input"
                variant="unstyled"
                decimalSeparator=","
                defaultValue={1}
                min={1}
                hideControls
                onChange={(value: number | string) =>
                  setMaterialQuantity(value)
                }
                value={materialQuantity}
                placeholder="Quantity"
                required
              />
              <ActionIcon
                onClick={() => {
                  changeBasketQuantity.mutateAsync({
                    materialId: material.id,
                    operation: 'PLUS',
                    quantity: 1,
                  });
                  setMaterialQuantity((prevState) => Number(prevState) + 1);
                }}
                variant="transparent"
                size="lg"
                aria-label="Gallery"
              >
                <PlusOutlined />
              </ActionIcon>
            </div>

            <div className="flex">
              <ActionIcon
                onClick={() => {
                  setPartialActioned({
                    action: 'delete',
                    actionId: material.id ?? '',
                  });
                }}
                variant="subtle"
                size="lg"
                aria-label="Gallery"
              >
                <IconTrash />
              </ActionIcon>
              <Accordion.Control className="w-min" />
            </div>
          </div>

          <div>
            <Text mt={8}>{material.description1}</Text>
          </div>
        </div>
        <Accordion.Panel className="p-8 border-t border-t-1">
          <div className="grid grid-cols-2 gap-6 gap-y-6">
            <div className="grid gap-y-6">
              <Select
                data={itemCategories.map((category) => ({
                  label: category.label + ': ' + category.value,
                  value: category.value,
                }))}
                required
                label={t('order.itemCategory')}
                {...form.getInputProps(`payload.${index}.itemCategory`)}
              />
              <div className="grid grid-cols-2 gap-6">
                <Select
                  data={plantsWithLocation.map((location) => ({
                    label: location.label,
                    value: location.label,
                  }))}
                  required
                  label={t('order.storageLocation')}
                  {...form.getInputProps(`payload.${index}.storageLocation`)}
                />
                <Select
                  disabled={
                    !form.getInputProps(`payload.${index}.storageLocation`)
                  }
                  data={plantsWithLocation
                    .find(
                      (location) =>
                        location.label ==
                        form.values.payload[index].storageLocation
                    )
                    ?.values.map((plant) => ({
                      label: plant,
                      value: plant,
                    }))}
                  required
                  label={t('order.plant')}
                  {...form.getInputProps(`payload.${index}.plant`)}
                />
              </div>
              <Input.Wrapper label={t('order.unloadingPlant')}>
                <Input
                  {...form.getInputProps(`payload.${index}.unloadingPlant`)}
                />
              </Input.Wrapper>
              <Input.Wrapper label={t('order.activity')} required>
                <Input {...form.getInputProps(`payload.${index}.activity`)} />
              </Input.Wrapper>
            </div>
            <div className="grid gap-y-6">
              <Select
                data={measurements.map((uom) => ({
                  label: uom.label,
                  value: uom.label,
                }))}
                required
                label={t('order.unitOfMeasurement')}
                {...form.getInputProps(`payload.${index}.unitOfMeasurement`)}
              />
              <Select
                data={usersList.map((user) => ({
                  label: user.label,
                  value: user.value,
                }))}
                required
                label="Requisitioner"
                {...form.getInputProps(`payload.${index}.createdBy`)}
              />
              <div className="grid grid-cols-2 gap-6">
                <Select
                  data={purchasingGroups.map((group) => ({
                    label: group.label,
                    value: group.label,
                  }))}
                  required
                  label={t('order.purchasingOrganization')}
                  {...form.getInputProps(
                    `payload.${index}.purchasingOrganization`
                  )}
                />
                <Select
                  data={purchasingGroups.map((group) => ({
                    label: group.label,
                    value: group.label,
                  }))}
                  required
                  label={t('order.purchasingGroup')}
                  {...form.getInputProps(`payload.${index}.purchasingGroup`)}
                />
              </div>
              <DateInput
                rightSection={<CalendarOutlined />}
                required
                label={t('order.requirementsDate')}
                {...form.getInputProps(`payload.${index}.requirementsDate`)}
              />
            </div>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default BasketItemCard;
