import { FC, useState } from 'react';

import { FileAddOutlined } from '@ant-design/icons';
import { useMeasurementUnits } from '@api/measurements/useMeasurementUnits';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, CloseButton, Input } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { ConfirmDeleteModal } from './components/ConfirmDeleteModal';
import { CreateMeasurementModal } from './components/CreateMeasurementModal';
import { EditMeasurementModal } from './components/EditMeasurementModal';
import { measurementsFilters } from './consts/filters/measurementsFilters';
import {
  IMeasurementsPageActions,
  measurementsPageActions,
} from './consts/pageActions/IMeasurementsPageActions';

const MeasurementUnitsPage: FC = () => {
  const [measurementId, setMeasurementId] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const { actioned, clearParams, filtered, onFilter, setPartialActioned } =
    useUrlFilteredActioned<IPageAbleRequest, IMeasurementsPageActions>(
      measurementsFilters,
      measurementsPageActions
    );

  const measurementsQuery = useMeasurementUnits({ ...filtered, searchQuery });

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/units-of-measurement', title: 'Units Of Measurement' },
        ]}
        openMenuKeys={['materials']}
        selectedMenuKeys={['materials-units-of-measurement']}
        title="Modifiers"
      />
      <div className="bg-white border-none p-5 rounded-xl">
        <div className="flex justify-between">
          <Input
            className="mb-5 !mt-0 w-[280px]"
            mt="md"
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            placeholder="Clearable input"
            rightSection={
              <CloseButton
                aria-label="Clear input"
                onClick={() => setSearchQuery('')}
                style={{ display: searchQuery ? undefined : 'none' }}
              />
            }
            value={searchQuery}
          />
          <Button
            disabled={!measurementsQuery.isFetched}
            leftSection={<FileAddOutlined className="w-4 h-4" />}
            onClick={() => {
              setPartialActioned({
                action: 'create',
              });
            }}
            variant="outline"
          >
            Add UoM
          </Button>
        </div>
        {/* FIXME: add query */}
        {/* <QueryTable
          actions={(row) => [
            {
              icon: <EditOutlined />,
              onClick: () => {
                setMeasurementId(row.id);
                setPartialActioned({
                  action: 'edit',
                  actionId: row.id ?? '',
                });
              },
              title: '',
            },
            {
              icon: <DeleteOutlined />,
              onClick: () => {
                setPartialActioned({
                  action: 'delete',
                  actionId: row.id ?? '',
                });
              },
              title: '',
            },
          ]}
          columns={measurementsColumns}
          empty={{
            description: 'Please try again with another query.',
            title: 'No units match your search query.',
          }}
          query={measurementsQuery}
          tableFilter={{ filtered, filters: measurementsFilters, onFilter }}
        /> */}
      </div>

      <ConfirmDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />

      <CreateMeasurementModal
        isOpen={actioned.action === 'create'}
        onClose={clearParams}
      />

      <EditMeasurementModal
        id={measurementId}
        isOpen={actioned.action === 'edit'}
        onClose={clearParams}
      />
    </div>
  );
};

export default MeasurementUnitsPage;
