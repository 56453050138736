import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';
import moment from 'moment';

import { SarStatusTag } from '@modules/sar/components/atoms/SarStatusTag';

import { IStockRequest } from '@/types/IStockRequest';
import { Link } from 'react-router-dom';
import { Tooltip } from '@mantine/core';

export const sarColumns: MRT_ColumnDef<IStockRequest>[] = [
  {
    accessorFn: (row) => (
      <Link to={`/sar/details/${row.id}`}>{row.id ?? '-'}</Link>
    ),
    accessorKey: 'id',
    header: t('id'),
    size: 5,
  },
  {
    accessorFn: (row) => (
      <Link to={`/sar/details/${row.id}`}>{row.sarName ?? '-'}</Link>
    ),
    accessorKey: 'sarName',
    header: t('sar.sarName'),
    size: 150,
  },
  {
    accessorFn: (row) =>
      row.materialNumbers && row.materialNumbers.length != 0 ? (
        <Tooltip
          withArrow
          multiline
          label={row.materialNumbers.map((number, key) => (
            <span key={key}>{number + '\n'}</span>
          ))}
        >
          <span className="whitespace-nowrap cursor-pointer">
            {row.materialNumbers[0]}
          </span>
        </Tooltip>
      ) : (
        <div>-</div>
      ),
    accessorKey: 'materialNumbers',
    header: t('sar.materialNumbers'),
    size: 150,
  },
  //materialNumbers
  {
    accessorFn: (row) => <SarStatusTag status={row.status} />,
    accessorKey: 'status',
    header: t('sar.status'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.createdByUser?.name ?? '-'}
      </span>
    ),
    accessorKey: 'requisitioner',
    header: t('sar.createdBy'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.dataChampionReviewerUser?.name ?? '-'}
      </span>
    ),
    accessorKey: 'dataChampionReviewer',
    header: t('sar.dataChampionReviewer'),
    size: 220,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.qaQcReviewerUser?.name ?? '-'}
      </span>
    ),
    accessorKey: 'qaQcReviewer',
    header: t('sar.qaQcReviewer'),
    size: 180,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {row.materialControllerReviewerUser?.name ?? '-'}
      </span>
    ),
    accessorKey: 'materialControllerReviewer',
    header: t('sar.materialControllerReviewer'),
    size: 240,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {moment(row.createdAt).format('DD/MM/YY') ?? '-'}
      </span>
    ),
    accessorKey: 'createdAt',
    header: t('sar.createdAt'),
    size: 150,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {moment(row.updatedAt).format('DD/MM/YY') ?? '-'}
      </span>
    ),
    accessorKey: 'updatedAt',
    header: t('sar.updatedAt'),
    size: 150,
  },
];
