import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IMaterial } from '@/types/IMaterial';

type IBasketMaterialsResponse = {
  content: {
    id: string;
    material: IMaterial;
    quantity: number;
  }[];
  id: string;
  userId: string;
};

export function useBasketMaterials() {
  return useQuery<IBasketMaterialsResponse, AxiosError>(
    [queryKeys.basket],
    () => api.get('/materials/api/basket').then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
