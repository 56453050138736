import { type FC, useState } from 'react';
import { Modal, Stepper } from '@mantine/core';
import { AddStockMaterialForm } from '../AddStockMaterialForm';
import { EditStockMaterialInfoForm } from '../EditStockMaterialInfoForm';

type IAddStockMaterialModal = {
  isOpen: boolean;
  onClose: () => void;
  nouns: any[];
};
export const AddStockMaterialModal: FC<IAddStockMaterialModal> = ({
  isOpen,
  onClose,
  nouns,
}) => {
  const [active, setActive] = useState(0);
  const nextStep = () =>
    setActive((current) => (current < 2 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={940}
      title="Materials"
    >
      <Stepper active={active} onStepClick={setActive} className="mb-6">
        <Stepper.Step description="Add attributes" label="Add attribute">
          <AddStockMaterialForm nouns={nouns} nextStep={nextStep} />
        </Stepper.Step>
        <Stepper.Step description="Add information" label="Add information">
          <EditStockMaterialInfoForm prevStep={prevStep} onClose={onClose} />
        </Stepper.Step>
      </Stepper>
    </Modal>
  );
};
