import { IPageActions } from '@/types/common/IPageActions';

enum IEnumOrderMaterialsPageActions {
  'view',
}

export type IOrderMaterialPageActions = {
  action: keyof typeof IEnumOrderMaterialsPageActions;
  actionId: string;
};

export const orderMaterialPageActions: IPageActions<IOrderMaterialPageActions> =
  {
    action: {
      allowed: (value) =>
        Object.keys(IEnumOrderMaterialsPageActions).includes(value),
      default: '',
    },
    actionId: { default: '' },
  };
