import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgUsers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="#53545C"
      d="M9.35 14.858c-3.964 0-7.35.612-7.35 3.06S5.364 21 9.35 21c3.963 0 7.349-.612 7.349-3.06 0-2.449-3.364-3.082-7.35-3.082M9.35 12.525c2.699 0 4.862-2.119 4.862-4.763C14.212 5.12 12.05 3 9.35 3 6.651 3 4.486 5.119 4.486 7.762c0 2.644 2.165 4.763 4.863 4.763M15.159 11.77c.182.03.369.048.56.052 1.898.048 3.601-1.148 4.072-2.95.697-2.675-1.35-5.077-3.957-5.077a4.16 4.16 0 0 0-.818.082M15.57 13.383c1.515-.029 3.145.176 3.747.32 1.276.244 2.115.74 2.462 1.466a2.107 2.107 0 0 1 0 1.878c-.531 1.123-2.245 1.485-2.912 1.578"
    />
  </svg>
);
const Memo = memo(SvgUsers);
export default Memo;
