import { FC, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Loader } from '@mantine/core';

import { PageMeta } from '@context/PageMetaContext';
import { UserContext } from '@context/UserContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';

import { useStockRequestById } from '@api/sar/useStockRequestById';
import { useStockMaterialsPaged } from '@api/sar-materials/useStockMaterialsPaged';
import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { sarMaterialsFilters } from '@modules/sar/consts/filters/sarMaterialsFilters';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';
import { sarMaterialsColumns } from '@modules/sar/consts/columns/sarMaterialsColumns';

import { QueryTable } from '@components/organisms/QueryTable';
import SarDetailsCard from '../molecules/SarDetailsCard';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import SarCommentsSection from '../molecules/SarCommentsSection';
import { RejectRequestModal } from '../organisms/modals/RejectRequestModal';
import { CancelRequestModal } from '../organisms/modals/CancelRequestModal';
import { EditStockRequestModal } from '../organisms/modals/EditStockRequestModal';
import { ApproveRequestModal } from '../organisms/modals/ApproveRequestModal';
import { useDuplicateStockRequest } from '@api/sar/useDuplicateStockRequest';
import { notify } from '@utils/notify';
import { t } from 'i18next';
import { AddCertificateModal } from '../organisms/modals/AddCertificateModal';
import { AddMaterialNumberModal } from '../organisms/modals/AddMaterialNumberModal';
import { useStockRequestCanMakeAction } from '@api/sar/useStockRequestCanMakeAction';
import { useStockRequestAction } from '@api/sar/useStockRequestAction';
import { ConfirmDeleteMaterialModal } from '../organisms/modals/ConfirmDeleteMaterialModal';
import { SarContext } from '@context/SarContext';

const StockRequestDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { sarId } = useParams();
  const { userMe } = useContext(UserContext);
  const { onChangeSarStatus } = useContext(SarContext);

  const duplicateSar = useDuplicateStockRequest();

  const { filtered, onFilter, setPartialActioned, clearParams, actioned } =
    useUrlFilteredActioned<IPageAbleRequest, ISARPageActions>(
      sarMaterialsFilters,
      sarPageActions
    );

  const stockRequestQuery = useStockRequestById({ sarId: Number(sarId) });

  const stockRequestMaterialsQuery = useStockMaterialsPaged({
    ...filtered,
    sarId: Number(sarId),
  });

  const stockRequest = stockRequestQuery.data;

  const isSarReady = useStockRequestCanMakeAction({ sarId: Number(sarId) });

  useEffect(() => {
    if (stockRequest) {
      onChangeSarStatus(stockRequest.status);
    }
  }, [stockRequest]);

  const handleDuplicate = () => {
    duplicateSar
      .mutateAsync({ sarId: Number(sarId) })
      .then((res) => {
        notify('success', 'Stock Request has been duplicated!');
        navigate(`/sar/details/${res.data}`);
      })
      .catch((error) => {
        notify('error', t('tryAgainLater'));
      });
  };

  const approveStockRequest = useStockRequestAction();

  const handleApproveRequest = () => {
    approveStockRequest
      .mutateAsync({
        id: Number(sarId),
        status: 'APPROVE',
        reason: '',
      })
      .then(() => {
        notify(
          'success',
          `The stock authorization request was successfully approved.`
        );
      });
  };

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/sar', title: 'Stock Authorization Requests' },
          {
            link: `/sar/details/${sarId}`,
            title: 'Stock Authorization Request Details',
          },
        ]}
        selectedMenuKeys={['sar']}
        title="Stock Authorization Request Details"
      />

      {stockRequestQuery.isLoading && stockRequestMaterialsQuery.isLoading ? (
        <Loader />
      ) : !(stockRequest && stockRequestMaterialsQuery) ? (
        <NotFoundResult
          subTitle=" Please try again later!"
          title="There is an error"
        />
      ) : (
        <>
          <div className="flex justify-between">
            <Button
              onClick={() => navigate('/sar')}
              variant="transparent"
              className="text-[##9CA3AF] mb-6"
              leftSection={<ArrowLeftOutlined />}
            >
              Back
            </Button>
            {userMe?.role == 'USER' && (
              <Button
                variant="outline"
                className="text-[##9CA3AF] mb-6"
                onClick={handleDuplicate}
              >
                New request from existing
              </Button>
            )}
            {((stockRequest.status == 'AWAITING_ENG_REVIEW' &&
              userMe?.role == 'DATA_CHAMPION_REVIEWER') ||
              (stockRequest.status == 'AWAITING_MDM' &&
                userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER') ||
              (stockRequest.status == 'AWAITING_QA_QC' &&
                userMe?.role == 'QA_QC_REVIEWER')) && (
              <div>
                <Button
                  variant="outline"
                  className="mb-6"
                  onClick={() =>
                    setPartialActioned({
                      action: 'cancel',
                      actionId: stockRequest.id.toString() ?? '',
                    })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="outline"
                  className="text-[##9CA3AF] mb-6"
                  onClick={() =>
                    setPartialActioned({
                      action: 'reject',
                      actionId: stockRequest.id.toString() ?? '',
                    })
                  }
                >
                  Reject
                </Button>
                <Button
                  disabled={
                    !isSarReady.data &&
                    userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER'
                  }
                  variant="filled"
                  className="text-[##9CA3AF] mb-6"
                  onClick={() =>
                    userMe?.role == 'QA_QC_REVIEWER' && isSarReady.data
                      ? handleApproveRequest()
                      : setPartialActioned({
                          action: 'approve',
                          actionId: stockRequest.id.toString() ?? '',
                        })
                  }
                >
                  {userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER'
                    ? 'Complete'
                    : 'Approve'}
                </Button>
              </div>
            )}
          </div>
          <SarDetailsCard sar={stockRequest} />

          <div className="overflow-x-scroll p-6 bg-white rounded-xl">
            <QueryTable
              columns={sarMaterialsColumns}
              empty={{
                description:
                  'No stock authorization requests were created by this user or no request matches your search or filtering criteria',
                title: 'No result',
              }}
              actions={(row) => [
                ...((userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
                  stockRequest.status == 'AWAITING_ENG_REVIEW') ||
                (userMe?.role == 'USER' && stockRequest.status == 'DRAFT') ||
                stockRequest.status == 'AWAITING_REQUISITIONER' ||
                (userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
                  stockRequest.status == 'AWAITING_MDM') ||
                (userMe?.role == 'QA_QC_REVIEWER' &&
                  stockRequest.status == 'AWAITING_QA_QC')
                  ? [
                      {
                        icon: <DeleteOutlined />,
                        onClick: () => {
                          setPartialActioned({
                            action: 'deleteMaterial',
                            actionId: row.id.toString() ?? '',
                          });
                        },
                        title: 'Delete',
                      },
                    ]
                  : []),
                ...(userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
                stockRequest.status == 'AWAITING_MDM'
                  ? [
                      {
                        icon: <EditOutlined />,
                        onClick: () => {
                          setPartialActioned({
                            action: row.info.materialNumber
                              ? 'editMaterialNumber'
                              : 'addMaterialNumber',
                            actionId: row.id.toString() ?? '',
                          });
                        },
                        title: row.info.materialNumber
                          ? 'Edit Material Number'
                          : 'Add Material Number',
                      },
                    ]
                  : []),
                ...((userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
                  stockRequest.status == 'AWAITING_ENG_REVIEW') ||
                (userMe?.role == 'USER' && stockRequest.status == 'DRAFT') ||
                stockRequest.status == 'AWAITING_REQUISITIONER' ||
                (userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
                  stockRequest.status == 'AWAITING_MDM') ||
                (userMe?.role == 'QA_QC_REVIEWER' &&
                  stockRequest.status == 'AWAITING_QA_QC')
                  ? [
                      {
                        icon: <EditOutlined />,
                        onClick: () => {
                          setPartialActioned({
                            action:
                              row.certificates && row.certificates.length != 0
                                ? 'editCertificate'
                                : 'addCertificate',
                            actionId: row.id.toString() ?? '',
                          });
                        },
                        title:
                          row.certificates && row.certificates.length != 0
                            ? 'Edit certificate'
                            : 'Add certificate',
                      },
                    ]
                  : []),
              ]}
              data={stockRequestMaterialsQuery.data}
              query={stockRequestMaterialsQuery}
              tableFilter={{ filtered, filters: sarMaterialsFilters, onFilter }}
            />
            {(userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
              stockRequest.status == 'AWAITING_ENG_REVIEW') ||
            (userMe?.role == 'USER' && stockRequest.status == 'DRAFT') ||
            (userMe?.role == 'USER' &&
              stockRequest.status == 'AWAITING_REQUISITIONER') ||
            (userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
              stockRequest.status == 'AWAITING_MDM') ||
            (userMe?.role == 'QA_QC_REVIEWER' &&
              stockRequest.status == 'AWAITING_QA_QC') ? (
              <Button
                className="my-6 addMaterial-btn w-full"
                disabled={!stockRequestMaterialsQuery.isFetched}
                leftSection={<PlusOutlined className="w-4 h-4" />}
                onClick={() => {
                  setPartialActioned({
                    action: 'addMaterial',
                  });
                }}
                variant="transparent"
              >
                Add Material
              </Button>
            ) : (
              <></>
            )}
          </div>

          <SarCommentsSection sar={stockRequest} />
        </>
      )}

      <ConfirmDeleteMaterialModal
        id={actioned.actionId}
        isOpen={actioned.action === 'deleteMaterial'}
        onClose={clearParams}
      />

      <RejectRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'reject'}
        onClose={clearParams}
      />

      <CancelRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'cancel'}
        onClose={clearParams}
      />

      <EditStockRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'edit'}
        onClose={clearParams}
      />

      <ApproveRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'approve'}
        onClose={clearParams}
      />

      <AddMaterialNumberModal
        id={actioned.actionId}
        isOpen={
          actioned.action === 'addMaterialNumber' ||
          actioned.action === 'editMaterialNumber'
        }
        onClose={clearParams}
      />

      <AddCertificateModal
        isOpen={
          actioned.action === 'addCertificate' ||
          actioned.action === 'editCertificate'
        }
        onClose={clearParams}
        id={actioned.actionId}
      />
    </div>
  );
};

export default StockRequestDetailsPage;
