import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import AuthRoute from '@routes/AuthRoute';

const AuthLayout: FC = () => {
  return (
    <AuthRoute auth={false}>
      <div className="grid h-full w-full bg-[#F9F9F9]">
        <div className="flex flex-col gap-8 overflow-auto p-8 md:gap-16 md:p-16 justify-center items-center">
          {/* <div className="flex w-full gap-6">
            <div className="flex flex-grow justify-end">
              <LanguageDropdown />
            </div>
          </div> */}
          <div className="w-[410px] px-[34px] py-[44px] rounded-xl flex flex-col items-center justify-center bg-white">
            <Outlet />
          </div>
        </div>
      </div>
    </AuthRoute>
  );
};

export default AuthLayout;
