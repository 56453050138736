import { FC, MemoExoticComponent, SVGProps } from 'react';

import { Title } from '@mantine/core';

export const NotFoundResult: FC<{
  Icon?: MemoExoticComponent<(props: SVGProps<SVGSVGElement>) => JSX.Element>;
  subTitle?: string;
  title?: string;
}> = ({
  subTitle = 'Попробуйте перезагрузить страницу',
  title = 'Страница не найдена',
}) => {
  return (
    <div className="flex gap-4 items-center flex-col justify-center bg-white h-fit py-8 px-2 text-center">
      <Title className="mt-4 text-base lg:text-2xl" order={2}>
        {title}
      </Title>
      <span className="text-gray-400 font-medium">{subTitle}</span>
    </div>
  );
};
