import { RoleEnumKeys } from './enums/rolesEnum';

export type IUser = {
  createdAt: string;
  defaultReviewer?: boolean;
  email: string;
  firstName: string;
  id: string;
  jobTitle: string;
  lastName: string;
  role: RoleEnumKeys;
  status: 'ACTIVE' | 'BLOCKED';
  updatedAt: string;
  username: string;
  name?: string;
  objectPart?: string;
};

export type IUserBase = {
  email: string;
  id: number;
  name: string;
  username: string;
};

export const userKeys: Record<keyof IUser, string> = {
  createdAt: 'createdAt',
  defaultReviewer: 'defaultReviewer',
  email: 'email',
  firstName: 'firstName',
  id: 'id',
  jobTitle: 'jobTitle',
  lastName: 'lastName',
  role: 'role',
  status: 'status',
  updatedAt: 'updatedAt',
  username: 'username',
  objectPart: 'objectPart',
  name: 'name',
};
