import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgWorkOrders = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 21 22"
    {...props}
  >
    <path
      stroke="#53545C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.514 20.5H6.166c-3.066 0-5.419-1.107-4.75-5.565l.777-6.041C2.605 6.67 4.024 5.818 5.27 5.818h10.179c1.263 0 2.599.916 3.075 3.076l.778 6.041c.568 3.954-1.72 5.565-4.787 5.565"
      clipRule="evenodd"
    />
    <path
      stroke="#53545C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.651 5.598a4.32 4.32 0 0 0-4.32-4.32v0a4.32 4.32 0 0 0-4.339 4.32h0M13.297 10.1h-.046M7.466 10.1H7.42"
    />
  </svg>
);
const Memo = memo(SvgWorkOrders);
export default Memo;
