import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IUser } from '@/types/IUser';

type IUsersResponse = IPageAbleResponse<IUser>;

export function useUsers({ ...params }: IPageAbleRequest) {
  return useQuery<IUsersResponse, AxiosError>(
    [queryKeys.users, params],
    () =>
      api.get('/users/api/admin/users/all', { params }).then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
