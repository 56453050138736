import { type FC } from 'react';

import { t } from 'i18next';

import {
  IMeasurementCreateRequest,
  measurementCreateInitial,
  measurementCreateValidation,
  useMeasurementCreate,
} from '@api/measurements/useMeasurementCreate';
import { Button, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';

type ICreateMeasurementModal = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateMeasurementModal: FC<ICreateMeasurementModal> = ({
  isOpen,
  onClose,
}) => {
  const measurementCreate = useMeasurementCreate();

  const form = useForm<IMeasurementCreateRequest>({
    initialValues: measurementCreateInitial,
    validate: yupResolver(measurementCreateValidation),
  });

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={570}
      title="Create unit of measurement"
    >
      <form
        onSubmit={form.onSubmit((values) => {
          measurementCreate
            .mutateAsync({
              ...values,
            })
            .then(() => {
              notify('success', 'Unit of measurement was successfully added!');
              form.reset();
              onClose();
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="grid gap-4 grid-cols-2 p-5">
          <TextInput
            label="UoM full name"
            name="fullName"
            required
            {...form.getInputProps('fullName')}
          />
          <TextInput
            label="UoM short name"
            name="shortName"
            required
            {...form.getInputProps('shortName')}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 px-5">
          <Button
            loading={measurementCreate.isLoading}
            type="submit"
            variant="outlined"
          >
            {t('create')}
          </Button>
          <Button onClick={handleClose} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
