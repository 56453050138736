import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IRoleEnum } from '@/types/enums/rolesEnum';
import { IUser } from '@/types/IUser';

type IUserRequest = { role: IRoleEnum };

type IUserResponse = AxiosResponse<IUser[]>;

export function useUserByRole(params: IUserRequest) {
  return useQuery<IUserResponse, AxiosError>(
    [queryKeys.user, params],
    () => api.get(`/users/api/user`, { params }),
    { enabled: !!params.role }
  );
}
