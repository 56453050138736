import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useModifiersByNounId } from '@api/modifiers/useModifiersByNounId';
import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { CloseButton, Input } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { modifiersColumns } from './consts/columns/modifiersColumns';
import { modifiersFilters } from './consts/filters/modifiersFilters';
import {
  IModifiersPageActions,
  modifierPageActions,
} from './consts/pageActions/IModifiersPageActions';

const ModifiersPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { filtered, onFilter } = useUrlFilteredActioned<
    IPageAbleRequest,
    IModifiersPageActions
  >(modifiersFilters, modifierPageActions);

  const { id = '' } = useParams<{ id: string }>();

  const modifiersQuery = useModifiersByNounId({ ...filtered, id, searchQuery });

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/nouns', title: 'Nouns' },
          { link: `/nouns/${id}/modifiers`, title: 'Modifiers' },
        ]}
        selectedMenuKeys={['nouns']}
        title="Modifiers"
      />
      <div className="bg-white border-none p-5 rounded-xl">
        <Input
          className="mb-5 !mt-0 w-[280px]"
          mt="md"
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          placeholder="Clearable input"
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchQuery('')}
              style={{ display: searchQuery ? undefined : 'none' }}
            />
          }
          value={searchQuery}
        />
        <QueryTable
          columns={modifiersColumns}
          empty={{
            description: 'Please try again with another query.',
            title: 'No modifier match your search query.',
          }}
          data={modifiersQuery.data}
          query={modifiersQuery}
          tableFilter={{ filtered, filters: modifiersFilters, onFilter }}
        />
      </div>
    </div>
  );
};

export default ModifiersPage;
