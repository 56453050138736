import { FunctionComponent, SVGProps } from 'react';

import { Folder, Orders, Sar, Users } from '@assets/icons';
export interface SidebarInterface {
  children?: SidebarInterface[];
  icon?: FunctionComponent<Omit<SVGProps<SVGSVGElement>, 'ref'>>;
  key: string;
  link: string;
  permissions: string[];
  title?: string;
}

export const sidebars = (): SidebarInterface[] => {
  return [
    {
      icon: Users,
      key: 'users',
      link: 'users',
      permissions: ['admin'],
      title: 'Users',
    },
    {
      children: [
        {
          // icon: ,
          key: 'all',
          link: 'materials',
          permissions: [
            'admin',
            'user',
            'data_champion_reviewer',
            'qa_qc_reviewer',
            'material_controller_reviewer',
          ],
          title: 'All Materials',
        },
        {
          // icon: ,
          key: 'nouns',
          link: 'nouns',
          permissions: [
            'admin',
            'user',
            'data_champion_reviewer',
            'qa_qc_reviewer',
            'material_controller_reviewer',
          ],
          title: 'Nouns',
        },
        {
          // icon: ,
          key: 'units-of-measurement',
          link: 'units-of-measurement',
          permissions: [
            'admin',
            'user',
            'data_champion_reviewer',
            'qa_qc_reviewer',
            'material_controller_reviewer',
          ],
          title: 'Units Of Measurement',
        },
      ],
      icon: Folder,
      key: 'materials',
      link: '',
      permissions: [
        'admin',
        'user',
        'data_champion_reviewer',
        'qa_qc_reviewer',
        'material_controller_reviewer',
      ],
      title: 'Materials',
    },
    {
      icon: Orders,
      key: 'orders',
      link: 'orders',
      permissions: ['admin', 'user'],
      title: 'Orders',
    },
    {
      icon: Sar,
      key: 'sar',
      link: 'sar',
      permissions: [
        'admin',
        'user',
        'data_champion_reviewer',
        'qa_qc_reviewer',
        'material_controller_reviewer',
      ],
      title: 'SAR Requests',
    },
  ];
};
