import { IPageActions } from '@/types/common/IPageActions';

enum IEnumMeasurementsPageActions {
  'edit',
  'delete',
  'create',
}

export type IMeasurementsPageActions = {
  action: keyof typeof IEnumMeasurementsPageActions;
  actionId: string;
};

export const measurementsPageActions: IPageActions<IMeasurementsPageActions> = {
  action: {
    allowed: (value) =>
      Object.keys(IEnumMeasurementsPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
