import { useTranslation } from 'react-i18next';
import { Tabs } from '@mantine/core';
import { IStockRequest } from '@/types/IStockRequest';
import CommentCard from './CommentCard';
import moment from 'moment';
import { useSarHistory } from '@api/sar/useSarHistory';
import HistoryChangeCard from './HistoryChangeCard';
import { NotFoundResult } from '@components/molecules/NotFoundResult';

export default function SarCommentsSection({ sar }: { sar: IStockRequest }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const useSarHistoryQuery = useSarHistory({ sarId: sar.id });
  const sarHistory = useSarHistoryQuery.data;

  const comment = {
    comment: 'Here is an example of a comment',
    createdAt: moment(new Date()).format('DD MMM YYYY HH:mm'),
    createdBy: 'Madina Kazybayeva',
    id: 5,
    sarId: 5,
    updatedAt: moment(new Date()).format('DD MMM YYYY HH:mm'),
    updatedBy: 'Madina Kazybayeva',
  };

  return (
    <div className="p-6 items-start bg-white rounded-xl border-b-0 my-6">
      <Tabs defaultValue="comments">
        <Tabs.List>
          <Tabs.Tab value="comments">Comments</Tabs.Tab>
          <Tabs.Tab value="history">History</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="comments">
          {sarHistory ? (
            sarHistory?.map((comment) => (
              <CommentCard comment={comment} key={comment.id} />
            ))
          ) : (
            <NotFoundResult
              title="Nothing found"
              subTitle="There is no comments yet"
            />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="history">
          {sarHistory ? (
            sarHistory?.map((change) => (
              <HistoryChangeCard key={change.id} change={change} />
            ))
          ) : (
            <NotFoundResult
              title="Nothing found"
              subTitle="There is no changes yet"
            />
          )}
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}
