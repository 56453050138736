import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

export type IFavoriteOrderRequest = {
  workOrderId: number;
};

type IIFavoriteOrderResponse = AxiosResponse<void>;

export function useFavoriteOrder() {
  const queryClient = useQueryClient();

  return useMutation<
    IIFavoriteOrderResponse,
    AxiosError,
    IFavoriteOrderRequest
  >(
    (params) =>
      api.put(`/work-orders/api/orders/favorite/${params.workOrderId}`),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () => queryClient.invalidateQueries([queryKeys.orders]),
    }
  );
}
