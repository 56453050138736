import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

type INounsResponse = {
  [key: number]: string;
};

export function useFilterNouns() {
  return useQuery<INounsResponse, AxiosError>(
    [queryKeys.filterNouns],
    () => api.get('/materials/api/filters/nouns').then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
