import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IHistory } from '@/types/IStockRequest';

type IStockRequestResponse = IHistory[];

export function useSarHistory({ sarId }: { sarId: number }) {
  return useQuery<IStockRequestResponse, AxiosError>(
    [queryKeys.stockRequestsHistory, sarId],
    () => api.get(`/materials/history/${sarId}`).then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
