import { useEffect, useMemo, useRef, useState } from 'react';

import { AxiosError } from 'axios/index';
import { t } from 'i18next';
import {
  MantineReactTable,
  MRT_Column,
  type MRT_ColumnDef,
  MRT_PaginationState,
  MRT_TableOptions,
  MRT_ShowHideColumnsButton,
  MRT_VisibilityState,
} from 'mantine-react-table';

import { mantineCommonTableProps } from '@consts/mantineCommonTableProps';
import { Menu, Tooltip } from '@mantine/core';
import { UseQueryResult } from '@tanstack/react-query';

import { TableFilter } from '@/types/common/IFilters';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IQueryTableAction } from '@/types/common/IQueryTableAction';
import { ITableContent } from '@/types/common/ITableContent';

import { QueryTableHeader } from './QueryTableHeader';
import { IconDots } from '@tabler/icons-react';
import { ISarPageAbleResponse } from '@/types/ISarPageAbleResponse';
import { IOrderMaterialsPageAbleResponse } from '@api/orders/useWorkOrderMaterials';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IOrdersPageAbleResponse } from '@api/orders/useWorkOrdersPaged';

type ICoachTableType<T extends ITableContent> = {
  actions?: (T: T) => IQueryTableAction[];
  columns: MRT_ColumnDef<T>[];
  empty?: { description?: string; title: string };
  query: UseQueryResult<
    | IPageAbleResponse<T>
    | ISarPageAbleResponse<T>
    | IOrdersPageAbleResponse<T>
    | IOrderMaterialsPageAbleResponse<T>,
    AxiosError
  >;
  data: any;
  tableFilter: TableFilter;
  hideColumns?: boolean;
  initialColumnVisibility?: {
    [key: string]: boolean;
  };
};

export const QueryTable = <T extends ITableContent>({
  actions,
  hideColumns = false,
  columns,
  empty,
  query: { isFetched, isFetching, isLoading },
  data,
  tableFilter: { filtered, filters, onFilter },
  initialColumnVisibility = {},
}: ICoachTableType<T>) => {
  const isFirstRender = useRef(true);
  const pageAble = data;
  const [total, setTotal] = useState(pageAble?.totalElements || 1);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });

  const filteredPage = useMemo(
    () =>
      typeof filtered.page === 'string'
        ? parseInt(filtered.page)
        : filtered.page ?? 0,
    [filtered.page]
  );

  useEffect(() => {
    if (pagination) {
      onFilter({
        page: pagination.pageIndex || 0,
        size: pagination.pageSize || 20,
      });
    }
  }, [pagination.pageIndex]);

  const columnsMantine: MRT_ColumnDef<T>[] = useMemo(() => {
    return columns.map((col) => {
      return {
        ...col,
        Header: ({ column }: { column: MRT_Column<T> }) => (
          <QueryTableHeader
            column={column}
            tableFilterProps={{ filtered, filters, onFilter }}
          />
        ),
        accessorFn:
          isLoading || (isFetching && !data?.content.length) || !isFetched
            ? undefined
            : col.accessorFn,
      };
    });
  }, [
    columns,
    filters,
    filtered,
    isLoading,
    isFetching,
    isFetched,
    data?.content,
  ]);

  useEffect(() => {
    if (!isLoading) {
      setTotal(pageAble?.totalElements || 1);
      if (filteredPage + 1 > (pageAble?.totalPages || 1)) {
        onFilter({ page: pageAble?.totalPages || 0 });
      }
    }
  }, [data]);

  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {}
  );

  useEffect(() => {
    const savedColumnVisibility = localStorage.getItem(
      'mrt_columnVisibility_table'
    );
    if (savedColumnVisibility) {
      setColumnVisibility(JSON.parse(savedColumnVisibility));
    }
    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) return;
    if (Object.keys(columnVisibility).length !== 0) {
      localStorage.setItem(
        'mrt_columnVisibility_table',
        JSON.stringify(columnVisibility)
      );
    }
  }, [columnVisibility]);

  return (
    <MantineReactTable
      {...(mantineCommonTableProps as Partial<MRT_TableOptions<T>>)}
      columns={columnsMantine}
      enableHiding
      enableDensityToggle
      data={
        !isFetched ? ([{}, {}, {}, {}, {}, {}] as T[]) : data?.content || []
      }
      enableRowActions={!!actions?.length}
      enableSorting={false}
      initialState={{
        showColumnFilters: true,
        density: 'xs',
      }}
      localization={{ actions: t('actions') }}
      mantinePaginationProps={{
        classNames: {
          control:
            'data-[active=true]:bg-[#1F4A62] border-[#DEE2E6] data-[active=true]:border-[#1F4A62] data-[active=true]:text-white',
          root: 'pt-4 !justify-end',
        },
        disabled: isFetching,
        showRowsPerPage: false,
      }}
      mantineSearchTextInputProps={{
        placeholder: 'Search Employees',
      }}
      mantineTableBodyRowProps={({ row }) => ({
        onClick: (event) => {
          console.info(row);
        },
        sx: {
          cursor: 'pointer',
        },
      })}
      mantineTableContainerProps={{ className: 'overflow-visible' }}
      manualPagination={true}
      renderEmptyRowsFallback={() => (
        <div className="flex items-center flex-col justify-center py-8 gap-4">
          <div className="text-center flex flex-col gap-2">
            <span className="text-lg text-primary-900 font-semibold">
              {empty?.title}
            </span>
            <span className="text-sm text-gray-500 font-medium">
              {empty?.description}
            </span>
          </div>
        </div>
      )}
      onPaginationChange={setPagination}
      renderBottomToolbarCustomActions={() => (
        <span className="font-normal text-sm text-[#8E8E93]">
          Showing {pageAble?.numberOfElements} of {pageAble?.totalElements}
        </span>
      )}
      renderRowActions={({ row }) => {
        return (
          actions?.(row.original).length !== 0 && (
            <Menu variant="actions" withinPortal>
              <Menu.Target>
                <div>
                  <IconDots className="text-[#6C757D] cursor-pointer text-3xl hover:bg-primary-101 rounded-full w-4 h-4" />
                </div>
              </Menu.Target>
              <Menu.Dropdown className="overflow-visible">
                {actions?.(row.original).map(
                  ({ icon, onClick, title }, index) => (
                    <Menu.Item leftSection={icon} key={index} onClick={onClick}>
                      {title}
                    </Menu.Item>
                  )
                )}
              </Menu.Dropdown>
            </Menu>
          )
        );
      }}
      renderToolbarInternalActions={({ table }) => {
        return hideColumns ? (
          <div className="flex justify-end items-center w-full flex-row">
            <MRT_ShowHideColumnsButton table={table} title="Show columns" />
            <Tooltip label="Press Show columns to add more columns to the Material orders table">
              <InfoCircleOutlined className="text-[#228BE6]" />
            </Tooltip>
          </div>
        ) : (
          <></>
        );
      }}
      rowCount={total}
      state={{
        isLoading: !isFetched,
        pagination: { pageIndex: filteredPage, pageSize: filtered.size || 10 },
        showProgressBars: isFetching && !isLoading,
        showSkeletons: !isFetched,
        columnVisibility: columnVisibility,
      }}
      onColumnVisibilityChange={setColumnVisibility}
    />
  );
};
