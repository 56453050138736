import { useTranslation } from 'react-i18next';
import { IHistory } from '@/types/IStockRequest';
import { Avatar, Text } from '@mantine/core';
import moment from 'moment';

export default function CommentCard({ comment }: { comment: IHistory }) {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <div className="border-b border-b-[#DEE2E6] p-6 w-full flex">
      <Avatar size="sm" />
      <div className="flex flex-col ml-[10px]">
        <div className="flex gap-[10px] items-center mb-3">
          <Text size="sm">{comment.userId}</Text>
          <span className="text-[#AEAEB2] text-xs">
            {moment(comment.createdAt).format('DD MMM YYYY HH:mm')}
          </span>
        </div>
        {comment.sarEventName == 'SAR_CANCELED' && (
          <span className="text-[#AEAEB2] font-bold text-sm">
            Cancellation reason:
          </span>
        )}
        {comment.sarEventName == 'SAR_REJECTED' && (
          <span>Rejection reason:</span>
        )}
        <Text size="sm">{comment.eventText}</Text>
      </div>
    </div>
  );
}
