import { type FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { t } from 'i18next';

import {
  attributeCreateInitial,
  attributeCreateValidation,
  IAttributeCreateRequest,
  useAttributeCreate,
} from '@api/attributes/useAttributeCreate';
import { Button, Checkbox, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';

type ICreateAttributeModal = {
  isOpen: boolean;
  onClose: () => void;
};

export const CreateAttributeModal: FC<ICreateAttributeModal> = ({
  isOpen,
  onClose,
}) => {
  const attributeCreate = useAttributeCreate();
  const { modifierId = '', nounId = '' } = useParams<{
    modifierId: string;
    nounId: string;
  }>();

  const form = useForm<IAttributeCreateRequest>({
    initialValues: attributeCreateInitial,
    validate: yupResolver(attributeCreateValidation),
  });

  useEffect(() => {
    if (!isOpen) {
      form.reset();
    }
  }, []);

  const handleClose = () => {
    onClose();
    form.reset();
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={570}
      title="Create an attribute"
    >
      <form
        onSubmit={form.onSubmit((values) => {
          attributeCreate
            .mutateAsync({
              ...values,
              modifierId: Number(modifierId),
              nounId: Number(nounId),
            })
            .then(() => {
              notify('success', 'Attribute was successfully added ');
              form.reset();
              onClose();
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="grid gap-4 grid-cols-2 p-5 items-end">
          <TextInput
            label="Attribute"
            name="value"
            required
            {...form.getInputProps('key')}
          />

          <Checkbox
            label="Is Units of Measurement required? "
            name="unitOfMeasurement"
            {...form.getInputProps('unitOfMeasurement')}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 px-5">
          <Button
            loading={attributeCreate.isLoading}
            type="submit"
            variant="outline"
          >
            {t('create')}
          </Button>
          <Button onClick={handleClose} variant="outline">
            {t('cancel')}
          </Button>
        </div>
      </form>
    </Modal>
  );
};
