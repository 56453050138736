import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IMaterialInfo } from '@/types/IMaterialInfo';

type IStockMaterialInfoRequest = { id: number };

export type IStockMaterialInfoResponse = IMaterialInfo;

export function useStockMaterialInfo(params: IStockMaterialInfoRequest) {
  return useQuery<IStockMaterialInfoResponse, AxiosError>(
    [queryKeys.stockMaterialInfo, params],
    () =>
      api
        .get(`/materials/api/sar-materials-info/sar-material/${params.id}`)
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess) && !!params.id,
    }
  );
}
