import { notifications } from '@mantine/notifications';
import { IconCheck, IconExclamationMark, IconX } from '@tabler/icons-react';

export function notify(
  type: 'success' | 'info' | 'error' | 'warning',
  title?: string,
  message?: string,
  autoClose = 3500
) {
  notifications.show({
    autoClose,
    classNames: {
      closeButton: 'hover:bg-transparent',
      ...(type == 'success'
        ? {
            description: 'text-green-700 font-medium',
            icon: 'bg-[#219653]',
            root: 'bg-[#E9FFF6] p-4',
            title: 'text-[#219653] text-base',
          }
        : type == 'error'
          ? {
              description: 'text-red-700 font-medium',
              icon: 'bg-red-600',
              root: 'bg-red-200 p-4',
              title: 'text-red-600 text-base',
            }
          : type == 'warning'
            ? {
                description: 'text-yellow-700 font-medium',
                icon: 'bg-yellow-600',
                root: 'bg-yellow-100 p-4',
                title: 'text-yellow-600 text-base',
              }
            : {
                description: 'text-primary-700',
                icon: 'bg-primary-600',
                root: 'bg-primary-200 p-4',
                title: 'text-primary-600 text-base',
              }),
    },
    icon:
      type == 'success' ? (
        <IconCheck />
      ) : type == 'error' ? (
        <IconX />
      ) : type == 'warning' ? (
        <IconExclamationMark />
      ) : (
        <IconExclamationMark />
      ),
    message,
    radius: 'md',
    title,
  });
}
