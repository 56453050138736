import { DateFilter } from '@components/molecules/filters/static/DateFilter';

import { paginationBaseFilters } from '@consts/paginationBaseFilters';

import { IFilters } from '@/types/common/IFilters';
import { ordersKeys } from '@/types/IWorkOrder';

export const ordersFilters: IFilters = {
  ...paginationBaseFilters,
  [ordersKeys.workOrderNumber]: {
    sort: true,
  },
  [ordersKeys.createdAt]: {
    input: <DateFilter />,
    sort: true,
  },
};
