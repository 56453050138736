import { useContext, useState, type FC } from 'react';

import { Button, Modal, Select, Textarea } from '@mantine/core';
import {
  IStockRequestActionRequest,
  stockRequestActionInitial,
  stockRequestActionValidation,
  useStockRequestAction,
} from '@api/sar/useStockRequestAction';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';
import { UserContext } from '@context/UserContext';
import {
  IEnumSarActionRoles,
  IEnumSarActionRolesKeys,
  IEnumSarQaQcActionRoles,
} from '@consts/enums/IEnumSarActionRoles';

type DeleteAttributeModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const RejectRequestModal: FC<DeleteAttributeModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const rejectStockRequest = useStockRequestAction();
  const { userMe } = useContext(UserContext);

  const [rejectionRole, setRejectionRole] = useState<string | null>();

  const form = useForm<IStockRequestActionRequest>({
    initialValues: {
      ...stockRequestActionInitial,
    },
    validate: yupResolver(stockRequestActionValidation),
  });

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Are you sure you want to reject this stock authorization request?`}
    >
      <form
        onSubmit={form.onSubmit(() => {
          rejectStockRequest
            .mutateAsync({
              id: +id,
              status: 'REJECT',
              reason: form.values.reason,
              returnToUser: rejectionRole as IEnumSarActionRolesKeys,
            })
            .then(() => {
              notify(
                'success',
                'The stock authorization request was successfully rejected.'
              );
              onClose();
            });
        })}
      >
        <Textarea
          required
          autosize
          label="Rejection reason"
          minRows={3}
          name="reason"
          w={600}
          {...form.getInputProps('reason')}
        />

        {userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' && (
          <Select
            comboboxProps={{ withinPortal: false }}
            className="mt-0"
            data={Object.keys(IEnumSarActionRoles).map((role) => ({
              value: role,
              label: role,
            }))}
            required
            label="Please select the step where this request should be returned to"
            onChange={setRejectionRole}
            placeholder="Select one"
            value={rejectionRole}
          />
        )}

        {userMe?.role == 'QA_QC_REVIEWER' && (
          <Select
            comboboxProps={{ withinPortal: false }}
            className="mt-0"
            data={Object.keys(IEnumSarQaQcActionRoles).map((role) => ({
              value: role,
              label: role,
            }))}
            label="Please select the step where this request should be returned to"
            required
            onChange={setRejectionRole}
            placeholder="Select one"
            value={rejectionRole}
          />
        )}

        <div className="flex justify-center gap-4 mt-8">
          <Button onClick={onClose} variant="outline" w={200}>
            No
          </Button>
          <Button
            disabled={
              (userMe?.role == 'QA_QC_REVIEWER' ||
                userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER') &&
              !rejectionRole
            }
            loading={rejectStockRequest.isLoading}
            type="submit"
            w={200}
          >
            Yes
          </Button>
        </div>
      </form>
    </Modal>
  );
};
