import { type FC, useMemo, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useDictionaryById } from '@api/dictionaries/useDictionaryById';
import { useStockMaterialInfo } from '@api/sar-materials/useStockMaterialInfo';
import {
  IStockMaterialInfoEditRequest,
  stockMaterialInfoEditInitial,
  stockMaterialInfoEditValidation,
  useStockMaterialInfoEdit,
} from '@api/sar-materials/useStockMaterialInfoEdit';
import {
  Button,
  Checkbox,
  Loader,
  NumberInput,
  Select,
  Tabs,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';
import { SarContext } from '@context/SarContext';

type DeleteAttributeModalProps = {
  prevStep?: () => void;
  onClose?: () => void;
};

export const EditStockMaterialInfoForm: FC<DeleteAttributeModalProps> = ({
  prevStep,
  onClose,
}) => {
  const { sarMaterialId } = useContext(SarContext);
  const { t } = useTranslation();
  const materialInfoQuery = useStockMaterialInfo({
    id: sarMaterialId,
  });
  const materialInfo = materialInfoQuery.data;

  const materialGroupsQuery = useDictionaryById({ id: 40 });
  const materialGroups = useMemo(() => {
    if (materialGroupsQuery.isFetched) {
      const mappings = materialGroupsQuery?.data?.data.mappings ?? [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        values: v as string[],
      }));
    }
    return [];
  }, [materialGroupsQuery]);

  const unitsOfMeasureQuery = useDictionaryById({ id: 41 });
  const unitsOfMeasure = useMemo(() => {
    if (materialGroupsQuery.isFetched) {
      const mappings = unitsOfMeasureQuery?.data?.data.mappings ?? [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        values: v as string[],
      }));
    }
    return [];
  }, [unitsOfMeasureQuery]);

  const mrpTypeQuery = useDictionaryById({ id: 42 });
  const mrpType = useMemo(() => {
    if (mrpTypeQuery.isFetched) {
      const mappings = mrpTypeQuery?.data?.data.mappings ?? [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        values: v as string[],
      }));
    }
    return [];
  }, [mrpTypeQuery]);

  const mrpControllerQuery = useDictionaryById({ id: 43 });
  const mrpController = useMemo(() => {
    if (mrpControllerQuery.isFetched) {
      const mappings = mrpControllerQuery?.data?.data.mappings ?? [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        values: v as string[],
      }));
    }
    return [];
  }, [mrpControllerQuery]);

  const editStockMaterialInfo = useStockMaterialInfoEdit();
  const form = useForm<IStockMaterialInfoEditRequest>({
    initialValues: {
      ...stockMaterialInfoEditInitial,
    },
    transformValues: (values) => ({
      ...values,
      id: materialInfo?.id ?? 0,
      inspectionSetupIndicator: Boolean(values.inspectionSetupIndicator),
      materialDescription: materialInfo?.materialDescription ?? '',
      qmProcActive: Boolean(values.qmProcActive),
      sarMaterialId: materialInfo?.sarMaterialId ?? 0,
      valuationClass: '',
    }),
    validate: yupResolver(stockMaterialInfoEditValidation),
  });

  useEffect(() => {
    if (materialInfo) {
      const {
        baseUnitOfMeasure,
        basicData,
        grProcessingTime,
        id,
        individualCollective,
        inspectionSetupIndicator,
        materialGroup,
        movingAveragePricePeriodicUnitPrice,
        mrpController,
        mrpType,
        plannedDeliveryTime,
        priceUnit,
        purchasingValueKey,
        qmProcActive,
        sarMaterialId,
        validFrom,
      } = materialInfo;
      form.setValues({
        baseUnitOfMeasure: baseUnitOfMeasure || '',
        basicData: basicData || '',
        grProcessingTime: grProcessingTime || '',
        id: Number(id),
        individualCollective: individualCollective || '',
        inspectionSetupIndicator: inspectionSetupIndicator || false,
        materialGroup: materialGroup || '',
        movingAveragePricePeriodicUnitPrice: Number(
          movingAveragePricePeriodicUnitPrice
        ),
        mrpController: mrpController || '',
        mrpType: mrpType || '',
        plannedDeliveryTime: plannedDeliveryTime || '',
        priceUnit: Number(priceUnit),
        purchasingValueKey: purchasingValueKey || '02',
        qmProcActive: qmProcActive || false,
        sarMaterialId: Number(sarMaterialId),
        validFrom: validFrom || 'NORM',
      });
    }
  }, [materialInfo]);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        editStockMaterialInfo
          .mutateAsync({
            ...values,
          })
          .then(() => {
            notify('success', 'Material information was successfully updated!');
            onClose?.();
          })
          .catch(() => {
            notify('error', t('tryAgainLater'));
          });
      })}
    >
      {materialInfo ? (
        <div className="lg:items-center">
          <Tabs defaultValue="general">
            <Tabs.List className="mb-5">
              <Tabs.Tab value="general">{t('materialInfo.general')}</Tabs.Tab>
              <Tabs.Tab value="purchasing">
                {t('materialInfo.purchasing')}
              </Tabs.Tab>
              <Tabs.Tab value="qualityManagement">
                {t('materialInfo.qualityManagement')}
              </Tabs.Tab>
              <Tabs.Tab value="accounting">
                {t('materialInfo.accounting')}
              </Tabs.Tab>
              <Tabs.Tab value="additional">
                {t('materialInfo.additional')}
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="general">
              <div>
                <div className="grid grid-cols-4 gap-4 my-3">
                  <TextInput
                    disabled
                    label={t('materialInfo.materialNumber')}
                    name="materialNumber"
                    {...form.getInputProps('materialNumber')}
                  />
                  <Select
                    className="mt-0"
                    comboboxProps={{ withinPortal: false }}
                    data={
                      unitsOfMeasure?.map((unit) => ({
                        label: unit.label,
                        value: unit.label,
                      })) ?? []
                    }
                    disabled={materialGroupsQuery.isLoading}
                    label={t('materialInfo.baseUnitOfMeasure')}
                    nothingFoundMessage="Nothing found..."
                    searchable
                    withAsterisk
                    {...form.getInputProps('baseUnitOfMeasure')}
                  />
                  <Select
                    className="mt-0"
                    comboboxProps={{ withinPortal: false }}
                    data={
                      materialGroups?.map((group) => ({
                        label: group.label,
                        value: group.label,
                      })) ?? []
                    }
                    disabled={materialGroupsQuery.isLoading}
                    label={t('materialInfo.materialGroup')}
                    nothingFoundMessage="Nothing found..."
                    searchable
                    withAsterisk
                    {...form.getInputProps('materialGroup')}
                  />
                  <TextInput
                    label={t('materialInfo.eanUpc')}
                    name="eanUpc"
                    {...form.getInputProps('eanUpc')}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4 my-3">
                  <TextInput
                    defaultValue="NORM"
                    disabled
                    label={t('materialInfo.genItemCatGroup')}
                    name="genItemCatGroup"
                    {...form.getInputProps('genItemCatGroup')}
                  />
                  <TextInput
                    defaultValue="02"
                    disabled
                    label={t('materialInfo.xplantMaterialStatus')}
                    name="xplantMaterialStatus"
                    {...form.getInputProps('xplantMaterialStatus')}
                  />
                </div>
                <div className="grid grid-cols-2 my-3">
                  <Textarea
                    autosize
                    label={t('materialInfo.basicData')}
                    minRows={2}
                    name="basicData"
                    w={600}
                    withAsterisk
                    {...form.getInputProps('basicData')}
                  />
                </div>
                <div className="flex flex-col my-3">
                  <Text>{t('materialInfo.materialDescription')}:</Text>
                  <Text>{materialInfo.materialDescription}</Text>
                </div>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="purchasing">
              <div>
                <div className="grid grid-cols-2 gap-4 my-3">
                  <TextInput
                    label={t('materialInfo.manufacturer')}
                    name="manufacturer"
                    {...form.getInputProps('manufacturer')}
                  />
                  <TextInput
                    label={t('materialInfo.manufacturingPartNumber')}
                    name="manufacturingPartNumber"
                    {...form.getInputProps('manufacturingPartNumber')}
                  />
                </div>
                <div className="grid grid-cols-2 gap-4 my-3">
                  <TextInput
                    defaultValue="02"
                    disabled
                    label={t('materialInfo.purchasingValueKey')}
                    name="purchasingValueKey"
                    {...form.getInputProps('purchasingValueKey')}
                  />
                  <TextInput
                    defaultValue="NORM"
                    disabled
                    label={t('materialInfo.validFrom')}
                    name="validFrom"
                    {...form.getInputProps('validFrom')}
                  />
                </div>
                <Textarea
                  autosize
                  label={t('materialInfo.purchaseOrderText')}
                  minRows={2}
                  name="purchaseOrderText"
                  w={600}
                  {...form.getInputProps('purchaseOrderText')}
                />
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="qualityManagement">
              <div>
                <div className="flex flex-col my-3">
                  <Checkbox
                    className="mb-4"
                    label={t('materialInfo.inspectionSetupIndicator')}
                    name="inspectionSetupIndicator"
                    {...form.getInputProps('inspectionSetupIndicator')}
                  />
                  <Checkbox
                    label={t('materialInfo.qmProcActive')}
                    name="qmProcActive"
                    {...form.getInputProps('qmProcActive')}
                    className="mb-4"
                  />
                  <TextInput
                    defaultValue="Z001"
                    disabled
                    label={t('materialInfo.qmControlKey')}
                    name="qmControlKey"
                    w={400}
                    {...form.getInputProps('qmControlKey')}
                  />
                </div>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="accounting">
              <div>
                <div className="grid grid-cols-2 my-3">
                  <Text>{t('materialInfo.valuationClass')}:</Text>
                  <Text>{materialInfo.valuationClass ?? '-'}</Text>
                </div>
                <div className="grid grid-cols-2 gap-4 my-3">
                  <NumberInput
                    allowNegative={false}
                    label={t('materialInfo.priceUnit')}
                    name="priceUnit"
                    withAsterisk
                    {...form.getInputProps('priceUnit')}
                  />
                  <NumberInput
                    allowNegative={false}
                    label={t(
                      'materialInfo.movingAveragePricePeriodicUnitPrice'
                    )}
                    name="movingAveragePricePeriodicUnitPrice"
                    withAsterisk
                    {...form.getInputProps(
                      'movingAveragePricePeriodicUnitPrice'
                    )}
                  />
                </div>
              </div>
            </Tabs.Panel>
            <Tabs.Panel value="additional">
              <div>
                <div className="grid grid-cols-2 my-3">
                  {/* FIXME: SELECT */}
                  <Text>{t('materialInfo.baseUnitOfMeasure')}:</Text>
                  <Text>{materialInfo.baseUnitOfMeasure ?? '-'}</Text>
                </div>
                {/* FIXME: SELECT */}
                <div className="grid grid-cols-2 my-3">
                  <Text>{t('materialInfo.materialGroup')}:</Text>
                  <Text>{materialInfo.materialGroup ?? '-'}</Text>
                </div>
                <div className="grid grid-cols-3 gap-4 my-3">
                  <Select
                    className="mt-0"
                    comboboxProps={{ withinPortal: false }}
                    data={
                      mrpType?.map((type) => ({
                        label: type.label,
                        value: type.label,
                      })) ?? []
                    }
                    disabled={materialGroupsQuery.isLoading}
                    label={t('materialInfo.mrpType')}
                    nothingFoundMessage="Nothing found..."
                    searchable
                    withAsterisk
                    {...form.getInputProps('mrpType')}
                  />
                  <Select
                    className="mt-0"
                    comboboxProps={{ withinPortal: false }}
                    data={
                      mrpController?.map((controller) => ({
                        label: controller.label,
                        value: controller.label,
                      })) ?? []
                    }
                    disabled={materialGroupsQuery.isLoading}
                    label={t('materialInfo.mrpController')}
                    nothingFoundMessage="Nothing found..."
                    searchable
                    withAsterisk
                    {...form.getInputProps('mrpController')}
                  />
                  <Select
                    className="mt-0"
                    comboboxProps={{ withinPortal: false }}
                    data={
                      [
                        { label: 'Individual', value: '1' },
                        { label: 'Collective', value: '2' },
                      ]?.map((type) => ({
                        label: type.label,
                        value: type.value,
                      })) ?? []
                    }
                    defaultValue="1"
                    disabled={materialGroupsQuery.isLoading}
                    label={t('materialInfo.individualCollective')}
                    nothingFoundMessage="Nothing found..."
                    searchable
                    withAsterisk
                    {...form.getInputProps('individualCollective')}
                  />
                </div>
                <div className="grid grid-cols-4 gap-4 my-3">
                  <TextInput
                    label={t('materialInfo.minimumLotSize')}
                    name="minimumLotSize"
                    {...form.getInputProps('minimumLotSize')}
                  />
                  <TextInput
                    label={t('materialInfo.maximumLotSize')}
                    name="maximumLotSize"
                    {...form.getInputProps('maximumLotSize')}
                  />
                  <TextInput
                    label={t('materialInfo.roundingValue')}
                    name="roundingValue"
                    {...form.getInputProps('roundingValue')}
                  />
                  <TextInput
                    defaultValue="Z1"
                    disabled
                    label={t('materialInfo.availabilityCheck')}
                    name="availabilityCheck"
                    {...form.getInputProps('availabilityCheck')}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4 my-3">
                  <TextInput
                    defaultValue="0101"
                    disabled
                    label={t('materialInfo.storageLocForExternalProcurement')}
                    name="storageLocForExternalProcurement"
                    {...form.getInputProps('storageLocForExternalProcurement')}
                  />
                  <TextInput
                    defaultValue="000"
                    disabled
                    label={t('materialInfo.schedulingMarginKey')}
                    name="schedulingMarginKey"
                    {...form.getInputProps('schedulingMarginKey')}
                  />
                  <TextInput
                    defaultValue="Z001"
                    disabled
                    label={t('materialInfo.stockDetermineGroup')}
                    name="stockDetermineGroup"
                    {...form.getInputProps('stockDetermineGroup')}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4 my-3">
                  <TextInput
                    label={t('materialInfo.minSafetyStock')}
                    name="minSafetyStock"
                    {...form.getInputProps('minSafetyStock')}
                  />
                  <NumberInput
                    allowNegative={false}
                    label={t('materialInfo.safetyStock')}
                    name="safetyStock"
                    {...form.getInputProps('safetyStock')}
                  />
                  <TextInput
                    label={t('materialInfo.specialProcurement')}
                    name="specialProcurement"
                    {...form.getInputProps('specialProcurement')}
                  />
                </div>
                <div className="grid grid-cols-3 gap-4 my-3">
                  <TextInput
                    defaultValue="14"
                    label={t('materialInfo.grProcessingTime')}
                    name="grProcessingTime"
                    withAsterisk
                    {...form.getInputProps('grProcessingTime')}
                  />
                  <TextInput
                    defaultValue="45"
                    label={t('materialInfo.plannedDeliveryTime')}
                    name="plannedDeliveryTime"
                    withAsterisk
                    {...form.getInputProps('plannedDeliveryTime')}
                  />
                  <NumberInput
                    allowNegative={false}
                    label={t('materialInfo.reorderPoint')}
                    name="reorderPoint"
                    {...form.getInputProps('reorderPoint')}
                  />
                </div>
                <div>
                  <NumberInput
                    allowNegative={false}
                    label={t('materialInfo.maximumStockLevel')}
                    name="maximumStockLevel"
                    {...form.getInputProps('maximumStockLevel')}
                  />
                </div>
              </div>
            </Tabs.Panel>
          </Tabs>
        </div>
      ) : (
        <Loader />
      )}

      <div className="flex flex-row justify-end mt-8">
        <Button className="mr-3" onClick={onClose} variant="outline">
          Close
        </Button>
        <Button loading={editStockMaterialInfo.isLoading} type="submit">
          {t('material.saveMaterial')}
        </Button>
      </div>
    </form>
  );
};
