import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';
import { object, ObjectSchema, string, number } from 'yup';

export type ISarMaterialNumberRequest = {
  sarMaterialId: number;
  materialNumber: string;
};

type ISarMaterialNumberResponse = AxiosResponse<void>;

export const sarMaterialNumberInitial: ISarMaterialNumberRequest = {
  sarMaterialId: 0,
  materialNumber: '',
};

export const sarMaterialNumberValidation: ObjectSchema<ISarMaterialNumberRequest> =
  object({
    sarMaterialId: number().required('This field is required!'),
    materialNumber: string().required('This field is required!'),
  });

export function useSarMaterialNumber() {
  const queryClient = useQueryClient();

  return useMutation<
    ISarMaterialNumberResponse,
    AxiosError,
    ISarMaterialNumberRequest
  >(
    (params) =>
      api.post(
        `/materials/api/sar-materials-info/material-number`,
        {},
        {
          params: {
            sarMaterialId: params.sarMaterialId,
            materialNumber: params.materialNumber,
          },
        }
      ),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.stockMaterialsPaged]);
      },
    }
  );
}
