import { Route, Routes } from 'react-router-dom';

import UsersPage from './components/pages/UsersPage';

export const UsersRouter = () => {
  return (
    <Routes>
      <Route element={<UsersPage />} index />
    </Routes>
  );
};
