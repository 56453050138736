import { Route, Routes } from 'react-router-dom';

import AuthLayout from '@layouts/AuthLayout';
import Layout from '@layouts/Layout';
import { BasketRouter } from '@modules/basket/BasketRouter';
import LoginPage from '@modules/components/pages/LoginPage';
import { MaterialsRouter } from '@modules/materials/MaterialsRouter';
import { MeasurementUnitsRouter } from '@modules/measurements/NounsRouter';
import { NounsRouter } from '@modules/nouns/NounsRouter';
import { SARRouter } from '@modules/sar/SARRouter';
import { UsersRouter } from '@modules/users/UsersRouter';
import IndexRoute from '@routes/IndexRoute';
import { OrdersRouter } from '@modules/orders/OrdersRouter';

const App = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route element={<LoginPage />} path="login" />
        {/* <Route element={<ChangePasswordPage />} path="/reset-password" /> */}
      </Route>

      <Route element={<Layout />} path="*">
        <Route element={<IndexRoute />} index />
        <Route element={<MaterialsRouter />} path="materials/*" />
        <Route element={<UsersRouter />} path="users/*" />
        <Route element={<BasketRouter />} path="basket/*" />
        <Route element={<NounsRouter />} path="nouns/*" />
        <Route element={<SARRouter />} path="sar/*" />
        <Route element={<OrdersRouter />} path="orders/*" />
        <Route
          element={<MeasurementUnitsRouter />}
          path="units-of-measurement/*"
        />
      </Route>
    </Routes>
  );
};

export default App;
