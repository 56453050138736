import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'qs';

import { storageKeys } from '@consts/storageKeys';
import { notify } from '@utils/notify';

export const cancelTokenSource = axios.CancelToken.source();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  cancelToken: cancelTokenSource.token,
  headers: {},
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'repeat' }),
});

// insert auth token into headers
axiosInstance.interceptors.request.use(async (reqConfig) => {
  const authToken = localStorage.getItem(storageKeys.tokenAccess);
  if (authToken && reqConfig.headers) {
    reqConfig.headers['Authorization'] = `Bearer ${authToken}`;
  }

  return reqConfig;
});

// catch 401 and refresh the token
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const responseUrl: string = error.request.responseURL;

    if (error && error.response) {
      if (error.response.status >= 500) {
        notify('error', 'Серверная ошибка');
      } else if (error.response.status === 401) {
        let refreshed = false;

        const originalRequest = error.config as AxiosRequestConfig & {
          _retry?: boolean;
        };
        const refreshToken: string | null = localStorage.getItem(
          storageKeys.tokenRefresh
        );

        const removeLogin = () => {
          localStorage.removeItem(storageKeys.tokenAccess);
          localStorage.removeItem(storageKeys.tokenRefresh);
        };

        await axios
          .post<{ accessToken: string; refreshToken: string }>(
            `/users/api/auth/refresh-token`,
            {
              refreshToken,
            }
          )
          .then((res) => {
            localStorage.setItem(
              storageKeys.tokenRefresh,
              res.data.refreshToken
            );
            localStorage.setItem(storageKeys.tokenAccess, res.data.accessToken);
            refreshed = true;
          })
          .catch(() => {
            removeLogin();
          });

        if (refreshed) {
          originalRequest._retry = true;
          return axios(originalRequest);
        }
      }

      return Promise.reject(error);
    }
  }
);

export const api = axiosInstance;
