import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type IMeasurementDeleteRequest = { unitId: string };

type IMeasurementDeleteResponse = AxiosResponse<void>;

export function useMeasurementDelete() {
  const queryClient = useQueryClient();

  return useMutation<
    IMeasurementDeleteResponse,
    AxiosError,
    IMeasurementDeleteRequest
  >(
    ({ unitId }) =>
      api.delete(`/materials/api/admin/units-of-measurement/${unitId}`),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () =>
        queryClient.invalidateQueries([queryKeys.unitsOfMeasurements]),
    }
  );
}
