import { paginationBaseFilters } from '@consts/paginationBaseFilters';

import { IFilters } from '@/types/common/IFilters';
import { nounKeys } from '@/types/INoun';

export const nounsFilters: IFilters = {
  ...paginationBaseFilters,
  [nounKeys.noun]: {
    sort: true,
  },
};
