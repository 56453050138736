import { cloneElement, FC, isValidElement, useState } from 'react';

import { QueryTableSortIcon } from '@components/organisms/QueryTableSortIcon';
import { TableSortEnum } from '@consts/tableSortEnum';
import { Popover } from '@mantine/core';
import { checkTextForCharacterIndex } from '@utils/checkTextForCharacterIndex';

import { Filter, IFiltersPropsDefault } from '@/types/common/IFilters';

type IFilterDropdown = {
  dataIndex: string;
  filter: Filter;
  filtered: IFiltersPropsDefault;
  onFilter: (key: object) => void;
  placement?:
    | 'bottom'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight'
    | 'top'
    | 'topLeft'
    | 'topCenter'
    | 'topRight';
  title?: JSX.Element | string | object;
};

export const FilterDropdown: FC<IFilterDropdown> = ({
  dataIndex,
  filter,
  filtered,
  onFilter,
  title,
}) => {
  const [opened, setOpened] = useState(false);
  const isActive =
    !!filtered[dataIndex] ||
    !!filter.queries?.find(
      (query) => query.key && filtered[query.key]?.toString().length
    );

  const filteredSortValue = String(filtered['sort']);

  const orderedBy = checkTextForCharacterIndex(filteredSortValue ?? '')
    ? TableSortEnum.DESC
    : TableSortEnum.ASC;
  const sortedBy = checkTextForCharacterIndex(filteredSortValue ?? '')
    ? filteredSortValue?.slice(1, filteredSortValue?.length)
    : filteredSortValue;

  return (
    <Popover
      onChange={setOpened}
      opened={opened}
      position="bottom"
      shadow="md"
      withArrow
      withinPortal
    >
      <Popover.Target>
        <div
          className="flex flex-nowrap justify-between cursor-pointer w-full text-gray-500 flex-col"
          onClick={() => setOpened((o) => !o)}
        >
          <div className="flex">
            <span
              className={isActive ? 'text-primary flex' : 'flex items-center'}
            >
              {filter.title || isValidElement(title)
                ? (title as JSX.Element)
                : title?.toString()}
            </span>
            {filter.sort && (
              <QueryTableSortIcon
                dataIndex={dataIndex}
                onFilter={onFilter}
                orderedBy={orderedBy}
                sortOrderReversed={filter.sortOrderReversed}
                sortedBy={sortedBy}
              />
            )}
          </div>
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        {filter.input ? (
          cloneElement(filter.input, {
            filterRender: {
              dataIndex: dataIndex,
              filtered: filtered,
              onFilter: (key) => {
                onFilter({
                  ...key,
                  page: 0,
                });
                setOpened(false);
              },
              queries: filter.queries,
            },
          })
        ) : (
          <></>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};
