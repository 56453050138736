import { FC, useRef, useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, MultiSelect } from '@mantine/core';

import { FilterRender } from '@/types/common/IFilters';

interface SelectMultipleInterface {
  filterRender?: FilterRender;
  loading: boolean;
  options: any[];
}

export const SelectMultipleFilter: FC<SelectMultipleInterface> = ({
  filterRender,
  loading,
  options,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [value, setValue] = useState<string[]>(filtered[key]);
  const selectRef = useRef(null);

  const onSelectSubmit = (clear = false) =>
    onFilter({ [key]: clear ? undefined : value });

  return (
    <>
      {/* <div className="p-2 space-y-3 bg-white shadow-filter max-w-tiny"> */}
      <MultiSelect
        autoFocus
        className="w-full max-w-xs"
        clearable
        comboboxProps={{ withinPortal: false }}
        data={options}
        disabled={loading}
        nothingFoundMessage={<div>nothing found</div>}
        onChange={(val) => {
          setValue(val);
          // onFilter({ [key]: value });
          // (selectRef as React.RefObject<HTMLInputElement>).current?.blur();
        }}
        ref={selectRef}
        value={value}
      />
      <div className="grid gap-1 grid-cols-2 mt-2">
        <Button
          disabled={!value || value == filtered[key]}
          leftSection={<SearchOutlined />}
          onClick={() => onSelectSubmit()}
          size="small"
          variant="primary"
        >
          Search
        </Button>
        <Button
          className="w-full"
          onClick={() => onSelectSubmit(true)}
          size="small"
        >
          Clear
        </Button>
      </div>
      {/* </div> */}
    </>
  );
};
