import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import { enTranslations } from '@consts/i18n/en';
import { ruTranslations } from '@consts/i18n/ru';
import { storageKeys } from '@consts/storageKeys';

import { IEnumLocalization } from '@/types/common/ILocalization';

const resources = {
  en: enTranslations,
  ru: ruTranslations,
};

let storageLanguage = localStorage.getItem(storageKeys.language) || '';

if (!Object.keys(IEnumLocalization).includes(storageLanguage)) {
  storageLanguage = 'ru';
}

i18n.use(initReactI18next).init({
  debug: false,
  defaultNS: 'translation',
  fallbackLng: 'ru',
  interpolation: { escapeValue: false },
  keySeparator: '.',
  lng: storageLanguage,
  ns: ['translation'],
  react: { useSuspense: false },
  resources,
  returnNull: true,
});

export default i18n;
