export function consoleErrorConfig() {
  const consoleError = console.error;
  const ignoredErrors = [];
  const ignoredArgs = ['showRowsPerPage', 'findDOMNode'];

  console.error = function filterErrors(msg, ...args) {
    if (
      typeof msg !== 'string' ||
      (!ignoredErrors.some((error) => msg?.includes(error)) &&
        !args.find((arg) => ignoredArgs.includes(arg)))
    ) {
      consoleError(msg, ...args);
    }
  };

  window.addEventListener('error', (e) => {
    if (
      e.message ===
      'ResizeObserver loop completed with undelivered notifications.'
    ) {
      document
        .getElementById('webpack-dev-server-client-overlay-div')
        ?.setAttribute('style', 'display: none');
      document
        .getElementById('webpack-dev-server-client-overlay')
        ?.setAttribute('style', 'display: none');
    }
  });
}
