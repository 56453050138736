import { type FC, useContext, useMemo, useState } from 'react';

import { t } from 'i18next';

import { useDictionaries } from '@api/dictionaries/useDictionaries';
import {
  IStockRequestCreateRequest,
  stockRequestCreateInitial,
  stockRequestCreateValidation,
  useStockRequestCreate,
} from '@api/sar/useStockRequestCreate';
import { SarContext } from '@context/SarContext';
import { Button, Select, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { notify } from '@utils/notify';

import { StatusEnum } from '@/types/enums/statusEnum';
import { useDataChampionReviewers } from '@api/users/useDataChampionReviewers';

export const CreateStockRequestForm: FC<{
  materialControllerReviewer: string;
  materialControllerReviewerId: number;
  qaQcReviewer: string;
  qaQcReviewerId: number;
  userId: string;
}> = ({
  materialControllerReviewer,
  materialControllerReviewerId,
  qaQcReviewer,
  qaQcReviewerId,
  userId,
}) => {
  const requestCreate = useStockRequestCreate();
  const [searchQuery, setSearchQuery] = useState('');
  const { onChangeSarId } = useContext(SarContext);

  //FIXME: check searchQuery
  const objectPartsQuery = useDictionaries({
    // searchQuery,
  });
  const objectParts = useMemo(() => {
    if (objectPartsQuery.isFetched) {
      const mappings = objectPartsQuery?.data?.[0].mappings ?? [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        values: v as string[],
      }));
    }
    return [];
  }, [objectPartsQuery]);

  const form = useForm<IStockRequestCreateRequest>({
    initialValues: {
      ...stockRequestCreateInitial,
      createdAt: new Date().toISOString(),
      createdBy: userId,
      materialControllerReviewer: materialControllerReviewerId,
      qaQcReviewer: qaQcReviewerId,
    },
    validate: yupResolver(stockRequestCreateValidation),
    validateInputOnChange: true,
  });

  const dataChampionReviewersQuery = useDataChampionReviewers({
    objectPart: form.values.objectPart,
  });
  const dataChampionReviewers = dataChampionReviewersQuery.data?.data;

  return (
    <>
      <form
        onSubmit={form.onSubmit(() => {
          requestCreate
            .mutateAsync({
              ...form.values,
              status: StatusEnum.DRAFT,
              dataChampionReviewer: +form.values.dataChampionReviewer || 0,
            })
            .then((res) => {
              notify('success', 'SAR request was successfully saved as draft!');
              onChangeSarId(res.data);
            })
            .catch(() => {
              notify('error', t('tryAgainLater'));
            });
        })}
      >
        <div className="bg-white rounded-xl p-6">
          <div className="grid gap-4 grid-cols-9">
            <TextInput
              className="col-span-2"
              label={t('sar.sarName')}
              name="sarName"
              {...form.getInputProps('sarName')}
            />
            <Select
              className="col-span-1"
              data={
                objectParts?.map((object) => ({
                  label: object.label,
                  value: object.label,
                })) ?? []
              }
              disabled={objectPartsQuery.isLoading}
              label={t('sar.objectPart')}
              name="objectPart"
              placeholder="Select item"
              searchable
              {...form.getInputProps('objectPart')}
              onSearchChange={setSearchQuery}
              searchValue={searchQuery}
            />
            <Select
              className="col-span-2"
              placeholder="Select item"
              data={dataChampionReviewers?.map((dataChampion) => ({
                value: String(dataChampion.id),
                label: dataChampion.name + ' ' + dataChampion.lastName,
              }))}
              disabled={!form.values.objectPart}
              label={t('sar.dataChampionReviewer')}
              name="dataChampionReviewer"
              searchable
              {...form.getInputProps('dataChampionReviewer')}
            />
            <TextInput
              className="col-span-2"
              disabled
              label={t('sar.materialControllerReviewer')}
              name="materialControllerReviewer"
              required
              value={materialControllerReviewer}
            />
            <TextInput
              className="col-span-2"
              disabled
              label={t('sar.qaQcReviewer')}
              name="qaQcReviewer"
              required
              value={qaQcReviewer}
            />
          </div>
        </div>

        <div className="flex flex-row justify-end">
          <Button type="submit" variant="outlined" className="mt-4">
            {t('saveDraft')}
          </Button>
        </div>
      </form>
    </>
  );
};
