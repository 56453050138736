import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgOrders = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 21 22"
    {...props}
  >
    <path
      stroke="#53545C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.514 20.5H6.166c-3.066 0-5.419-1.108-4.75-5.565l.777-6.041c.412-2.225 1.831-3.076 3.076-3.076h10.178c1.264 0 2.6.915 3.076 3.076l.778 6.04c.567 3.955-1.72 5.566-4.787 5.566"
      clipRule="evenodd"
    />
    <path
      stroke="#53545C"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M14.651 5.598a4.32 4.32 0 0 0-4.32-4.32v0a4.32 4.32 0 0 0-4.34 4.32h0M13.296 10.102h-.045M7.466 10.102H7.42"
    />
  </svg>
);
const Memo = memo(SvgOrders);
export default Memo;
