import { FC } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { useUsers } from '@api/users/useUsers';
import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { usersColumns } from '@modules/users/consts/columns/usersColumns';
import { usersFilters } from '@modules/users/consts/filters/usersFilters';
import {
  IUsersPageActions,
  usersPageActions,
} from '@modules/users/consts/pageActions/IUsersPageActions';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { UserDetailsModal } from '../organisms/UserDetailsModal';

const UsersPage: FC = () => {
  const { actioned, clearParams, filtered, onFilter, setPartialActioned } =
    useUrlFilteredActioned<IPageAbleRequest, IUsersPageActions>(
      usersFilters,
      usersPageActions
    );
  const usersQuery = useUsers({
    ...filtered,
  });

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/users', title: 'Users List' }]}
        selectedMenuKeys={['users']}
        title="Users List"
      />

      <div className="overflow-x-scroll">
        <QueryTable
          data={usersQuery.data}
          actions={(row) => [
            {
              icon: <EditOutlined />,
              onClick: () => {
                setPartialActioned({
                  action: 'edit',
                  actionId: row.id ?? '',
                });
              },
              title: 'Edit user',
            },
          ]}
          columns={usersColumns}
          empty={{
            description: 'Здесь пока еще нет пользователей',
            title: 'Результаты не найдены',
          }}
          query={usersQuery}
          tableFilter={{ filtered, filters: usersFilters, onFilter }}
        />
      </div>

      <UserDetailsModal
        id={actioned.actionId}
        isOpen={actioned.action === 'edit'}
        onClose={clearParams}
      />
    </div>
  );
};

export default UsersPage;
