import { IFilters } from '@/types/common/IFilters';

export const paginationBaseFilters: IFilters = {
  page: { queries: [{ default: 0 }] },
  size: {
    queries: [
      {
        allowed: (value) => ['10', '20', '50', '100'].includes(value),
        default: 10,
      },
    ],
  },
  sort: { queries: [{ default: '' }] },
};
