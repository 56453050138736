import { t } from 'i18next';

export type StatusEnumKeys = keyof typeof StatusEnum;

export enum StatusEnum {
  ACTIVE = 'Active',
  AWAITING_ENG_REVIEW = 'AWAITING_ENG_REVIEW',
  AWAITING_MDM = 'AWAITING_MDM',
  AWAITING_QA_QC = 'AWAITING_QA_QC',
  AWAITING_REQUISITIONER = 'AWAITING_REQUISITIONER',
  BLOCKED = 'Blocked',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
}

export enum StatusValueEnum {
  ACTIVE = 'ACTIVE',
  AWAITING_ENG_REVIEW = 'AWAITING_ENG_REVIEW',
  AWAITING_MDM = 'AWAITING_MDM',
  AWAITING_QA_QC = 'AWAITING_QA_QC',
  AWAITING_REQUISITIONER = 'AWAITING_REQUISITIONER',
  BLOCKED = 'BLOCKED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  DRAFT = 'DRAFT',
}

export const getStatuses = () => [
  {
    label: t(`statuses.ACTIVE`),
    value: StatusValueEnum.ACTIVE,
  },
  {
    label: t(`statuses.BLOCKED`),
    value: StatusValueEnum.BLOCKED,
  },
  {
    label: t(`statuses.AWAITING_ENG_REVIEW`),
    value: StatusValueEnum.AWAITING_ENG_REVIEW,
  },
  {
    label: t(`statuses.AWAITING_QA_QC`),
    value: StatusValueEnum.AWAITING_QA_QC,
  },
  {
    label: t(`statuses.AWAITING_MDM`),
    value: StatusValueEnum.AWAITING_MDM,
  },
  {
    label: t(`statuses.AWAITING_REQUISITIONER`),
    value: StatusValueEnum.AWAITING_REQUISITIONER,
  },
  {
    label: t(`statuses.DRAFT`),
    value: StatusValueEnum.DRAFT,
  },
  {
    label: t(`statuses.COMPLETED`),
    value: StatusValueEnum.COMPLETED,
  },
  {
    label: t(`statuses.CANCELED`),
    value: StatusValueEnum.CANCELED,
  },
];
