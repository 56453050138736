import { FC, useState } from 'react';

import { t } from 'i18next';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Input } from '@mantine/core';

import { FilterRender } from '@/types/common/IFilters';

interface InputFilterInterface {
  filterRender?: FilterRender;
  placeholder?: string;
}

export const InputFilter: FC<InputFilterInterface> = ({
  filterRender,
  placeholder,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [value, setValue] = useState(filtered[key]);

  const onInputSubmit = (clear = false) => {
    setValue(value.trim());
    onFilter({ [key]: clear ? undefined : value.trim() });
  };

  return (
    <div className="space-y-3 bg-white max-w-tiny">
      <Input
        autoFocus
        className="border border-b-1 border-x-0 border-t-0"
        classNames={{ input: 'h-9 !bg-transparent !border-none' }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder={`Filter by ${key}`}
        value={value}
        variant="unstyled"
      />
      <div className="grid grid-cols-2 gap-2 mt-2">
        <Button
          className="h-auto p-1"
          disabled={!value || value == filtered[key]}
          leftSection={<SearchOutlined />}
          onClick={() => onInputSubmit()}
          size="small"
          variant="primary"
        >
          {t(`search`)}
        </Button>
        <Button
          className="h-auto p-1"
          onClick={() => onInputSubmit(true)}
          size="small"
          variant="outline"
        >
          {t(`clear`)}
        </Button>
      </div>
    </div>
  );
};
