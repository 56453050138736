import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { object, ObjectSchema, string, number, array } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IWorkOrderMaterial } from '@/types/IWorkOrder';
import { IMaterial } from '@/types/IMaterial';

export type IOrderCheckout = Pick<
  IWorkOrderMaterial,
  | 'itemCategory'
  | 'unitOfMeasurement'
  | 'storageLocation'
  | 'unloadingPlant'
  | 'purchasingGroup'
  | 'purchasingOrganization'
  | 'requirementsDate'
  | 'activity'
  | 'createdBy'
  | 'requirementQuantity'
  | 'materialNumber'
  | 'materialDescription'
  | 'updateAt'
  | 'plant'
> & {
  material: Pick<
    IMaterial,
    'id' | 'quantity' | 'stockNumber' | 'branchPlant' | 'description1' | 'plant'
  >;
};

export type IOrderCheckoutRequest = {
  payload: IOrderCheckout[];
  params: {
    workOrderNumber: string;
  };
};

export type IWorkOrderCheckoutRequest = {
  payload: Pick<
    IWorkOrderMaterial,
    | 'itemCategory'
    | 'unitOfMeasurement'
    | 'storageLocation'
    | 'unloadingPlant'
    | 'purchasingGroup'
    | 'purchasingOrganization'
    | 'requirementsDate'
    | 'activity'
    | 'createdBy'
    | 'requirementQuantity'
    | 'materialNumber'
    | 'materialDescription'
    | 'updateAt'
    | 'plant'
  >[];
  params: {
    workOrderNumber: string;
  };
};

type IOrderCheckoutResponse = AxiosResponse<void>;

export const orderCheckoutInitial: IOrderCheckout = {
  itemCategory: '',
  unitOfMeasurement: '',
  storageLocation: '',
  unloadingPlant: '',
  purchasingGroup: 'K01',
  purchasingOrganization: 'K01',
  requirementsDate: new Date(new Date().getTime() + 45 * 24 * 60 * 60 * 1000),
  activity: '0010',
  createdBy: null,
  requirementQuantity: 0,
  materialNumber: '',
  materialDescription: '',
  updateAt: '',
  plant: '',
  material: {
    id: '',
    quantity: 0,
    stockNumber: 0,
    branchPlant: null,
    plant: '',
    description1: '',
  },
};

export const orderCheckoutValidation: ObjectSchema<IWorkOrderCheckoutRequest> =
  object({
    payload: array()
      .of(
        object({
          itemCategory: string().required('Поле обязательно для заполнения'),
          unitOfMeasurement: string().required(
            'Поле обязательно для заполнения'
          ),
          storageLocation: string().required('Поле обязательно для заполнения'),
          unloadingPlant: string(),
          purchasingGroup: string().required('Поле обязательно для заполнения'),
          purchasingOrganization: string().required(
            'Поле обязательно для заполнения'
          ),
          activity: string().required(),
          requirementsDate: string().required(),
          createdBy: number().required(),
          requirementQuantity: number().required(),
          materialNumber: string().required(),
          materialDescription: string(),
          updateAt: string().required(),
          plant: string().required('Поле обязательно для заполнения'),
          material: object({
            id: string().required(),
            quantity: number().required(),
            stockNumber: number().required(),
            description1: string().nullable(),
          }),
        })
      )
      .required(),
    params: object({
      workOrderNumber: string().required(),
    }),
  });

export function useOrderCheckout() {
  const queryClient = useQueryClient();

  return useMutation<
    IOrderCheckoutResponse,
    AxiosError,
    IWorkOrderCheckoutRequest
  >(
    ({ payload, params }) =>
      api.post('/work-orders/api/materials/checkout', payload, {
        params: {
          workOrderNumber: params.workOrderNumber,
        },
      }),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () => queryClient.invalidateQueries([queryKeys.basket]),
    }
  );
}
