import { useNavigate } from 'react-router-dom';

import { AxiosError, AxiosResponse } from 'axios';
import { Maybe, object, ObjectSchema, string } from 'yup';

import { storageKeys } from '@consts/storageKeys';
import { useMutation } from '@tanstack/react-query';
import { api } from '@utils/index';
import { notify } from '@utils/notify';

import { IBackendError } from '@/types/common/IBackendError';
import { IAuth } from '@/types/IAuth';

export type IUserAuthRequest = {
  email: string;
  password: string;
};

export const authInitial: IUserAuthRequest = {
  email: '',
  password: '',
};

export const authValidation: ObjectSchema<IUserAuthRequest> = object().shape({
  email: string().required(),
  password: string()
    .required()
    .max(30, 'Пароль должен быть не более 20 символов'),
});

export function useAuth() {
  const navigate = useNavigate();

  return useMutation<
    AxiosResponse<IAuth>,
    AxiosError<Maybe<IBackendError>>,
    IUserAuthRequest
  >((payload) => api.post('/users/api/auth/login', payload), {
    onError: (error) => {
      if (error.response?.data?.message === 'User is blocked') {
        notify('error', 'User is blocked! Please contact the administrator.');
      } else {
        notify('error', error.message);
      }
    },
    onSuccess: (response) => {
      if (response.data.access_token) {
        localStorage.setItem(
          storageKeys.tokenAccess,
          response.data.access_token
        );
        localStorage.setItem(
          storageKeys.tokenRefresh,
          response.data.refresh_token
        );
        localStorage.setItem(storageKeys.basketItems, '0');
        navigate('/');
      }
    },
  });
}
