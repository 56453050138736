import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 22 20"
    {...props}
  >
    <path
      stroke="#6E7079"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="m16.903 6.851-4.444 3.613c-.84.666-2.02.666-2.86 0L5.12 6.851"
    />
    <path
      stroke="#6E7079"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M15.909 19C18.95 19.008 21 16.51 21 13.438V6.57C21 3.499 18.95 1 15.909 1H6.09C3.05 1 1 3.499 1 6.57v6.868C1 16.51 3.05 19.008 6.091 19z"
      clipRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgMessage);
export default Memo;
