import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type IBasketQuantityRequest = {
  materialId: string;
  operation: 'PLUS' | 'MINUS';
  quantity: number;
};

type IBasketQuantityResponse = AxiosResponse<void>;

export function useBasketQuantity() {
  const queryClient = useQueryClient();

  return useMutation<
    IBasketQuantityResponse,
    AxiosError,
    IBasketQuantityRequest
  >(({ ...payload }) => api.put(`/materials/api/basket/quantity`, payload), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => queryClient.invalidateQueries([queryKeys.basket]),
  });
}
