import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IAttribute } from '@/types/IAttribute';

type IAttributesResponse = IAttribute[];

export function useAttributes({
  ...params
}: {
  modifierId?: string;
  nounId?: string;
  // searchQuery?: string;
}) {
  return useQuery<IAttributesResponse, AxiosError>(
    [queryKeys.attributes, params],
    () =>
      api
        .get(`/materials/api/sar-attribute/defaults`, {
          params,
        })
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) && !!params.modifierId,
    }
  );
}
