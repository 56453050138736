import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IUser } from '@/types/IUser';

type IUserRequest = { id: string };

type IUserResponse = AxiosResponse<IUser>;

export function useUser(params: IUserRequest) {
  return useQuery<IUserResponse, AxiosError>(
    [queryKeys.user, params],
    () => api.get(`/users/api/admin/users/${params.id}`),
    { enabled: !!params.id }
  );
}
