import { IAttribute } from './IAttribute';
import { IBranchPlant } from './IBranchPlant';

export type IMaterial = {
  attributes: IAttribute;
  branchPlant: IBranchPlant | null;
  plant: string;
  description1?: string;
  description2?: string;
  id: string;
  modifier: string;
  noun: string;
  quantity: number;
  stockNumber: number;
};

export const materialKeys: Record<keyof IMaterial, string> = {
  attributes: 'attributes',
  branchPlant: 'branchPlant',
  plant: 'plant',
  description1: 'description1',
  description2: 'description2',
  id: 'id',
  modifier: 'modifier',
  noun: 'noun',
  quantity: 'quantity',
  stockNumber: 'stockNumber',
};
