import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IMeasurementUnit } from '@/types/IMeasurementUnit';

export type IMeasurementEditRequest = Pick<
  IMeasurementUnit,
  'fullName' | 'shortName' | 'id'
>;

type IMeasurementEditResponse = AxiosResponse<IMeasurementUnit>;

export const measurementEditInitial: IMeasurementEditRequest = {
  fullName: '',
  id: '',
  shortName: '',
};

export const measurementEditValidation: ObjectSchema<IMeasurementEditRequest> =
  object({
    fullName: string().required(),
    id: string().required(),
    shortName: string().required(),
  });

export function useMeasurementEdit() {
  const queryClient = useQueryClient();

  return useMutation<
    IMeasurementEditResponse,
    AxiosError,
    IMeasurementEditRequest
  >(
    (payload) => api.put(`/materials/api/admin/units-of-measurement`, payload),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () =>
        queryClient.invalidateQueries([queryKeys.unitsOfMeasurements]),
    }
  );
}
