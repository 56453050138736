import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IPageAbleResponse } from '@/types/common/IPageAbleResponse';
import { IWorkOrder, IWorkOrderMaterial } from '@/types/IWorkOrder';

export type IOrderMaterialsPageAbleResponse<T> = {
  workOrderMaterialsCount: number | null;
  favoriteCount: number | null;
  workOrdersCount: number | null;
  workOrder: IWorkOrder;
  workOrderMaterials: IPageAbleResponse<T>;
};

type IOrdersResponse = IOrderMaterialsPageAbleResponse<IWorkOrderMaterial>;

export function useWorkOrderMaterials({
  ...params
}: IPageAbleRequest & {
  workOrderId: number;
  materialNumber?: string;
  showFavoriteList?: boolean;
}) {
  return useQuery<IOrdersResponse, AxiosError>(
    [queryKeys.orderMaterials, params],
    () =>
      api
        .get(`/work-orders/api/orders/${params.workOrderId}`, {
          params,
        })
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
