import { IPageActions } from '@/types/common/IPageActions';

enum IEnumSARPageActions {
  'create',
  'delete',
  'edit',
  'addMaterial',
  'deleteAttribute',
  'editMaterialInfo',
  'editMaterial',
  'publishSar',
  'deleteMaterial',
  'approve',
  'reject',
  'cancel',
  'addCertificate',
  'editCertificate',
  'addMaterialNumber',
  'editMaterialNumber',
}

export type ISARPageActions = {
  action: keyof typeof IEnumSARPageActions;
  actionId: string;
};

export const sarPageActions: IPageActions<ISARPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumSARPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
