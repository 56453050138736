import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IStockRequest } from '@/types/IStockRequest';
import { ISarPageAbleResponse } from '@/types/ISarPageAbleResponse';

type IStockRequestResponse = ISarPageAbleResponse<IStockRequest>;

export function useStockRequests({
  ...params
}: IPageAbleRequest & {
  searchQuery?: string;
  actionRequired: boolean | null;
}) {
  return useQuery<IStockRequestResponse, AxiosError>(
    [queryKeys.stockRequests, params],
    () => api.get('/materials/api/sars', { params }).then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
