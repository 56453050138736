import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import { Checkbox } from '@mantine/core';

import { IAttribute } from '@/types/IAttribute';

export const attributesColumns: MRT_ColumnDef<IAttribute>[] = [
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.id ?? '-'}</span>
    ),
    accessorKey: 'id',
    header: t('id'),
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">{row.value ?? '-'}</span>
    ),
    accessorKey: 'attribute',
    header: t('attributes.attribute'),
  },
  {
    accessorFn: (row) => (
      <Checkbox
        classNames={{ inner: 'm-2', label: 'p-2' }}
        defaultChecked={row.unitOfMeasurement}
        disabled
        key={`unitOfMeasurement-${row.id}`}
      />
    ),
    accessorKey: 'unitOfMeasurement',
    header: t('attributes.unitOfMeasurement'),
  },
];
