import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DeleteOutlined, FileAddOutlined } from '@ant-design/icons';
import { useAttributesByNounId } from '@api/attributes/useAttributesByNounId';
import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, CloseButton, Input } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import { ConfirmDeleteModal } from './components/ConfirmDeleteModal';
import { CreateAttributeModal } from './components/CreateAttributeModal';
import { attributesColumns } from './consts/columns/attributesColumns';
import { attributesFilters } from './consts/filters/attributesFilters';
import {
  attributesPageActions,
  IAttributesPageActions,
} from './consts/pageActions/IAttributesPageActions';

const AttributesPage: FC = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const { actioned, clearParams, filtered, onFilter, setPartialActioned } =
    useUrlFilteredActioned<IPageAbleRequest, IAttributesPageActions>(
      attributesFilters,
      attributesPageActions
    );

  const { modifierId = '', nounId = '' } = useParams<{
    modifierId: string;
    nounId: string;
  }>();

  const attributesQuery = useAttributesByNounId({
    ...filtered,
    modifierId,
    nounId,
    // searchQuery,
  });

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/nouns', title: 'Nouns' },
          { link: `/nouns/${nounId}/modifiers`, title: 'Modifiers' },
          {
            link: `/nouns/${nounId}/modifiers/${modifierId}/attributes`,
            title: 'Attributes',
          },
        ]}
        selectedMenuKeys={['attributes']}
        title="Attributes"
      />
      <div className="bg-white border-none p-5 rounded-xl">
        <div className="flex justify-between">
          <Input
            className="mb-5 !mt-0 w-[280px]"
            mt="md"
            onChange={(event) => setSearchQuery(event.currentTarget.value)}
            placeholder="Clearable input"
            rightSection={
              <CloseButton
                aria-label="Clear input"
                onClick={() => setSearchQuery('')}
                style={{ display: searchQuery ? undefined : 'none' }}
              />
            }
            value={searchQuery}
          />
          <Button
            disabled={!attributesQuery.isFetched}
            leftSection={<FileAddOutlined className="w-4 h-4" />}
            onClick={() => {
              setPartialActioned({
                action: 'create',
              });
            }}
            variant="outline"
          >
            Add attribute
          </Button>
        </div>
        <QueryTable
          actions={(row) => [
            {
              icon: <DeleteOutlined />,
              onClick: () => {
                setPartialActioned({
                  action: 'delete',
                  actionId: row.id ?? '',
                });
              },
              title: '',
            },
          ]}
          columns={attributesColumns}
          empty={{
            description: 'No items available',
            title: '',
          }}
          data={attributesQuery.data}
          query={attributesQuery}
          tableFilter={{ filtered, filters: attributesFilters, onFilter }}
        />

        <ConfirmDeleteModal
          id={actioned.actionId}
          isOpen={actioned.action === 'delete'}
          onClose={clearParams}
        />

        <CreateAttributeModal
          isOpen={actioned.action === 'create'}
          onClose={clearParams}
        />
      </div>
    </div>
  );
};

export default AttributesPage;
