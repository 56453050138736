import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { ICertificate } from '@/types/ICertificate';

export type ICertificatesResponse = ICertificate[];

export function useCertificates({ ...params }: { searchQuery?: string }) {
  return useQuery<ICertificatesResponse, AxiosError>(
    [queryKeys.certificates, params],
    () =>
      api
        .get(`/materials/api/certificates`, { params })
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
