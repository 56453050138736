import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';
import { IEnumSarActionStatusKeys } from '@consts/enums/IEnumStatuses';
import { object, ObjectSchema, string, number } from 'yup';
import { IEnumSarActionRolesKeys } from '@consts/enums/IEnumSarActionRoles';

export type IStockRequestActionRequest = {
  id: number;
  status: IEnumSarActionStatusKeys;
  reason?: string | null;
  returnToUser?: IEnumSarActionRolesKeys;
};

type IStockRequestActionResponse = AxiosResponse<void>;

export const stockRequestActionInitial: IStockRequestActionRequest = {
  id: 0,
  status: 'CANCEL',
  reason: '',
  returnToUser: undefined,
};

export const stockRequestActionValidation: ObjectSchema<IStockRequestActionRequest> =
  object({
    id: number().required('This field is required!'),
    reason: string()
      .when('isUomRequired', {
        is: (status: IEnumSarActionStatusKeys) => status != 'APPROVE',
        otherwise: (s) => s.notRequired(),
        then: (s) => s.required('This field is required!'),
      })
      .nullable(),
    status: string<IEnumSarActionStatusKeys>().required(
      'This field is required!'
    ),
    returnToUser: string<IEnumSarActionRolesKeys>(),
  });

export function useStockRequestAction() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockRequestActionResponse,
    AxiosError,
    IStockRequestActionRequest
  >(
    (params) =>
      api.post(
        `/materials/api/sars/reviewers/action/${params.id}`,
        {},
        {
          params: {
            reason: params.reason,
            status: params.status,
            returnToUser: params.returnToUser,
          },
        }
      ),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.stockRequests]);
        queryClient.invalidateQueries([queryKeys.stockRequest]);
        queryClient.invalidateQueries([queryKeys.stockRequestsHistory]);
      },
    }
  );
}
