import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { ICertificate } from '@/types/ICertificate';
import { SarContext } from '@context/SarContext';
import { UserContext } from '@context/UserContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { ActionIcon, Alert, Button, Text } from '@mantine/core';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';
import { IconEdit, IconInfoCircle } from '@tabler/icons-react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export default function CertificatesCard({
  certificates,
}: {
  certificates: ICertificate[];
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { userMe } = useContext(UserContext);
  const { sarStatus } = useContext(SarContext);
  const { materialId } = useParams();
  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    ISARPageActions
  >(sarFilters, sarPageActions);

  return (
    <div className="bg-white p-6 rounded-xl mb-6">
      <div className="flex justify-between mb-8">
        <Text>Certificates</Text>
        {certificates.length != 0 && userMe?.role != 'ADMIN' && (
          <div className="flex flex-row justify-between">
            <ActionIcon
              aria-label="Edit Material"
              onClick={() => {
                setPartialActioned({
                  action: 'editCertificate',
                  actionId: materialId?.toString(),
                });
              }}
              variant="transparent"
            >
              <IconEdit width={20} height={20} />
            </ActionIcon>
          </div>
        )}
      </div>
      {certificates.length == 0 ? (
        <>
          <Alert
            variant="light"
            color="yellow"
            title="No certificates"
            icon={<IconInfoCircle color="#FD7E14" />}
          >
            No certificates were added to this material
          </Alert>

          {(userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
            sarStatus == 'AWAITING_ENG_REVIEW') ||
          (userMe?.role == 'USER' && sarStatus == 'DRAFT') ||
          (userMe?.role == 'USER' && sarStatus == 'AWAITING_REQUISITIONER') ||
          (userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
            sarStatus == 'AWAITING_MDM') ||
          (userMe?.role == 'QA_QC_REVIEWER' &&
            sarStatus == 'AWAITING_QA_QC') ? (
            <Button
              onClick={() =>
                setPartialActioned({
                  action: 'addCertificate',
                  actionId: materialId?.toString(),
                })
              }
            >
              Add certificate
            </Button>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div>
          {certificates.map((certificate) => (
            <div className="border-t border-t-1 py-2" key={certificate.id}>
              {certificate.certificate}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
