import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { object, ObjectSchema, string, number } from 'yup';

import { IEnumSarStatusKeys } from '@consts/enums/IEnumStatuses';
import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IStockRequest } from '@/types/IStockRequest';

export type IStockRequestEditRequest = Pick<
  IStockRequest,
  | 'sarName'
  | 'objectPart'
  | 'dataChampionReviewer'
  | 'qaQcReviewer'
  | 'materialControllerReviewer'
  | 'status'
  | 'updatedBy'
  | 'updatedAt'
  | 'id'
>;

type IStockRequestEditResponse = AxiosResponse<number>;

export const stockRequestEditInitial: IStockRequestEditRequest = {
  sarName: '',
  id: 0,
  objectPart: '',
  dataChampionReviewer: 0,
  materialControllerReviewer: 0,
  qaQcReviewer: 0,
  status: 'DRAFT',
  updatedBy: '',
  updatedAt: '',
};

export const stockRequestEditValidation: ObjectSchema<IStockRequestEditRequest> =
  object({
    id: number().required('This field is required!'),
    updatedAt: string().required(),
    updatedBy: string().required(),
    dataChampionReviewer: number().required('This field is required!'),
    materialControllerReviewer: number().required('This field is required!'),
    objectPart: string().required('This field is required!'),
    qaQcReviewer: number().required('This field is required!'),
    sarName: string().required('This field is required!'),
    status: string<IEnumSarStatusKeys>().required('This field is required!'),
  });

export function useStockRequestEdit() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockRequestEditResponse,
    AxiosError,
    IStockRequestEditRequest
  >((payload) => api.put('/materials/api/sars/update', payload), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => queryClient.invalidateQueries([queryKeys.stockRequest]),
  });
}
