import { FC, useContext, useEffect, useMemo } from 'react';

import { useBasketMaterials } from '@api/basket/useBasketMaterials';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, Select, Skeleton, Text } from '@mantine/core';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';

import BasketItemCard from '../components/BasketItemCard';
import { ConfirmDeleteModal } from '../components/ConfirmDeleteModal';
import { basketFilters } from '../consts/filters/basketFilters';
import {
  basketPageActions,
  IBasketPageActions,
} from '../consts/pageActions/IBasketPageActions';
import { useWorkOrders } from '@api/orders/useWorkOrders';
import { useForm, yupResolver } from '@mantine/form';
import {
  IOrderCheckoutRequest,
  orderCheckoutInitial,
  IOrderCheckout,
  // orderCheckoutValidation,
  useOrderCheckout,
  orderCheckoutValidation,
} from '@api/orders/useOrderCheckout';
import { notify } from '@utils/notify';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useDictionaries } from '@api/dictionaries/useDictionaries';
import { useUserByRole } from '@api/users/useUserByRole';
import { IRoleEnum } from '@/types/enums/rolesEnum';
import { UserContext } from '@context/UserContext';

const BasketPage: FC = () => {
  const navigate = useNavigate();
  const { userMe } = useContext(UserContext);

  const basketQuery = useBasketMaterials();
  const basket = basketQuery.data;

  const ordersQuery = useWorkOrders();
  const orders = ordersQuery.data;

  const orderCheckout = useOrderCheckout();

  const { actioned, clearParams } = useUrlFilteredActioned<
    IPageAbleRequest,
    IBasketPageActions
  >(basketFilters, basketPageActions);

  const objectPartsQuery = useDictionaries({});
  const itemCategories = useMemo(() => {
    if (objectPartsQuery.isFetched) {
      const mappings =
        objectPartsQuery?.data?.find((object) => object.id == '67')?.mappings ??
        [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        value: v as string,
      }));
    }
    return [];
  }, [objectPartsQuery]);

  const purchasingGroups = useMemo(() => {
    if (objectPartsQuery.isFetched) {
      const mappings =
        objectPartsQuery?.data?.find((object) => object.id == '66')?.mappings ??
        [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        value: v as string,
      }));
    }
    return [];
  }, [objectPartsQuery]);

  const measurements = useMemo(() => {
    if (objectPartsQuery.isFetched) {
      const mappings =
        objectPartsQuery?.data?.find((object) => object.id == '41')?.mappings ??
        [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        value: v as string,
      }));
    }
    return [];
  }, [objectPartsQuery]);

  const plantsWithLocation = useMemo(() => {
    if (objectPartsQuery.isFetched) {
      const mappings =
        objectPartsQuery?.data?.find((object) => object.id == '64')?.mappings ??
        [];
      return Object.entries(mappings).map(([k, v]) => ({
        label: k,
        values: v as string[],
      }));
    }
    return [];
  }, [objectPartsQuery]);

  const usersQuery = useUserByRole({
    role: IRoleEnum.USER,
  });
  const users = usersQuery?.data?.data;

  const usersList =
    users?.map((user) => ({
      label: user.username,
      value: user.id.toString(),
    })) || [];

  const initialValues = basket?.content.reduce((acc: any[], field) => {
    const itemCategoryInitial = itemCategories.find(
      (category) => category.label == 'N'
    );

    acc.push({
      ...orderCheckoutInitial,
      itemCategory: itemCategoryInitial?.value,
      createdBy: String(userMe?.id),
      material: {
        ...field.material,
        id: field.material.id as string,
        quantity: field.quantity,
        stockNumber: field.material.stockNumber,
        branchPlant: field.material.branchPlant,
        description1: field.material.description1,
        createdBy: Number(userMe?.id),
        materialNumber: String(field.material.stockNumber),
      },
      materialDescription: field.material.description1 ?? '',
      materialNumber: field.material.stockNumber,
      updateAt: new Date().toISOString(),
      requirementQuantity: field.quantity,
    });
    return acc;
  }, []);

  const form = useForm<IOrderCheckoutRequest>({
    initialValues: {
      payload: initialValues as IOrderCheckout[],
      params: {
        workOrderNumber: '',
      },
    },
    validateInputOnChange: true,
    validate: yupResolver(orderCheckoutValidation),
  });

  useEffect(() => {
    if (!form.values.payload) {
      form.setValues({
        payload: initialValues as IOrderCheckout[],
        params: {
          workOrderNumber: '',
        },
      });
    }
  }, [initialValues, form.values.payload]);

  const orderId = useMemo(() => {
    if (orders && form.values.params.workOrderNumber) {
      return orders.find(
        (order) =>
          String(order.workOrderNumber) == form.values.params.workOrderNumber
      )?.id;
    }
  }, [form.values.params, orders]);

  return (
    <div>
      <PageMeta
        breadcrumbs={[{ link: '/basket', title: 'Basket' }]}
        selectedMenuKeys={['basket']}
        title="Basket"
      />
      {basketQuery.isLoading ? (
        <>
          <Skeleton className="rounded-xl h-56" />
          <Skeleton className="w-24 h-8" />
          <Skeleton className="rounded-xl h-56" />
        </>
      ) : !(basket && initialValues) ? (
        <NotFoundResult title="There are no materials in your basket." />
      ) : (
        <form
          onSubmit={form.onSubmit((values) => {
            orderCheckout
              .mutateAsync({
                ...values,
              })
              .then((res) => {
                notify(
                  'success',
                  `Materials were successfully added to Work Order #${res.data}`
                );
                navigate(`/orders/details/${orderId}`);
              })
              .catch(() => {
                notify('error', t('tryAgainLater'));
              });
          })}
        >
          <div className="grid grid-cols-3 gap-4 mt-5">
            <div className="col-span-2">
              {!form.values.payload ? (
                <>
                  <Skeleton className="rounded-xl h-24" />
                </>
              ) : (
                form.values.payload.map((item, index) => (
                  <BasketItemCard
                    form={form}
                    key={index}
                    material={item.material}
                    quantity={item.material?.quantity}
                    index={index}
                    itemCategories={itemCategories}
                    purchasingGroups={purchasingGroups}
                    measurements={measurements}
                    usersList={usersList}
                    plantsWithLocation={plantsWithLocation}
                  />
                ))
              )}
            </div>
            <div className="h-min bg-white col-span-1 py-6 px-8 rounded-xl border border-[#E4E6ED]">
              <Text fw={600}>Checkout order</Text>
              <Text size="sm" className="text-[#6B7280] pt-2 pb-4">
                To complete your order, please select/assign a phone number for
                the order.
              </Text>
              <Select
                className="mt-0"
                comboboxProps={{ withinPortal: false }}
                data={(orders ?? []).map((order) => ({
                  value: String(order.workOrderNumber),
                  label: String(order.workOrderNumber),
                }))}
                label="Work order"
                nothingFoundMessage="Nothing found..."
                readOnly={false}
                withAsterisk
                {...form.getInputProps(`params.workOrderNumber`)}
              />
              <Button
                className="w-full mt-6"
                type="submit"
                disabled={!form.values.params.workOrderNumber}
              >
                Checkout
              </Button>
            </div>
          </div>
        </form>
      )}
      <ConfirmDeleteModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />
    </div>
  );
};

export default BasketPage;
