import { useTranslation } from 'react-i18next';

import { Globus } from '@assets/icons';
import { Button, Menu } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';

import { IEnumLocalization } from '@/types/common/ILocalization';

export default function LanguageDropdown() {
  const {
    i18n: { language },
    t,
  } = useTranslation();

  return (
    <Menu classNames={{ dropdown: 'rounded-lg mt-4' }} keepMounted>
      <Menu.Target>
        <Button
          className="border-none capitalize text-gray-500"
          leftSection={<Globus width={20} />}
          rightSection={<IconChevronDown className="text-[#a3a3a3]" />}
          variant="outline"
        >
          {language}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label className="font-semibold text-gray-500">
          {t('chooseLanguage')}
        </Menu.Label>

        <Menu.Divider />

        {Object.keys(IEnumLocalization).map((locale) => (
          <Menu.Item
            className="hover:text-primary font-medium hover:font-semibold"
            key={`localization-${locale}`}
            onClick={() => {
              localStorage.setItem('language', locale);
              window.location.reload();
            }}
          >
            {locale.toUpperCase()}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
