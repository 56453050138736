import { FC, useContext, useRef, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';

import { Cart, Home } from '@assets/icons';
import LanguageDropdown from '@components/molecules/LanguageDropdown';
import ProfileDropdown from '@components/molecules/ProfileDropdown';
import { SideNavbar } from '@components/organisms/SideNavbar';
import { PageMetaContext, usePageMetaContext } from '@context/PageMetaContext';
import { UserContext } from '@context/UserContext';
import {
  ActionIcon,
  Anchor,
  AppShell,
  Breadcrumbs,
  Burger,
  Group,
  Indicator,
  rem,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import AuthRoute from '@routes/AuthRoute';

const Layout: FC = () => {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const [opened, { toggle }] = useDisclosure();
  const ref = useRef(null);

  const { basketQuantity } = useContext(UserContext);

  const { title } = usePageMetaContext();
  const pageMetaContext = useContext(PageMetaContext);
  const [navbarWidth, setNavbarWidth] = useState(270);

  const items = pageMetaContext.breadcrumbs?.map((item, index) => (
    <Anchor href={item.link} key={index}>
      {item.title}
    </Anchor>
  ));

  return (
    <AuthRoute auth>
      <AppShell
        footer={{ height: 60 }}
        header={{ height: 60 }}
        layout="alt"
        navbar={{
          breakpoint: 'sm',
          collapsed: { desktop: !desktopOpened, mobile: !mobileOpened },
          width: navbarWidth,
        }}
        padding={24}
        transitionDuration={200}
        transitionTimingFunction="ease"
      >
        <AppShell.Header>
          <Group h="100%" px="md">
            <Burger
              hiddenFrom="sm"
              onClick={toggleMobile}
              opened={mobileOpened}
              size="sm"
            />
            <Burger
              onClick={() => {
                toggle();
                setNavbarWidth(opened ? 300 : 90);
              }}
              size="sm"
              visibleFrom="sm"
            />
            <div className="ml-auto hidden items-center gap-8 lg:flex">
              <LanguageDropdown />

              <Link to="/basket">
                <ActionIcon
                  aria-label="basket"
                  color="gray"
                  variant="transparent"
                >
                  <Indicator
                    inline
                    label={basketQuantity}
                    offset={3}
                    position="top-end"
                    size={16}
                  >
                    <Cart />
                  </Indicator>
                </ActionIcon>
              </Link>

              <ProfileDropdown />
            </div>
          </Group>
          <div className="w-full flex py-1 px-5 bg-white !text-[#8B8D97] border-t-2 border-[#F1F3F9]">
            <Home width={14} />
            <span className="mx-3">/</span>
            <Breadcrumbs>{items}</Breadcrumbs>
          </div>
        </AppShell.Header>

        <AppShell.Navbar
          className="border-r-0"
          p={!opened ? 'md' : 'xs'}
          ref={ref}
        >
          <SideNavbar opened={opened} />
        </AppShell.Navbar>

        <AppShell.Main
          className="bg-[#F4F5FA]"
          pt={`calc(${rem(95)} + var(--mantine-spacing-md))`}
        >
          <Outlet />
        </AppShell.Main>
      </AppShell>
    </AuthRoute>
  );
};

export default Layout;
