import { IFilters } from '@/types/common/IFilters';
import { materialKeys } from '@/types/IMaterial';

export const materialsFilters: IFilters = {
  page: { queries: [{ default: 0 }] },
  size: {
    queries: [
      {
        default: 6,
      },
    ],
  },
  sort: { queries: [{ default: '' }] },
  [materialKeys.noun]: {
    sort: true,
  },
  [materialKeys.modifier]: {
    sort: true,
  },
  [materialKeys.quantity]: {
    sort: true,
  },
};
