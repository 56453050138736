import { FC } from 'react';

import { BadgeProps, Text } from '@mantine/core';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import {
  IOrderMaterialPageActions,
  orderMaterialPageActions,
} from '@modules/orders/consts/pageActions/IOrderMaterialPageActions';
import { orderMaterialsFilters } from '@modules/orders/consts/filters/orderMaterialsFilters';

type StatusProps = BadgeProps & {
  readonly id: string | number;
  readonly materialNumber: string;
};

export const MaterialLink: FC<StatusProps> = ({ id, materialNumber }) => {
  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    IOrderMaterialPageActions
  >(orderMaterialsFilters, orderMaterialPageActions);

  return (
    <Text
      className="text-[#228BE6]"
      onClick={() =>
        setPartialActioned({
          action: 'view',
          actionId: String(id) ?? '',
        })
      }
    >
      {materialNumber ?? '-'}
    </Text>
  );
};
