export type RuTranslationsType = typeof ruTranslations;
export const ruTranslations = {
  translation: {
    actions: 'Actions',
    activate: 'Activate',
    attributes: {
      attribute: 'Attribute',
      unitOfMeasurement: 'Unit Of Measurement',
    },
    auth: {
      auth: 'Authorization',
      changeEmail: 'Change email',
      checkEmail: 'Check your email!',
      confirmPassword: 'Confirm Password',
      email: 'Email',
      enter: 'Login',
      enterEmail: 'Enter your email address',
      enterNewPassword: 'Enter a new password',
      finishRegister: 'Complete registration',
      forgotPassword: 'Forgot your password?',
      incorrectLogin: 'Incorrect login or password',
      login: 'Login',
      newPassword: 'New Password',
      password: 'Password',
      pleaseCheckEmail: 'Please, check your email',
      recoverPassword: 'Password recovery',
      resetPassword: 'Reset password',
      send: 'Send',
      sendConfirmation:
        'We have sent you an email with instructions on how to reset your password.',
      userInactive: 'The user with this email address has been blocked',
      welcome: 'Welcome',
    },
    block: 'Block',
    cancel: 'Cancel',
    chooseLanguage: 'Choose language',
    clear: 'Clear',
    create: 'Create',
    createdAt: 'Created at',
    exit: 'Exit',
    goBack: 'Go Back',
    id: 'ID',
    material: {
      branchPlant: 'Branch Plant',
      description1: 'Description #1',
      description2: 'Description #2',
      material: 'Material',
      modifier: 'Modifier',
      noun: 'Noun',
      quantity: 'Quantity',
      saveMaterial: 'Save Material',
      stockNumber: 'Stock number',
    },
    branchPlant: {
      id: 'ID',
      date: 'Date',
      mrpElement: 'MRP element',
      mrpElementData: 'MRP element data',
      reschedulingDate: 'Rescheduling date',
      exception: 'Exception',
      receiptReqmt: 'Receipt/Reqmt',
      availableQty: 'Available Qty',
      supplRecevingPlant: 'Suppl. Receving Plant',
      issRecStorLoc: 'Iss./Rec. Stor. Loc.',
      storageLocation: 'Storage Location',
      reqmtPrio: 'Reqmt. Prio',
    },
    order: {
      all: 'All work orders',
      favorited: 'Favorited',
      materialNumber: 'Mat. Num.',
      materialDescription: 'Material Desc.',
      requirementQuantity: 'Req. Quant',
      unitOfMeasurement: 'UoM',
      requirementsDate: 'Req. Date',
      purchasingGroup: 'Purch. Group',
      str: 'STR',
      sto: 'STO',
      pr: 'PR',
      po: 'PO',
      deliveryDate: 'Delivery date',
      status: 'Status',
      itemCategory: 'Item Category',
      storageLocation: 'Storage Location',
      branchPlant: 'Branch Plant',
      activity: 'Activity',
      purchasingOrganization: 'Purch. Org.',
      unloadingPlant: 'Unloading Plant',
      assignedBuyer: 'Assigned Buyer',
      updateAt: 'Update date',
      plant: 'Plant',
    },
    materialInfo: {
      certificates: 'Certificates',
      accounting: 'Accounting',
      additional: 'Additional',
      availabilityCheck: 'Availability Check',
      baseUnitOfMeasure: 'Base unit of measure',
      basicData: 'Basic Data',
      eanUpc: 'EAN/UPC',
      genItemCatGroup: 'GenItemCatGroup',
      general: 'General',
      grProcessingTime: 'GR processing time',
      id: 'ID',
      individualCollective: 'Individual/collective',
      inspectionSetupIndicator: 'Inspection setup indicator',
      lotSizeIndicator: 'Lot Size Indicator',
      manufacturer: 'Manufacturer',
      manufacturingPartNumber: 'Manufacturing part number',
      materialDescription: 'Material Description',
      materialGroup: 'Material group',
      materialNumber: 'Material Number',
      maximumLotSize: 'Maximum lot size',
      maximumStockLevel: 'Maximum stock level',
      minSafetyStock: 'Min safety stock',
      minimumLotSize: 'Minimum lot size',
      movingAveragePricePeriodicUnitPrice:
        'Moving Average Price/Periodic Unit Price (USDK)',
      mrpController: 'MRP controller',
      mrpType: 'MRP type',
      plannedDeliveryTime: 'Planned delivery time',
      priceUnit: 'Price unit',
      purchaseOrderText: 'Purchase order text',
      purchasing: 'Purchasing',
      purchasingValueKey: 'Purchasing value key',
      qmControlKey: 'QM control key',
      qmProcActive: 'QM Proc. active',
      qualityManagement: 'Quality Management',
      reorderPoint: 'Reorder point',
      roundingValue: 'Rounding value',
      safetyStock: 'Safety stock',
      sarMaterialId: 'SAR Material ID',
      schedulingMarginKey: 'Scheduling margin key',
      specialProcurement: 'Special procurement',
      stockDetermineGroup: 'Stock determin.group',
      storageLocForExternalProcurement: 'Storage loc. for external procurement',
      validFrom: 'Valid from',
      valuationClass: 'Valuation class',
      xplantMaterialStatus: 'X plant material status',
    },
    messages: {
      error: 'Try again later!',
    },
    modifier: {
      modifier: 'Modifier',
      nounId: 'Noun ID',
    },
    more: 'More',
    noun: {
      modifiers: 'Modifiers',
      noun: 'Noun',
    },
    roles: {
      admin: 'Admin',
      dataChampionReviewer: 'Data Champion Reviewer',
      materialControllerReviewer: 'Material Controller Reviewer',
      qaQcReviewer: 'QA/QC Reviewer',
      user: 'User',
    },
    sar: {
      actionRequired: 'Action required',
      allRequests: 'All Requests',
      createdAt: 'Creation date',
      createdBy: 'Requisitioner',
      dataChampionReviewer: 'Data Champion Reviewer',
      materialControllerReviewer: 'Material Controller Reviewer',
      materialNumbers: 'Material Number',
      myRequests: 'My Requests',
      objectPart: 'Object Part',
      qaQcReviewer: 'QA/QC Reviewer',
      sarName: 'SAR Name',
      status: 'Status',
      updatedAt: 'Updated date',
    },
    save: 'Save',
    saveDraft: 'Save Draft',
    search: 'Search',
    statuses: {
      PR_RFQ_CREATED: 'PR - RFQ Created',
      PR_AWAITING_RELEASE: 'PR - Awaiting Release',
      STO_AWAITING_RELEASE: 'STO - Awaiting Release',
      PO_CREATED: 'PO - Created',
      PR_RELEASED: 'PR - Released',
      STR_COMPLETED: 'STR - Completed',
      ACTIVE: 'Active',
      AWAITING_ENG_REVIEW: 'Awaiting eng review',
      AWAITING_MDM: 'Awaiting MDM',
      AWAITING_QA_QC: 'Awaiting QA/QC',
      AWAITING_REQUISITIONER: 'Awaiting requisitioner',
      BLOCKED: 'Blocked',
      CANCELED: 'Cancelled',
      COMPLETED: 'Completed',
      DRAFT: 'Draft',
      PUBLISHED: 'Published',
    },
    tryAgainLater: 'Try again later',
    updatedAt: 'Updated at',
    user: {
      email: 'Email',
      firstName: 'First name',
      jobTitle: 'Job Title',
      lastName: 'Last name',
      name: 'Name',
      role: 'Permission',
      status: 'Status',
      username: 'Username',
    },
  },
};
