import { useEffect, useState, type FC } from 'react';
import {
  Button,
  Checkbox,
  CloseButton,
  Input,
  Loader,
  Modal,
  Text,
} from '@mantine/core';
import { useCertificates } from '@api/sar-materials/useCertificates';
import { useAddMaterialCertificates } from '@api/sar-materials/useAddMaterialCertificates';
import { notify } from '@utils/notify';
import { t } from 'i18next';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import { useStockMaterial } from '@api/sar-materials/useStockMaterial';
import { NotFoundResult } from '@components/molecules/NotFoundResult';

type IAddCertificateModal = {
  isOpen: boolean;
  id?: string;
  onClose: () => void;
};
export const AddCertificateModal: FC<IAddCertificateModal> = ({
  isOpen,
  onClose,
  id = '',
}) => {
  const { actioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    ISARPageActions
  >(sarFilters, sarPageActions);

  const [materialCerts, setMaterialCerts] = useState<string[] | number[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>();

  const certificatesQuery = useCertificates({ searchQuery });
  const certificates = certificatesQuery.data;

  const saveMaterialCertificates = useAddMaterialCertificates();

  const handleSave = () => {
    saveMaterialCertificates
      .mutateAsync({
        sarMaterialId: Number(id),
        certificateIds:
          materialCerts.length != 0 ? (materialCerts as number[]) : [],
      })
      .then(() => {
        notify(
          'success',
          actioned.action == 'addCertificate'
            ? 'Certificate was successfully added!'
            : 'Certificates was successfully updated!'
        );
        onClose();
      })
      .catch(() => {
        notify('error', t('tryAgainLater'));
      });
  };

  const stockMaterialQuery = useStockMaterial({
    sarMaterialId: Number(id),
  });
  const stockMaterial = stockMaterialQuery.data;

  useEffect(() => {
    if (stockMaterial && stockMaterial.certificates) {
      const certs = stockMaterial.certificates.map((certificate) =>
        String(certificate.id)
      );
      setMaterialCerts(certs);
    }
  }, [stockMaterial]);

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={550}
      title={
        <div className="flex flex-col">
          <Text size="lg" fw={500} className="mb-1">
            {actioned.action == 'addCertificate'
              ? 'Add certificate'
              : 'Edit certificate'}
          </Text>
          <span className="text-[#AEAEB2] text-xs">
            Please select one or more certificates for this material
          </span>
        </div>
      }
    >
      <div>
        <Input
          className="w-full"
          onChange={(event) => setSearchQuery(event.currentTarget.value)}
          placeholder="Search..."
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchQuery('')}
              style={{ display: searchQuery ? undefined : 'none' }}
            />
          }
          value={searchQuery}
        />
        <div className="my-2">
          {stockMaterialQuery.isLoading ? (
            <Loader />
          ) : stockMaterial ? (
            <Checkbox.Group
              classNames={{ label: 'p-1' }}
              defaultValue={materialCerts as string[]}
              onChange={(checkedValue) => {
                setMaterialCerts(checkedValue);
              }}
            >
              {certificates?.map((certificate) => (
                <Checkbox
                  classNames={{ inner: 'm-2', label: 'p-2' }}
                  key={`certificate-${certificate.id}`}
                  label={certificate.certificate}
                  value={certificate.id.toString()}
                />
              ))}
            </Checkbox.Group>
          ) : (
            <NotFoundResult />
          )}
        </div>
      </div>
      <div className="flex flex-row justify-center mt-8">
        <Button w={100} className="mr-6" onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button
          w={100}
          loading={saveMaterialCertificates.isLoading}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </Modal>
  );
};
