import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

type IAttributesResponse = {
  attributesFilters?: any;
  materialFilters?: any;
};

export function useFilterAttributes({
  ...params
}: {
  modifier?: string;
  noun?: string;
}) {
  return useQuery<IAttributesResponse, AxiosError>(
    [queryKeys.filterAttributes, params],
    () =>
      api
        .get(
          `/materials/api/filters/attributes/${params.noun}/${params.modifier}`,
          {
            params,
          }
        )
        .then((res) => res.data),
    {
      enabled:
        !!localStorage.getItem(storageKeys.tokenAccess) &&
        !!params.modifier &&
        !!params.noun,
    }
  );
}
