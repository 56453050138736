import { FC, useState } from 'react';

import { t } from 'i18next';

import { ArrowRightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from '@mantine/core';

import { FilterRender } from '@/types/common/IFilters';

interface InputRangeFilterInterface {
  filterRender?: FilterRender;
  placeholderMax?: string;
  placeholderMin?: string;
}

export const RangeFilter: FC<InputRangeFilterInterface> = ({
  filterRender,
  placeholderMax,
  placeholderMin,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const keyMin = queries?.[0]?.key || `${dataIndex}From`;
  const keyMax = queries?.[1]?.key || `${dataIndex}To`;

  const [min, setMin] = useState(filtered[keyMin]);
  const [max, setMax] = useState(filtered[keyMax]);

  const onRangeSubmit = (clear = false) =>
    max && min > max
      ? onFilter({
          [keyMax]: clear ? undefined : min,
          [keyMin]: clear ? undefined : max,
        })
      : onFilter({
          [keyMax]: clear ? undefined : max,
          [keyMin]: clear ? undefined : min,
        });

  return (
    <div className="space-y-3 bg-white max-w-tiny">
      <div className="grid grid-flow-col auto-cols-auto range-filter">
        <Input
          autoFocus
          className="border-r-0 rounded-r-none hover:border-gray-500"
          onChange={setMin}
          placeholder={placeholderMin}
          type="number"
          value={min}
        />
        <div className="text-tiny border-x-0 border-y flex items-center text-gray-400">
          <ArrowRightOutlined />
        </div>
        <Input
          className="border-l-0 rounded-l-none hover:border-gray-500"
          onChange={setMax}
          placeholder={placeholderMax}
          value={max}
        />
      </div>
      <div className="grid gap-1 grid-cols-2 rounded-none mt-2">
        <Button
          className="w-full"
          disabled={
            (!min && !max) ||
            (filtered[keyMin] == min && filtered[keyMax] == max)
          }
          leftSection={<SearchOutlined />}
          onClick={() => onRangeSubmit()}
          size="small"
          variant="primary"
        >
          {t(`search`)}
        </Button>
        <Button
          className="w-full"
          onClick={() => onRangeSubmit(true)}
          size="small"
        >
          {t(`clear`)}
        </Button>
      </div>
    </div>
  );
};
