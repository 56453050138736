import { Route, Routes } from 'react-router-dom';

import { CreateStockRequestPage } from './components/pages/CreateStockRequestPage';
import StockMaterialDetailsPage from './components/pages/StockMaterialDetailsPage';
import StockRequestDetailsPage from './components/pages/StockRequestDetailsPage';
import StockRequestsPage from './components/pages/StockRequestsPage';

export const SARRouter = () => {
  return (
    <Routes>
      <Route element={<StockRequestsPage />} index />
      <Route element={<StockRequestDetailsPage />} path="/details/:sarId" />
      <Route
        element={<StockMaterialDetailsPage />}
        path="/details/:sarId/material/:materialId"
      />
      <Route element={<CreateStockRequestPage />} path="/create" />
    </Routes>
  );
};
