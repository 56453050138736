import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

type IModifiersResponse = {
  [key: number]: string;
};

export function useFilterModifiers({ ...params }: { id: string }) {
  return useQuery<IModifiersResponse, AxiosError>(
    [queryKeys.filterModifiers, params],
    () =>
      api
        .get(`/materials/api/filters/modifiers/${params.id}`, {
          params,
        })
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess) && !!params.id,
    }
  );
}
