import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgGlobus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24px"
    height="24px"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <g
      stroke="#AEAEB2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      clipPath="url(#globus_svg__a)"
    >
      <path d="M8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12M2.401 6h11.2M2.401 10h11.2" />
      <path d="M7.666 2a11.333 11.333 0 0 0 0 12M8.333 2a11.333 11.333 0 0 1 0 12" />
    </g>
    <defs>
      <clipPath id="globus_svg__a">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
const Memo = memo(SvgGlobus);
export default Memo;
