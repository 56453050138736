import { createContext, FC, ReactNode, useContext } from 'react';

import { useCurrent } from '@api/auth/useCurrent';
import { useBasketMaterials } from '@api/basket/useBasketMaterials';
import { storageKeys } from '@consts/storageKeys';

import { IMaterial } from '@/types/IMaterial';
import { IUser } from '@/types/IUser';

type UserContextValues = {
  basketItems: {
    id: string;
    material: IMaterial;
    quantity: number;
  }[];
  basketQuantity: number;
  userMe?: IUser;
  userMeError: boolean;
  userMeFetching: boolean;
  userMeLoading: boolean;
};

export const UserContext = createContext<UserContextValues>({
  basketItems: [],
  basketQuantity: 0,
  userMe: undefined,
  userMeError: false,
  userMeFetching: !!localStorage.getItem(storageKeys.tokenAccess),
  userMeLoading: !!localStorage.getItem(storageKeys.tokenAccess),
});

export const UserContextProvider: FC<{ children?: ReactNode }> = (props) => {
  const userMeQuery = useCurrent();
  const userMe = userMeQuery.data?.data;

  const basketQuery = useBasketMaterials();
  const basket = basketQuery.data?.content;

  const logout = () => {
    localStorage.removeItem(storageKeys.tokenAccess);
    localStorage.removeItem(storageKeys.tokenRefresh);
  };

  return (
    <UserContext.Provider
      value={{
        basketItems: basket ?? [],
        basketQuantity: basket?.length ?? 0,
        userMe: userMe,
        userMeError: userMeQuery.isError,
        userMeFetching: userMeQuery.isFetching,
        userMeLoading: userMeQuery.isLoading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const userContext = useContext(UserContext);

  if (typeof userContext === 'undefined') {
    throw new Error('useUserContext must be used within a UserContextProvider');
  }

  return userContext;
};
