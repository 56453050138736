export type IEnumSarStatusKeys = keyof typeof IEnumSarStatus;

export enum IEnumSarStatus {
  AWAITING_ENG_REVIEW = 'Awaiting Eng Review',
  AWAITING_MDM = 'Awaiting MDM',
  AWAITING_QA_QC = 'Awaiting QA/QC',
  AWAITING_REQUISITIONER = 'Awaiting Requisitioner',
  CANCELED = 'Cancelled',
  COMPLETED = 'Completed',
  //   ARCHIVED = 'Archived',
  DRAFT = 'Draft',
}

export type IEnumSarActionStatusKeys = keyof typeof IEnumSarActionStatus;

export enum IEnumSarActionStatus {
  CANCEL = 'CANCEL',
  REJECT = 'REJECT',
  APPROVE = 'APPROVE',
  COMPLETE = 'COMPLETE',
}
