import { FC } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useBasketMaterialAdd } from '@api/basket/useBasketMaterialAdd';
import { useMaterial } from '@api/materials/useMaterial';
import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { PageMeta } from '@context/PageMetaContext';
import { useUserContext } from '@context/UserContext';
import { Box, Button, NumberInput, Skeleton } from '@mantine/core';

import MaterialInfo from '../organisms/MaterialInfo';

const MaterialDetailsPage: FC = () => {
  const [materialQuantity, setMaterialQuantity] = useState<number | string>(1);
  const { id = '' } = useParams<{ id: string }>();
  const materialQuery = useMaterial({ id });
  const material = materialQuery.data?.data;

  const addToBasket = useBasketMaterialAdd({
    materialId: id,
    quantity: materialQuantity as number,
  });

  const basket = useUserContext();

  return (
    <div className="">
      <PageMeta
        breadcrumbs={[
          { link: '/materials', title: 'Materials' },
          { link: `/materials/${id}`, title: `Material #${id}` },
        ]}
        selectedMenuKeys={['materials']}
        title="Materials"
      />
      {materialQuery.isLoading ? (
        <>
          <Skeleton className="rounded-xl h-56" />
          <Skeleton className="w-24 h-8" />
          <Skeleton className="rounded-xl h-56" />
        </>
      ) : !material ? (
        <NotFoundResult
          subTitle="There is no detailt about this material"
          title="No result"
        />
      ) : (
        <div>
          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2">
              <MaterialInfo material={material} />
            </div>
            <div className="col-span-1 mt-6">
              {!basket.basketItems.find((item) => item.material.id === id) ? (
                <Box className="flex flex-col items-baseline bg-white py-8 px-5 rounded-xl">
                  <NumberInput
                    className="w-[250px] mr-4 mb-6"
                    decimalSeparator=","
                    defaultValue={1}
                    min={1}
                    mt="md"
                    onChange={(value: number | string) =>
                      setMaterialQuantity(value)
                    }
                    placeholder="Quantity"
                    required
                    value={materialQuantity}
                  />
                  <Button
                    className="w-full"
                    onClick={() => addToBasket.mutateAsync()}
                  >
                    Add to basket
                  </Button>
                </Box>
              ) : (
                <div>
                  <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
                    Added to basket:
                  </p>
                  <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
                    {
                      basket.basketItems.find((item) => item.material.id === id)
                        ?.quantity
                    }
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaterialDetailsPage;
