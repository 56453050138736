export const mantineCommonTableProps = {
  defaultColumn: { maxSize: 350, minSize: 120, size: 120 },
  enableColumnActions: false,
  enableColumnFilters: false,
  enableExpanding: false,
  enableRowNumbers: false,
  enableTopToolbar: true,
  enableInternalToolbar: true,
  enableFullScreenToggle: true,
  mantineBottomToolbarProps: {
    className:
      'border-none bg-transparent mb-3 flex justify-between items-center',
    // sx: {
    //   boxShadow: 'none',
    //   'div:nth-child(2)': { flexDirection: 'row-reverse' },
    // },
  },
  mantineLoadingOverlayProps: {
    loaderProps: { className: 'hidden' },
    zIndex: 100,
  },
  mantinePaperProps: {
    className: 'border-none bg-transparent relative w-full',
    radius: 'md',
    shadow: '0',
  },
  // mantineProgressProps: ({ isTopToolbar }) => ({
  //   className: 'h-0.5',
  //   color: colors.primary['200'],
  //   sx: { display: isTopToolbar ? 'block' : 'none' },
  // }),
  mantineSkeletonProps: { width: '80%' },
  mantineTableBodyCellProps: {
    className: 'font-medium text-[#333]',
  },
  mantineTableBodyRowProps: {
    className: 'border-b border-[#DEE2E6] border-solid',
  },
  mantineTableHeadCellProps: { className: 'border-none text-gray-500' },
  mantineTableHeadRowProps: { className: 'bg-[#F7F7FB]' },

  paginationDisplayMode: 'pages',
  positionActionsColumn: 'last',
  renderEmptyRowsFallback: () => false,
  renderToolbarInternalActions: () => false,
};
