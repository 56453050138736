import { AxiosError, AxiosResponse } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IBranchPlant } from '@/types/IBranchPlant';

type IBranchPlantRequest = { workOrderMaterialId: number };

type IBranchPlantResponse = AxiosResponse<IBranchPlant[]>;

export function useBranchPlant(params: IBranchPlantRequest) {
  return useQuery<IBranchPlantResponse, AxiosError>(
    [queryKeys.branchPlant, params],
    () =>
      api.get(
        `/work-orders/api/demand-plant-data/${params.workOrderMaterialId}`
      ),
    { enabled: !!params.workOrderMaterialId }
  );
}
