import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { storageKeys } from '@consts/storageKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IMeasurementUnit } from '@/types/IMeasurementUnit';

type IMeasurementsResponse = IMeasurementUnit[];

//FIXME: make pageable response
export function useMeasurementUnits({
  ...params
}: IPageAbleRequest & {
  searchQuery?: string;
}) {
  return useQuery<IMeasurementsResponse, AxiosError>(
    [queryKeys.unitsOfMeasurements, params],
    () =>
      api
        .get('/materials/api/admin/units-of-measurement', { params })
        .then((res) => res.data),
    {
      enabled: !!localStorage.getItem(storageKeys.tokenAccess),
    }
  );
}
