import { FC, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SidebarInterface, sidebars } from '@consts/sidebars';
import { PageMetaContext } from '@context/PageMetaContext';
import { UserContext } from '@context/UserContext';
import { AppShell, NavLink, Stack } from '@mantine/core';
import { DungaOperatingLogo, DungaLogo } from '@assets/icons';
import { useLogout } from '@api/auth/useLogout';

function NavLinkWrapper({
  children,
  isLink,
  link,
}: {
  children: JSX.Element;
  isLink: boolean;
  link: string;
}) {
  if (isLink) {
    return <Link to={link}>{children}</Link>;
  }
  return children;
}

export function SidebarNavLink({
  item,
  openMenuKeys,
  parentKey,
  parentLink,
  selectedMenuKeys,
  opened,
}: {
  item: SidebarInterface;
  openMenuKeys?: string[];
  parentKey?: string;
  parentLink?: string;
  selectedMenuKeys?: string[];
  opened: boolean;
}) {
  const currentKey = parentKey ? `${parentKey}-${item.key}` : item.key;
  const currentLink = parentLink ? `${parentLink}/${item.link}` : item.link;
  const isActive = selectedMenuKeys?.includes(currentKey);
  const isOpen = openMenuKeys?.includes(currentKey);
  const Icon = item.icon;

  return (
    <NavLinkWrapper isLink={!item.children?.length} link={currentLink || ''}>
      <NavLink
        active={isActive}
        childrenOffset={0}
        className={`${isActive ? 'active' : isOpen ? 'open' : ''} ${
          opened ? 'p-4' : 'px-6 py-3'
        }`}
        defaultOpened={isOpen}
        key={currentKey}
        label={opened ? undefined : item.title}
        leftSection={Icon ? <Icon /> : undefined}
      >
        {item.children?.map((subItem) => (
          <SidebarNavLink
            opened={opened}
            item={subItem}
            key={`subItem-${currentKey}-${subItem.key}`}
            openMenuKeys={openMenuKeys}
            parentKey={currentKey}
            parentLink={currentLink}
            selectedMenuKeys={selectedMenuKeys}
          />
        ))}
      </NavLink>
    </NavLinkWrapper>
  );
}

export const SideNavbar: FC<{ opened: boolean }> = ({ opened }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const { userMe } = useContext(UserContext);
  const { logout } = useLogout();
  const { openMenuKeys, selectedMenuKeys } = useContext(PageMetaContext);

  const links = useMemo(
    () =>
      sidebars()
        .filter((item) =>
          item.permissions.includes(userMe?.role.toLowerCase() ?? '')
        )
        .map((item) => (
          <SidebarNavLink
            opened={opened}
            item={item}
            key={item.key}
            openMenuKeys={openMenuKeys}
            selectedMenuKeys={selectedMenuKeys}
          />
        )),
    [language, openMenuKeys, selectedMenuKeys, userMe]
  );

  return (
    <>
      <AppShell.Section>
        <Link to="/">
          {!opened ? (
            <DungaOperatingLogo width={245} height={40} />
          ) : (
            <DungaLogo height="auto" width={80} />
          )}
        </Link>
      </AppShell.Section>
      <AppShell.Section grow my="md">
        <Stack>{links}</Stack>
      </AppShell.Section>
    </>
  );
};
