import { useTranslation } from 'react-i18next';

import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { ActionIcon, Table } from '@mantine/core';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { IStockRequestMaterial } from '@/types/IStockRequest';
import { NumberTag } from '@components/atoms/NumberTag';
import { IconEdit } from '@tabler/icons-react';
import { UserContext } from '@context/UserContext';
import { useContext } from 'react';
import { SarContext } from '@context/SarContext';

export default function StockMaterialCard({
  material,
}: {
  material: IStockRequestMaterial;
}) {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    ISARPageActions
  >(sarFilters, sarPageActions);
  const { userMe } = useContext(UserContext);
  const { sarStatus } = useContext(SarContext);

  const rows = material.sarAttributes.map((attribute) => (
    <Table.Tr
      className="!border-b !border-b-1 border-b-[#DEE2E6]"
      key={attribute.id}
    >
      <Table.Td>{attribute.key}</Table.Td>
      <Table.Td>{attribute.value}</Table.Td>
      {attribute.uom && <Table.Td>{attribute.uom.shortName}</Table.Td>}
    </Table.Tr>
  ));

  return (
    <div className="bg-white p-6 rounded-xl mb-6">
      <div className="flex flex-row justify-between mb-8">
        <NumberTag id={material.id} />
        <span className="normal-case text-lg font-bold lg:mb-0">
          Noun: {material.noun.noun}
        </span>
        <span className="normal-case text-lg font-bold lg:mb-0">
          Modifier: {material.modifier.modifier}
        </span>
        {(userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
          sarStatus == 'AWAITING_ENG_REVIEW') ||
        (userMe?.role == 'USER' && sarStatus == 'DRAFT') ||
        (userMe?.role == 'USER' && sarStatus == 'AWAITING_REQUISITIONER') ||
        (userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
          sarStatus == 'AWAITING_MDM') ||
        (userMe?.role == 'QA_QC_REVIEWER' && sarStatus == 'AWAITING_QA_QC') ? (
          <ActionIcon
            aria-label="Edit Material"
            onClick={() => {
              setPartialActioned({
                action: 'editMaterial',
                actionId: material.id.toString() ?? '',
              });
            }}
            variant="transparent"
          >
            <IconEdit width={20} height={20} />
          </ActionIcon>
        ) : (
          <></>
        )}
      </div>
      <div className="lg:items-center">
        <Table className="bg-[#F8F9FA]">
          <Table.Thead>
            <Table.Tr className="bg-[#F8F9FA] border-y border-y-[#DEE2E6]">
              <Table.Th>Label</Table.Th>
              <Table.Th>Value</Table.Th>
              <Table.Th>Unit of Measurement</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </div>
    </div>
  );
}
