import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

type IStockRequestDeleteRequest = { id: number };

type IStockRequestDeleteResponse = AxiosResponse<void>;

export function useStockRequestDelete() {
  const queryClient = useQueryClient();

  return useMutation<
    IStockRequestDeleteResponse,
    AxiosError,
    IStockRequestDeleteRequest
  >(({ id }) => api.delete(`/materials/api/sars/delete/${id}`), {
    onError: () => {
      notify('error', t('messages.error'));
    },
    onSuccess: () => {
      queryClient.invalidateQueries([queryKeys.stockRequests]);
      notify('success', 'Stock Request was successfully deleted.');
    },
  });
}
