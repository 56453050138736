import { FC, useContext, useState } from 'react';

import { t } from 'i18next';

import { Button, Checkbox } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

import { FilterRender } from '@/types/common/IFilters';
import { SarContext } from '@context/SarContext';
import {
  IEnumSarStatus,
  IEnumSarStatusKeys,
} from '@consts/enums/IEnumStatuses';

interface SarStatusFilterInterface {
  filterRender?: FilterRender;
}

export const SarStatusFilter: FC<SarStatusFilterInterface> = ({
  filterRender,
}) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const { sarTab } = useContext(SarContext);

  const key = queries?.[0]?.key || dataIndex;
  const [value, setValue] = useState(filtered[key]);
  const onCheckboxSubmit = (clear = false) =>
    onFilter({ [key]: clear ? undefined : value });

  const options =
    sarTab != 'action'
      ? Object.keys(IEnumSarStatus).map((value) => ({
          label: t(`statuses.${value as IEnumSarStatusKeys}`),
          value,
        }))
      : Object.keys(IEnumSarStatus)
          .map((value) => ({
            label: t(`statuses.${value as IEnumSarStatusKeys}`),
            value,
          }))
          .filter((status) => status.value != 'DRAFT');

  return (
    <div className="space-y-3 bg-white">
      <Checkbox.Group
        classNames={{ label: 'p-1' }}
        defaultValue={value}
        onChange={(checkedValue) => {
          setValue(checkedValue);
        }}
      >
        {options.map((option) => (
          <Checkbox
            classNames={{ inner: 'm-2', label: 'p-2' }}
            key={`radio-${option.value}`}
            label={option.label}
            value={option.value.toString()}
          />
        ))}
      </Checkbox.Group>
      <div className="grid grid-cols-2 gap-2 mt-2">
        <Button
          className="h-auto p-1"
          leftSection={<IconSearch size={14} />}
          onClick={() => onCheckboxSubmit()}
          size="small"
          variant="primary"
        >
          {t(`search`)}
        </Button>
        <Button
          className="h-auto p-1"
          onClick={() => {
            onCheckboxSubmit(true);
          }}
          size="small"
          variant="outline"
        >
          {t(`clear`)}
        </Button>
      </div>
    </div>
  );
};
