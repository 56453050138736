import { IPageActions } from '@/types/common/IPageActions';

enum IEnumModifiersPageActions {
  'view',
}

export type IModifiersPageActions = {
  action: keyof typeof IEnumModifiersPageActions;
  actionId: string;
};

export const modifierPageActions: IPageActions<IModifiersPageActions> = {
  action: {
    allowed: (value) => Object.keys(IEnumModifiersPageActions).includes(value),
    default: '',
  },
  actionId: { default: '' },
};
