import { useContext, type FC } from 'react';

import { Button, Modal } from '@mantine/core';
import { useStockRequestAction } from '@api/sar/useStockRequestAction';
import { notify } from '@utils/notify';
import { UserContext } from '@context/UserContext';

type ApproveRequestModalProps = {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
};

export const ApproveRequestModal: FC<ApproveRequestModalProps> = ({
  id = '',
  isOpen,
  onClose,
}) => {
  const approveStockRequest = useStockRequestAction();
  const { userMe } = useContext(UserContext);

  const handleApproveRequest = () => {
    approveStockRequest
      .mutateAsync({
        id: +id,
        status:
          userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER'
            ? 'COMPLETE'
            : 'APPROVE',
        reason: '',
      })
      .then(() => {
        notify(
          'success',
          `The stock authorization request was successfully approved.`
        );
        onClose();
      });
  };

  return (
    <Modal
      centered
      onClose={onClose}
      opened={isOpen}
      size={776}
      title={`Are you sure you want to approve this stock authorization request ${
        userMe?.role == 'QA_QC_REVIEWER' ? 'without certificates' : ''
      }?`}
    >
      <div className="flex justify-center gap-4 mt-8">
        <Button onClick={onClose} variant="outline" w={200}>
          No
        </Button>
        <Button
          loading={approveStockRequest.isLoading}
          onClick={handleApproveRequest}
          type="submit"
          w={200}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};
