import { AxiosError } from 'axios';

import { queryKeys } from '@consts/queryKeys';
import { useQuery } from '@tanstack/react-query';
import { api } from '@utils/index';

import { IMeasurementUnit } from '@/types/IMeasurementUnit';

type IMaterialRequest = { id: string };

export function useMeasurementUnitById(params: IMaterialRequest) {
  return useQuery<IMeasurementUnit, AxiosError>(
    [queryKeys.unitsOfMeasurement, params],
    () =>
      api
        .get(`/materials/api/admin/units-of-measurement/${params.id}`)
        .then((res) => res.data)
  );
}
