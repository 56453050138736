import { Route, Routes } from 'react-router-dom';

import AttributesPage from '@modules/attributes/AttributesPage';
import ModifiersPage from '@modules/modifiers/ModifiersPage';

import NounsPage from './NounsPage';

export const NounsRouter = () => {
  return (
    <Routes>
      <Route element={<NounsPage />} index />
      <Route element={<ModifiersPage />} path="/:id/modifiers" />
      <Route
        element={<AttributesPage />}
        path="/:nounId/modifiers/:modifierId/attributes"
      />
    </Routes>
  );
};
