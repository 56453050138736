import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { t } from 'i18next';

import { EditOutlined } from '@ant-design/icons';
import { useStockRequests } from '@api/sar/useStockRequests';
import { QueryTable } from '@components/organisms/QueryTable';
import { PageMeta } from '@context/PageMetaContext';
import { SarContext } from '@context/SarContext';
import { UserContext } from '@context/UserContext';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { Button, Chip, CloseButton, Group, Input, Title } from '@mantine/core';
import { sarColumns } from '@modules/sar/consts/columns/sarColumns';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';

import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import { sarKeys } from '@/types/IStockRequest';
import { RejectRequestModal } from '../organisms/modals/RejectRequestModal';
import { CancelRequestModal } from '../organisms/modals/CancelRequestModal';
import { ConfirmDeleteRequestModal } from '../organisms/modals/ConfirmDeleteRequest';
import { IconTrash } from '@tabler/icons-react';
import { ApproveRequestModal } from '../organisms/modals/ApproveRequestModal';

const StockRequestsPage: FC = () => {
  const navigate = useNavigate();
  const { userMe } = useContext(UserContext);
  const {
    onChangeSarId,
    onChangeSarIdList,
    onChangeSarNumbersList,
    onChangeSarTab,
  } = useContext(SarContext);

  const [value, setValue] = useState(userMe?.role == 'ADMIN' ? 'all' : 'my');
  const [searchQuery, setSearchQuery] = useState('');

  const { filtered, onFilter, setPartialActioned, actioned, clearParams } =
    useUrlFilteredActioned<IPageAbleRequest, ISARPageActions>(
      sarFilters,
      sarPageActions
    );

  const sarQuery = useStockRequests({
    ...filtered,
    searchQuery,
    actionRequired: value == 'all' ? null : value != 'my',
  });
  const sars = sarQuery.data?.sars.content;

  useEffect(() => {
    if (value == 'all') {
      onFilter({
        [sarKeys.status]: undefined,
        [sarKeys.createdBy]: undefined,
      });
    } else if (value == 'my') {
      onFilter({
        [sarKeys.createdBy]: userMe?.id,
        [sarKeys.status]: undefined,
      });
    } else if (value == 'action') {
      onFilter({
        [sarKeys.createdBy]: undefined,
      });
    }
  }, [value]);

  useEffect(() => {
    if (sars?.length != 0) {
      const sarIds = sars?.map((sar) => sar.id.toString());
      const sarNumbersList = sars
        ?.map((sar) => sar.materialNumbers?.map((number) => number) ?? [])
        .flat();
      onChangeSarIdList(sarIds ?? []);
      onChangeSarNumbersList(sarNumbersList ?? []);
    }
  }, [sars]);

  const mySarsCount = sarQuery.data?.mySarsCount;
  const allSarsCount = sarQuery.data?.allSarsCount;
  const actionRequiredSarsCount = sarQuery.data?.actionRequiredSarsCount;
  const reviewersSarsCount = sarQuery.data?.reviewersSarsCount;

  const handleTabChange = (value: string) => {
    setValue(value);
    onChangeSarTab(value);
  };

  return (
    <div className="py-[10px]">
      <PageMeta
        breadcrumbs={[{ link: '/sar', title: 'Stock Authorization Requests' }]}
        selectedMenuKeys={['sar']}
        title="Stock Authorization Requests"
      />
      <div className="flex justify-between mb-6 items-center">
        <Title order={3}>Stock Authorization Request</Title>
        <Button onClick={() => navigate('/sar/create')}>
          Create new request
        </Button>
      </div>

      <div className="bg-white p-5 rounded-xl">
        <div className="flex justify-between items-end">
          <Chip.Group
            multiple={false}
            onChange={(value) => handleTabChange(value)}
            value={value}
          >
            <Group className="sar-filter-tabs gap-0 mb-5" justify="start">
              {userMe?.role !== 'ADMIN' && (
                <Chip value="my" variant="outline">
                  {t('sar.myRequests')}
                  <span className="min-w-[30px] ml-2 text-center bg-[#F2F4F7] px-1 rounded-xl">
                    {mySarsCount || 0}
                  </span>
                </Chip>
              )}
              {userMe?.role !== 'ADMIN' && (
                <Chip value="action" variant="outline">
                  {t('sar.actionRequired')}
                  <span className="min-w-[30px] ml-2 text-center bg-[#F2F4F7] px-1 rounded-xl">
                    {actionRequiredSarsCount || 0} / {reviewersSarsCount || 0}
                  </span>
                </Chip>
              )}
              {!(
                userMe?.role == 'DATA_CHAMPION_REVIEWER' ||
                userMe?.role == 'QA_QC_REVIEWER'
              ) && (
                <Chip value="all" variant="outline">
                  {t('sar.allRequests')}
                  <span className="min-w-[30px] ml-2 text-center bg-[#F2F4F7] px-1 rounded-xl">
                    {allSarsCount || 0}
                  </span>
                </Chip>
              )}
            </Group>
          </Chip.Group>
          <div>
            <Input
              className="mb-5 !mt-0 w-[280px]"
              mt="md"
              onChange={(event) => setSearchQuery(event.currentTarget.value)}
              placeholder="Search by SAR name"
              rightSection={
                <CloseButton
                  aria-label="Clear input"
                  onClick={() => setSearchQuery('')}
                  style={{ display: searchQuery ? undefined : 'none' }}
                />
              }
              rightSectionPointerEvents="all"
              value={searchQuery}
            />
          </div>
        </div>
        <div className="overflow-x-scroll">
          <QueryTable
            actions={(row) => [
              ...((row.status == 'DRAFT' ||
                row.status == 'AWAITING_REQUISITIONER') &&
              row.createdBy == userMe?.id
                ? [
                    {
                      icon: <EditOutlined />,
                      onClick: () => {
                        navigate(`/sar/details/${row.id}`);
                        onChangeSarId(row.id);
                      },
                      title: 'Edit request',
                    },
                  ]
                : []),
              ...(row.status == 'DRAFT' && row.createdBy == userMe?.id
                ? [
                    {
                      icon: <IconTrash />,
                      onClick: () => {
                        setPartialActioned({
                          action: 'delete',
                          actionId: row.id.toString() ?? '',
                        });
                      },
                      title: 'Delete request',
                    },
                  ]
                : []),
              ...(userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
              row.status == 'AWAITING_ENG_REVIEW' &&
              row.dataChampionReviewer == userMe.id
                ? [
                    {
                      icon: <EditOutlined />,
                      onClick: () => {
                        setPartialActioned({
                          action: 'approve',
                          actionId: row.id.toString() ?? '',
                        });
                      },
                      title: 'Approve',
                    },
                    {
                      icon: <EditOutlined />,
                      onClick: () => {
                        setPartialActioned({
                          action: 'reject',
                          actionId: row.id.toString() ?? '',
                        });
                      },
                      title: 'Reject',
                    },
                    {
                      icon: <EditOutlined />,
                      onClick: () => {
                        setPartialActioned({
                          action: 'cancel',
                          actionId: row.id.toString() ?? '',
                        });
                      },
                      title: 'Cancel',
                    },
                  ]
                : []),
              ...(userMe?.role == 'QA_QC_REVIEWER' &&
              row.status == 'AWAITING_QA_QC' &&
              row.qaQcReviewer == userMe.id
                ? [
                    {
                      icon: <EditOutlined />,
                      onClick: () => {
                        setPartialActioned({
                          action: 'approve',
                          actionId: row.id.toString() ?? '',
                        });
                      },
                      title: 'Approve',
                    },
                    {
                      icon: <EditOutlined />,
                      onClick: () => {
                        setPartialActioned({
                          action: 'reject',
                          actionId: row.id.toString() ?? '',
                        });
                      },
                      title: 'Reject',
                    },
                    {
                      icon: <EditOutlined />,
                      onClick: () => {
                        setPartialActioned({
                          action: 'cancel',
                          actionId: row.id.toString() ?? '',
                        });
                      },
                      title: 'Cancel',
                    },
                  ]
                : []),
            ]}
            columns={sarColumns}
            empty={{
              description:
                'No stock authorization requests were created by this user or no request matches your search or filtering criteria',
              title: 'No result',
            }}
            data={sarQuery.data?.sars}
            query={sarQuery}
            tableFilter={{ filtered, filters: sarFilters, onFilter }}
          />
        </div>
      </div>

      <RejectRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'reject'}
        onClose={clearParams}
      />

      <CancelRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'cancel'}
        onClose={clearParams}
      />

      <ConfirmDeleteRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'delete'}
        onClose={clearParams}
      />

      <ApproveRequestModal
        id={actioned.actionId}
        isOpen={actioned.action === 'approve'}
        onClose={clearParams}
      />
    </div>
  );
};

export default StockRequestsPage;
