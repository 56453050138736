export const queryKeys = {
  attributes: 'attributes',
  basket: 'basket',
  orders: 'orders',
  dicionaries: 'dicionaries',
  filterAttributes: 'filterAttributes',
  filterModifiers: 'filterModifiers',
  filterNouns: 'filterNouns',
  material: 'material',
  materials: 'materials',
  orderMaterials: 'orderMaterials',
  ordersMaterials: 'ordersMaterials',
  modifiers: 'modifiers',
  branchPlant: 'branchPlant',
  stockRequestCanMakeAction: 'stockRequestCanMakeAction',
  nouns: 'nouns',
  certificates: 'certificates',
  stockRequestsHistory: 'stockRequestsHistory',
  stockMaterial: 'stockMaterial',
  stockMaterialInfo: 'stockMaterialInfo',
  stockMaterials: 'stockMaterials',
  stockMaterialsPaged: 'stockMaterialsPaged',
  stockMaterialsInfo: 'stockMaterialsInfo',
  stockRequests: 'stockRequests',
  unitsOfMeasurement: 'unitsOfMeasurement',
  unitsOfMeasurements: 'unitsOfMeasurements',
  user: 'user',
  userActivate: 'userActivate',
  userBlock: 'userBlock',
  userDemote: 'userDemote',
  userPromote: 'userPromote',
  users: 'users',
  stockRequest: 'stockRequest',
};
