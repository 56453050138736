import { DataChampionsSelectMultipleFilter } from '@components/molecules/filters/dynamic/DataChampionsSelectMultipleFilter';
import { MaterialControllersSelectMultipleFilter } from '@components/molecules/filters/dynamic/MaterialControllersSelectMultipleFilter';
import { MaterialNumberSelectFilter } from '@components/molecules/filters/dynamic/MaterialNumberSelectFilter';
import { QaReviewersSelectMultipleFilter } from '@components/molecules/filters/dynamic/QaReviewersSelectMultipleFilter';
import { SarIdSelectFilter } from '@components/molecules/filters/dynamic/SarIdSelectFilter';
import { UsersSelectMultipleFilter } from '@components/molecules/filters/dynamic/UsersSelectMultipleFilter';
import { DateFilter } from '@components/molecules/filters/static/DateFilter';

import { paginationBaseFilters } from '@consts/paginationBaseFilters';

import { IFilters } from '@/types/common/IFilters';
import { sarKeys } from '@/types/IStockRequest';
import { SarStatusFilter } from '@components/molecules/filters/dynamic/SarStatusFilter';

export const sarFilters: IFilters = {
  ...paginationBaseFilters,
  [sarKeys.id]: {
    input: <SarIdSelectFilter />,
    sort: true,
  },
  [sarKeys.sarName]: {
    sort: true,
  },
  [sarKeys.materialNumbers]: {
    input: <MaterialNumberSelectFilter />,
  },
  [sarKeys.status]: {
    input: <SarStatusFilter />,
    sort: true,
  },
  [sarKeys.requisitioner]: {
    input: <UsersSelectMultipleFilter />,
    sort: true,
  },
  [sarKeys.dataChampionReviewer]: {
    input: <DataChampionsSelectMultipleFilter />,
    sort: true,
  },
  [sarKeys.qaQcReviewer]: {
    input: <QaReviewersSelectMultipleFilter />,
    sort: true,
  },
  [sarKeys.materialControllerReviewer]: {
    input: <MaterialControllersSelectMultipleFilter />,
    sort: true,
  },
  [sarKeys.createdAt]: {
    input: <DateFilter />,
    sort: true,
  },
  [sarKeys.updatedAt]: {
    input: <DateFilter />,
    sort: true,
  },
};
