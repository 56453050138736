import { Route, Routes } from 'react-router-dom';

import BasketPage from './pages/BasketPage';

export const BasketRouter = () => {
  return (
    <Routes>
      <Route element={<BasketPage />} index />
    </Routes>
  );
};
