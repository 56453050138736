export enum PermissionsEnum {
  ADMIN = 'ADMIN',
  DATA_CHAMPION_REVIEWER = 'DATA_CHAMPION_REVIEWER',
  MATERIAL_CONTROLLER_REVIEWER = 'MATERIAL_CONTROLLER_REVIEWER',
  QA_QC_REVIEWER = 'QA_QC_REVIEWER',
  USER = 'USER',
}

export const getUserPermissions = [
  // {
  //   label: 'Admin',
  //   value: PermissionsEnum.ADMIN,
  // },
  {
    label: 'Data Champion',
    value: PermissionsEnum.DATA_CHAMPION_REVIEWER,
  },
  {
    label: 'User',
    value: PermissionsEnum.USER,
  },
  {
    label: 'QA/QC Reviewer',
    value: PermissionsEnum.QA_QC_REVIEWER,
  },
  {
    label: 'Material Controller Reviewer',
    value: PermissionsEnum.MATERIAL_CONTROLLER_REVIEWER,
  },
];
