import { type FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { t } from 'i18next';

import { PlusOutlined } from '@ant-design/icons';
import { useAttributes } from '@api/attributes/useAttributes';
import { useFilterModifiers } from '@api/filters/useFilterModifiers';
import { useMeasurementUnits } from '@api/measurements/useMeasurementUnits';
import {
  IAdditionalAttribute,
  IStockMaterialAddRequest,
  stockMaterialAddInitial,
  stockMaterialAddValidation,
  useAddStockMaterial,
} from '@api/sar-materials/useAddStockMaterial';
import { SarContext } from '@context/SarContext';
import {
  ActionIcon,
  Button,
  Checkbox,
  Group,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { IconTrash } from '@tabler/icons-react';
import { notify } from '@utils/notify';

import { IAttribute } from '@/types/IAttribute';

type IAddStockMaterialForm = {
  nouns: any[];
  nextStep: () => void;
};
export const AddStockMaterialForm: FC<IAddStockMaterialForm> = ({
  nouns,
  nextStep,
}) => {
  const location = useLocation();
  const { onChangeSarMaterialId } = useContext(SarContext);
  const { id } = useParams();
  const isEditPage = location.pathname.includes('edit');
  const { sarId } = useContext(SarContext);
  const [inputs, setInputs] = useState<IAdditionalAttribute[]>([
    {
      isUomRequired: false,
      key: '',
      modifierId: null,
      nounId: null,
      uom: null,
      value: '',
    },
  ]);

  const handleAddInput = () => {
    setInputs([
      ...inputs,
      {
        isUomRequired: false,
        key: '',
        modifierId: null,
        nounId: null,
        uom: null,
        value: '',
      },
    ]);
  };

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  const requestMaterialAdd = useAddStockMaterial();

  const form = useForm<IStockMaterialAddRequest>({
    initialValues: {
      ...stockMaterialAddInitial,
      sarId: isEditPage ? Number(id) : Number(sarId),
    },
    transformValues: (values) => ({
      modifier: {
        id: Number(values.modifier.id),
      },
      noun: {
        id: Number(values.noun.id),
      },
      sarAttributes:
        values.sarAttributes.concat(inputs).map((input) => ({
          isUomRequired: input.isUomRequired ?? false,
          key: input.key,
          modifierId: Number(form.values.modifier.id),
          nounId: Number(form.values.noun.id),
          uom:
            input.uom !== null
              ? {
                  id: Number(input.uom.id),
                }
              : null,
          value: input.value,
        })) ?? [],
      sarId: isEditPage ? Number(id) : Number(sarId),
    }),
    validate: yupResolver(stockMaterialAddValidation),
  });

  //GET Modifiers
  const modifiersQuery = useFilterModifiers({
    id: form.values.noun.id?.toString() as string,
  });

  const modifiers = useMemo(() => {
    form.setFieldValue('modifier.id', null);
    const obj = modifiersQuery.data;
    return obj
      ? Object.keys(obj)
          .map((key) => ({ key, value: obj[key] }))
          .sort((a, b) => (a.value > b.value ? 1 : b.value > a.value ? -1 : 0))
      : [];
  }, [modifiersQuery.data]);

  //GET Attributes
  const attributesQuery = useAttributes({
    modifierId: form.values.modifier.id?.toString() as string,
    nounId: form.values.noun.id?.toString() as string,
  });
  const attributes: IAttribute[] = attributesQuery.data ?? [];

  //GET UoMs
  const measurementsQuery = useMeasurementUnits({
    page: 0,
    size: 100,
  });
  const measurements = measurementsQuery.data;

  const handleClose = () => {
    form.reset();
    setInputs([
      {
        isUomRequired: false,
        key: '',
        modifierId: null,
        nounId: null,
        uom: null,
        value: '',
      },
    ]);
  };

  const handleChange = (event, index, isUomSelect: boolean) => {
    const onChangeValue = [...inputs];
    if (!isUomSelect) {
      const { name, value } = event.target;
      const { checked } = event.currentTarget;
      if (name == 'isUomRequired') {
        onChangeValue[index][name] = checked;
      } else {
        onChangeValue[index][name] = value;
      }
    } else {
      onChangeValue[index]['uom'] = {
        id: event.value != null ? event.value : 0,
      };
    }
    setInputs(onChangeValue);
  };

  useEffect(() => {
    if (attributes.length != 0) {
      form.setFieldValue('sarAttributes', attributes);
    }
  }, [attributes]);

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        requestMaterialAdd
          .mutateAsync({
            ...values,
          })
          .then((result) => {
            onChangeSarMaterialId(result.data);
            notify('success', 'Material was successfully added!');
            nextStep();
          })
          .catch(() => {
            notify('error', t('tryAgainLater'));
          });
      })}
    >
      <div>
        <div className="input_container">
          <div className="grid gap-4 grid-cols-2 items-end mb-6">
            <Select
              className="mt-0"
              comboboxProps={{ withinPortal: false }}
              data={(nouns ?? [])?.map((noun) => ({
                label: noun.value,
                value: noun.key,
              }))}
              label="Noun"
              placeholder="Noun"
              searchable
              withAsterisk
              {...form.getInputProps('noun.id')}
            />
            <Select
              className="mt-0"
              comboboxProps={{ withinPortal: false }}
              data={(modifiers ?? [])?.map((modifier) => ({
                label: modifier.value,
                value: modifier.key,
              }))}
              disabled={modifiersQuery.isLoading}
              label="Modifier"
              nothingFoundMessage="Nothing found..."
              placeholder="Modifier"
              withAsterisk
              {...form.getInputProps('modifier.id')}
            />
          </div>

          <span className="text-[#111827] font-semibold text-sm">
            Material attributes
          </span>
          <div className="bg-[#F8F9FA] border border-[#CED4DA] border-1 rounded-xl py-8 px-6">
            {/* ATTRIBUTES CHANGE */}
            {attributesQuery.isLoading ? (
              <div className="text-center">
                <span className="text-gray-400  font-medium">
                  Please choose noun & modifier
                </span>
              </div>
            ) : (
              attributes.map((attribute, attrIndex) => (
                <div className="grid grid-cols-2 gap-2 " key={attrIndex}>
                  <div className="flex items-center">
                    <span className="mr-5 text-[#9CA3AF]">
                      {attrIndex + 1}.
                    </span>
                    <TextInput
                      className="mb-5 !mt-0 w-[250px]"
                      label={attribute.key}
                      mt="md"
                      placeholder={attribute.key}
                      withAsterisk
                      {...form.getInputProps(
                        `sarAttributes.${attrIndex}.value`
                      )}
                    />
                  </div>
                  {attribute.isUomRequired && attribute.uom && (
                    <Select
                      className="mt-0 w-[130px]"
                      comboboxProps={{ withinPortal: false }}
                      data={
                        measurements?.map((measurement) => ({
                          label: measurement.shortName,
                          value: measurement.id.toString(),
                        })) ?? []
                      }
                      disabled={measurementsQuery.isLoading}
                      label="Unit of Measurement"
                      nothingFoundMessage="Nothing found..."
                      placeholder="%IN"
                      readOnly={false}
                      width={120}
                      withAsterisk
                      {...form.getInputProps(
                        `sarAttributes.${attrIndex}.uom.id`
                      )}
                    />
                  )}
                </div>
              ))
            )}

            {/* ADDITIONAL ATTRIBUTES CHANGE */}
            <span className="text-[#111827] font-semibold text-sm">
              New attributes
            </span>
            {attributesQuery.isFetched &&
              measurementsQuery.isFetched &&
              inputs.map((item, index) => (
                <div key={index}>
                  <div className="grid grid-cols-4 gap-4 items-end">
                    <div className="flex items-center">
                      <span className="mr-5 text-[#9CA3AF]">{index + 1}.</span>
                      <TextInput
                        className="mb-4 !mt-0"
                        label="Label"
                        mt="md"
                        name="key"
                        onChange={(event) => handleChange(event, index, false)}
                        placeholder="Label"
                        required
                        value={item.key}
                        withAsterisk
                      />
                    </div>
                    <TextInput
                      className="mb-4 !mt-0"
                      label="Value"
                      mt="md"
                      name="value"
                      onChange={(event) => handleChange(event, index, false)}
                      placeholder="Value"
                      required
                      value={item.value}
                      withAsterisk
                    />
                    <Group className="mb-4">
                      {item.isUomRequired !== null && (
                        <Checkbox
                          checked={item.isUomRequired}
                          label="Unit of Measurement"
                          name="isUomRequired"
                          onChange={(event) =>
                            handleChange(event, index, false)
                          }
                        />
                      )}
                      <Select
                        className="mt-0"
                        comboboxProps={{ withinPortal: false }}
                        data={
                          measurements?.map((measurement) => ({
                            label: measurement.shortName,
                            name: 'uom',
                            value: measurement.id.toString(),
                          })) ?? []
                        }
                        disabled={
                          measurementsQuery.isLoading || !item.isUomRequired
                        }
                        name="uom"
                        nothingFoundMessage="Nothing found..."
                        onChange={(_vlue, option) =>
                          handleChange(option, index, true)
                        }
                        placeholder="%IN"
                        required
                        value={item.uom != null ? item.uom.id?.toString() : ''}
                        width={120}
                      />
                    </Group>
                    {inputs.length > 1 && (
                      <ActionIcon
                        aria-label="deleteAttribute"
                        onClick={() => handleDeleteInput(index)}
                        variant="default"
                      >
                        <IconTrash
                          stroke={1.5}
                          style={{ height: '70%', width: '70%' }}
                        />
                      </ActionIcon>
                    )}
                  </div>
                  {index === inputs.length - 1 && (
                    <Button
                      className="w-full mt-4 text-[#228BE6] border-[#228BE6]"
                      disabled={attributesQuery.isLoading}
                      leftSection={<PlusOutlined className="w-4 h-4" />}
                      onClick={() => handleAddInput()}
                      variant="outline"
                    >
                      Add Attribute
                    </Button>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end mt-8">
        <Button className="mr-3" onClick={handleClose} variant="outline">
          {t('cancel')}
        </Button>
        <Button
          loading={requestMaterialAdd.isLoading}
          type="submit"
          variant="outlined"
        >
          {t('material.saveMaterial')}
        </Button>
      </div>
    </form>
  );
};
