import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isObject } from 'lodash';

import { Accordion, Text } from '@mantine/core';
import { calculateSum } from '@utils/calculateSum';

import { IMaterial } from '@/types/IMaterial';
import { NumberTag } from '@components/atoms/NumberTag';

type MaterialInfoProps = {
  material: IMaterial;
};

const MaterialInfo: FC<MaterialInfoProps> = ({ material }) => {
  const { t } = useTranslation();
  const branchPlant = useMemo(() => {
    return material.branchPlant
      ? Object.entries(material.branchPlant).map(([key, value]) => {
          const branchSum = Object.values(value).reduce(
            (a, b) => Number(a) + Number(b),
            0
          );

          return {
            items: isObject(value)
              ? Object.entries(value).map(([k, v]) => ({
                  key: k,
                  value: v.toString(),
                }))
              : [],
            key,
            value: isObject(value) ? branchSum : value,
          };
        })
      : null;
  }, [material]);
  const totalQuantity = branchPlant ? calculateSum(branchPlant, 'value') : 0;

  return (
    <div className="mt-6 bg-white py-8 px-5 rounded-xl">
      <div className="flex justify-between">
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {material.noun ?? '-'}
        </p>
        <NumberTag id={material.stockNumber} />
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
          {t('material.modifier')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {material.modifier ?? '-'}
        </p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
          {t('material.description1')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {material.description1 ?? '-'}
        </p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
          {t('material.description2')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {material.description2 ?? '-'}
        </p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4">
          {t('material.quantity')}
        </p>
        <p className="font-normal text-[#5F6574] mb-4 md:mb-6">
          {material.quantity ?? 0}
        </p>
      </div>
      <div>
        <p className="font-bold text-base text-[#5F6574] mb-2 leading-4 border-b-2 pb-3">
          {t('material.branchPlant')}
        </p>
        {/* <p className="font-normal text-[#5F6574] mb-4 md:mb-6"> */}
        <div>
          <div className="flex flex-col p-3">
            <Accordion>
              {branchPlant ? (
                branchPlant.map((item, key) => (
                  <div className="" key={key}>
                    <Accordion.Item
                      className="branchPlant-accordion"
                      key={item.key}
                      value={item.key as string}
                    >
                      {
                        <Accordion.Control>
                          <Text className="mr-4">{item.key}</Text>
                          <Text className="">{item.value}</Text>
                        </Accordion.Control>
                      }
                      {item.items.length != 0 ? (
                        item.items.map((branch, index) => (
                          <Accordion.Panel key={index}>
                            <Text className="mr-4">{branch.key}</Text>
                            <Text className="">{branch.value}</Text>
                          </Accordion.Panel>
                        ))
                      ) : (
                        <Accordion.Panel>
                          <div>No details found</div>
                        </Accordion.Panel>
                      )}
                    </Accordion.Item>
                  </div>
                ))
              ) : (
                <div>No details found</div>
              )}
            </Accordion>
          </div>
          <div className="grid grid-cols-2 p-3 bg-[#F1F3F9]">
            <Text className="font-bold">Total quantity</Text>{' '}
            <Text className="font-bold">{totalQuantity}</Text>
          </div>
        </div>
        {/* </p> */}
      </div>
    </div>
  );
};

export default MaterialInfo;
