import { paginationBaseFilters } from '@consts/paginationBaseFilters';

import { IFilters } from '@/types/common/IFilters';
import { modifierKeys } from '@/types/IModifier';

export const attributesFilters: IFilters = {
  ...paginationBaseFilters,
  [modifierKeys.modifier]: {
    sort: true,
  },
};
