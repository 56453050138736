import { FC, useRef, useState } from 'react';

import { t } from 'i18next';
import moment from 'moment';

import { SearchOutlined } from '@ant-design/icons';
import { Button } from '@mantine/core';
import { DatePicker, DatesRangeValue } from '@mantine/dates';

import { FilterRender } from '@/types/common/IFilters';

interface DateFilterInterface {
  filterRender?: FilterRender;
}

export const DateFilter: FC<DateFilterInterface> = ({ filterRender }) => {
  if (!filterRender) {
    return null;
  }

  const { dataIndex, filtered, onFilter, queries } = filterRender;

  const key = queries?.[0]?.key || dataIndex;
  const [dateValue, setDateValue] = useState<DatesRangeValue>(filtered[key]);
  const ref = useRef<HTMLButtonElement>(null);

  const onInputSubmit = (clear = false) => {
    setDateValue(dateValue);

    onFilter({
      [key]: clear
        ? undefined
        : dateValue.map((date, index) =>
            index == 0
              ? moment(date).format('yyyy-MM-DD HH:mm:ss')
              : moment(date).add('1', 'd').format('yyyy-MM-DD HH:mm:ss')
          ),
    });
  };

  return (
    <div className="space-y-3 bg-white max-w-tiny">
      <DatePicker
        onChange={(value) => {
          setDateValue(value);
          ref.current?.blur();
        }}
        type="range"
        value={dateValue}
      />
      <div className="grid grid-cols-2 gap-2 mt-2">
        <Button
          className="h-auto p-1"
          disabled={!dateValue || dateValue == filtered[key]}
          leftSection={<SearchOutlined />}
          onClick={() => onInputSubmit()}
          size="small"
          variant="primary"
        >
          {t(`search`)}
        </Button>
        <Button
          className="h-auto p-1"
          onClick={() => onInputSubmit(true)}
          size="small"
          variant="outline"
        >
          {t(`clear`)}
        </Button>
      </div>
    </div>
  );
};
