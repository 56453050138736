import { useTranslation } from 'react-i18next';

import {
  ActionIcon,
  CopyButton,
  rem,
  Tabs,
  Text,
  Tooltip,
} from '@mantine/core';
import { IMaterialInfo } from '@/types/IMaterialInfo';
import { IconCheck, IconCopy, IconEdit } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import { useContext } from 'react';
import { UserContext } from '@context/UserContext';
import { SarContext } from '@context/SarContext';

export default function StockMaterialInfoCard({
  materialInfo,
}: {
  materialInfo: IMaterialInfo;
}) {
  const { materialId } = useParams();
  const { userMe } = useContext(UserContext);
  const { sarStatus } = useContext(SarContext);

  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { setPartialActioned } = useUrlFilteredActioned<
    IPageAbleRequest,
    ISARPageActions
  >(sarFilters, sarPageActions);

  return (
    <div className="bg-white p-6 rounded-xl">
      <div className="lg:items-center">
        <Tabs defaultValue="general">
          <Tabs.List className="mb-5">
            <Tabs.Tab value="general">{t('materialInfo.general')}</Tabs.Tab>
            <Tabs.Tab value="purchasing">
              {t('materialInfo.purchasing')}
            </Tabs.Tab>
            <Tabs.Tab value="qualityManagement">
              {t('materialInfo.qualityManagement')}
            </Tabs.Tab>
            <Tabs.Tab value="accounting">
              {t('materialInfo.accounting')}
            </Tabs.Tab>
            <Tabs.Tab value="additional">
              {t('materialInfo.additional')}
            </Tabs.Tab>
            {(userMe?.role == 'DATA_CHAMPION_REVIEWER' &&
              sarStatus == 'AWAITING_ENG_REVIEW') ||
            (userMe?.role == 'USER' && sarStatus == 'DRAFT') ||
            (userMe?.role == 'USER' && sarStatus == 'AWAITING_REQUISITIONER') ||
            (userMe?.role == 'MATERIAL_CONTROLLER_REVIEWER' &&
              sarStatus == 'AWAITING_MDM') ||
            (userMe?.role == 'QA_QC_REVIEWER' &&
              sarStatus == 'AWAITING_QA_QC') ? (
              <ActionIcon
                aria-label="Edit Material"
                onClick={() => {
                  setPartialActioned({
                    action: 'editMaterialInfo',
                    actionId: materialId?.toString() ?? '',
                  });
                }}
                variant="transparent"
              >
                <IconEdit width={20} height={20} />
              </ActionIcon>
            ) : (
              <></>
            )}
          </Tabs.List>

          <Tabs.Panel value="general">
            <div>
              <div className="flex flex-col my-3">
                <Text>{t('materialInfo.materialDescription')}:</Text>
                <div className="flex">
                  <Text className="mr-6">
                    {materialInfo.materialDescription}
                  </Text>
                  <CopyButton
                    value={materialInfo.materialDescription ?? ''}
                    timeout={2000}
                  >
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? 'Copied' : 'Copy'}
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </div>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.materialNumber')}:</Text>
                <Text>{materialInfo.materialNumber ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.baseUnitOfMeasure')}:</Text>
                <Text>{materialInfo.baseUnitOfMeasure ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.eanUpc')}:</Text>
                <Text>{materialInfo.eanUpc ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.materialGroup')}:</Text>
                <Text>{materialInfo.materialGroup ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.genItemCatGroup')}:</Text>
                <Text>{materialInfo.genItemCatGroup ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.xplantMaterialStatus')}:</Text>
                <Text>{materialInfo.xplantMaterialStatus ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.basicData')}:</Text>
                <div className="flex">
                  <Text className="mr-6">{materialInfo.basicData}</Text>
                  {/* FIXME: make a component */}
                  <CopyButton
                    value={materialInfo.basicData ?? ''}
                    timeout={2000}
                  >
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? 'Copied' : 'Copy'}
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </div>
              </div>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="purchasing">
            <div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.manufacturer')}:</Text>
                <Text>{materialInfo.manufacturer ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.manufacturingPartNumber')}:</Text>
                <Text>{materialInfo.manufacturingPartNumber ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.purchasingValueKey')}:</Text>
                <Text>{materialInfo.purchasingValueKey ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.validFrom')}:</Text>
                <Text>{materialInfo.validFrom ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.purchaseOrderText')}:</Text>
                <div className="flex">
                  <Text>{materialInfo.purchaseOrderText ?? '-'}</Text>
                  <CopyButton
                    value={materialInfo.purchaseOrderText ?? ''}
                    timeout={2000}
                  >
                    {({ copied, copy }) => (
                      <Tooltip
                        label={copied ? 'Copied' : 'Copy'}
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? 'teal' : 'gray'}
                          variant="subtle"
                          onClick={copy}
                        >
                          {copied ? (
                            <IconCheck style={{ width: rem(16) }} />
                          ) : (
                            <IconCopy style={{ width: rem(16) }} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                </div>
              </div>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="qualityManagement">
            <div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.inspectionSetupIndicator')}:</Text>
                <Text>{materialInfo.inspectionSetupIndicator ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.qmProcActive')}:</Text>
                <Text>{materialInfo.qmProcActive ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.qmControlKey')}:</Text>
                <Text>{materialInfo.qmControlKey ?? '-'}</Text>
              </div>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="accounting">
            <div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.valuationClass')}:</Text>
                <Text>{materialInfo.valuationClass ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.priceUnit')}:</Text>
                <Text>{materialInfo.priceUnit ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>
                  {t('materialInfo.movingAveragePricePeriodicUnitPrice')}:
                </Text>
                <Text>
                  {materialInfo.movingAveragePricePeriodicUnitPrice ?? '-'}
                </Text>
              </div>
            </div>
          </Tabs.Panel>
          <Tabs.Panel value="additional">
            <div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.mrpType')}:</Text>
                <Text>{materialInfo.mrpType ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.mrpController')}:</Text>
                <Text>{materialInfo.mrpController ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.minimumLotSize')}:</Text>
                <Text>{materialInfo.minimumLotSize ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.maximumLotSize')}:</Text>
                <Text>{materialInfo.maximumLotSize ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.maximumStockLevel')}:</Text>
                <Text>{materialInfo.maximumStockLevel ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.roundingValue')}:</Text>
                <Text>{materialInfo.roundingValue ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.specialProcurement')}:</Text>
                <Text>{materialInfo.specialProcurement ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>
                  {t('materialInfo.storageLocForExternalProcurement')}:
                </Text>
                <Text>
                  {materialInfo.storageLocForExternalProcurement ?? '-'}
                </Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.schedulingMarginKey')}:</Text>
                <Text>{materialInfo.schedulingMarginKey ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.minSafetyStock')}:</Text>
                <Text>{materialInfo.minSafetyStock ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.safetyStock')}:</Text>
                <Text>{materialInfo.safetyStock ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.availabilityCheck')}:</Text>
                <Text>{materialInfo.availabilityCheck ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.stockDetermineGroup')}:</Text>
                <Text>{materialInfo.stockDetermineGroup ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.grProcessingTime')}:</Text>
                <Text>{materialInfo.grProcessingTime ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.plannedDeliveryTime')}:</Text>
                <Text>{materialInfo.plannedDeliveryTime ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.individualCollective')}:</Text>
                <Text>{materialInfo.individualCollective ?? '-'}</Text>
              </div>
              <div className="grid grid-cols-2 my-3">
                <Text>{t('materialInfo.reorderPoint')}:</Text>
                <Text>{materialInfo.reorderPoint ?? '-'}</Text>
              </div>
            </div>
          </Tabs.Panel>
        </Tabs>
      </div>
    </div>
  );
}
