import { DateFilter } from '@components/molecules/filters/static/DateFilter';

import { paginationBaseFilters } from '@consts/paginationBaseFilters';

import { IFilters } from '@/types/common/IFilters';
import { orderMaterialsKeys } from '@/types/IWorkOrder';
import { OrderStatusFilter } from '@components/molecules/filters/dynamic/OrderStatusFilter';

export const orderMaterialsFilters: IFilters = {
  ...paginationBaseFilters,
  [orderMaterialsKeys.materialNumber]: {
    sort: true,
  },
  [orderMaterialsKeys.materialDescription]: {
    sort: true,
  },
  [orderMaterialsKeys.requirementQuantity]: {
    sort: true,
  },
  [orderMaterialsKeys.itemCategory]: {
    sort: true,
  },
  [orderMaterialsKeys.storageLocation]: {
    sort: true,
  },
  [orderMaterialsKeys.branchPlant]: {
    sort: true,
  },
  [orderMaterialsKeys.activity]: {
    sort: true,
  },
  [orderMaterialsKeys.purchasingOrganization]: {
    sort: true,
  },
  [orderMaterialsKeys.unloadingPlant]: {
    sort: true,
  },
  [orderMaterialsKeys.assignedBuyer]: {
    sort: true,
  },
  [orderMaterialsKeys.unitOfMeasurement]: {
    sort: true,
  },
  [orderMaterialsKeys.str]: {
    sort: true,
  },
  [orderMaterialsKeys.sto]: {
    sort: true,
  },
  [orderMaterialsKeys.pr]: {
    sort: true,
  },
  [orderMaterialsKeys.po]: {
    sort: true,
  },
  [orderMaterialsKeys.requirementsDate]: {
    input: <DateFilter />,
    sort: true,
  },
  [orderMaterialsKeys.updateAt]: {
    input: <DateFilter />,
    sort: true,
  },
  [orderMaterialsKeys.deliveryDate]: {
    input: <DateFilter />,
    sort: true,
  },
  [orderMaterialsKeys.status]: {
    input: <OrderStatusFilter />,
    sort: true,
  },
};
