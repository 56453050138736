import { AxiosError, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { object, ObjectSchema, string } from 'yup';

import { queryKeys } from '@consts/queryKeys';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { api, notify } from '@utils/index';

import { IMeasurementUnit } from '@/types/IMeasurementUnit';

export type IMeasurementCreateRequest = Pick<
  IMeasurementUnit,
  'fullName' | 'shortName'
>;

type IMeasurementCreateResponse = AxiosResponse<IMeasurementUnit>;

export const measurementCreateInitial: IMeasurementCreateRequest = {
  fullName: '',
  shortName: '',
};

export const measurementCreateValidation: ObjectSchema<IMeasurementCreateRequest> =
  object({
    fullName: string().required(),
    shortName: string().required(),
  });

export function useMeasurementCreate() {
  const queryClient = useQueryClient();

  return useMutation<
    IMeasurementCreateResponse,
    AxiosError,
    IMeasurementCreateRequest
  >(
    (payload) => api.post('/materials/api/admin/units-of-measurement', payload),
    {
      onError: () => {
        notify('error', t('messages.error'));
      },
      onSuccess: () =>
        queryClient.invalidateQueries([queryKeys.unitsOfMeasurements]),
    }
  );
}
