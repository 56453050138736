import { FC } from 'react';
import { PageMeta } from '@context/PageMetaContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Loader } from '@mantine/core';

import { NotFoundResult } from '@components/molecules/NotFoundResult';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useStockMaterial } from '@api/sar-materials/useStockMaterial';
import StockMaterialInfoCard from '../molecules/StockMaterialInfoCard';
import StockMaterialCard from '../molecules/StockMaterialCard';
import { useUrlFilteredActioned } from '@hooks/useUrlFilteredActioned';
import { IPageAbleRequest } from '@/types/common/IPageAbleRequest';
import {
  ISARPageActions,
  sarPageActions,
} from '@modules/sar/consts/pageActions/ISARPageActions';
import { sarFilters } from '@modules/sar/consts/filters/sarFilters';
import { EditStockMaterialModal } from '../organisms/modals/EditStockMaterialModal';
import { EditStockMaterialInfoModal } from '../organisms/modals/EditStockMaterialInfoModal';
import CertificatesCard from '../molecules/CertificatesCard';
import { AddCertificateModal } from '../organisms/modals/AddCertificateModal';

const StockMaterialDetailsPage: FC = () => {
  const { materialId, sarId } = useParams();
  const navigate = useNavigate();
  const stockMaterialQuery = useStockMaterial({
    sarMaterialId: Number(materialId),
  });
  const stockMaterial = stockMaterialQuery.data;

  const { actioned, clearParams } = useUrlFilteredActioned<
    IPageAbleRequest,
    ISARPageActions
  >(sarFilters, sarPageActions);

  return (
    <div>
      <PageMeta
        breadcrumbs={[
          { link: '/sar', title: 'Stock Authorization Requests' },
          {
            link: `/sar/details/${sarId}`,
            title: 'Stock Authorization Request Details',
          },
          {
            link: `/sar/details/${sarId}/material/${materialId}`,
            title: 'Material Details',
          },
        ]}
        selectedMenuKeys={['sar']}
        title="Stock Authorization Request Material Details"
      />

      {stockMaterialQuery.isLoading ? (
        <Loader />
      ) : !stockMaterial ? (
        <NotFoundResult
          subTitle=" Please try again later!"
          title="There is an error"
        />
      ) : (
        <>
          <Button
            variant="transparent"
            className="text-[##9CA3AF] mb-6"
            onClick={() => navigate(`/sar/details/${sarId}`)}
            leftSection={<ArrowLeftOutlined />}
          >
            Back
          </Button>
          <StockMaterialCard material={stockMaterial} />

          <CertificatesCard certificates={stockMaterial.certificates} />

          <StockMaterialInfoCard materialInfo={stockMaterial.info} />
        </>
      )}
      <EditStockMaterialModal
        isOpen={actioned.action === 'editMaterial'}
        onClose={clearParams}
        sarMaterialId={Number(actioned.actionId)}
      />

      <EditStockMaterialInfoModal
        isOpen={actioned.action === 'editMaterialInfo'}
        onClose={clearParams}
      />

      <AddCertificateModal
        isOpen={
          actioned.action === 'addCertificate' ||
          actioned.action === 'editCertificate'
        }
        onClose={clearParams}
        id={actioned.actionId}
      />
    </div>
  );
};

export default StockMaterialDetailsPage;
