import { t } from 'i18next';
import { MRT_ColumnDef } from 'mantine-react-table';

import moment from 'moment';
import { Link } from 'react-router-dom';

import { IWorkOrder } from '@/types/IWorkOrder';
import { FavoriteButton } from '@modules/orders/components/atoms/FavoriteButton';

export const ordersColumns: MRT_ColumnDef<IWorkOrder>[] = [
  {
    accessorFn: (row) => (
      <FavoriteButton id={row.id} isFavoriteOrder={row.isFavorite} />
    ),
    accessorKey: 'favorite',
    header: '',
    size: 1,
  },
  {
    accessorFn: (row) => (
      <Link to={`/orders/details/${row.id}`}>{row.workOrderNumber ?? '-'}</Link>
    ),
    accessorKey: 'workOrderNumber',
    header: t('id'),
    size: 5,
  },
  {
    accessorFn: (row) => (
      <span className="whitespace-nowrap">
        {moment(row.createdAt).format('DD/MM/YY') ?? '-'}
      </span>
    ),
    accessorKey: 'createdAt',
    header: t('createdAt'),
    size: 5,
  },
];
